import { useState } from 'react';
import { Column } from '../../types/table';

interface States<T> {
    page?: number;
    limit?: number;
    sortColumn?: Column<T>;
    sortAsc?: boolean;
    searchTerm?: string;
}

const useAPITableStates = <T>(initial: States<T>) => {
    const [page, setPage] = useState(initial.page ?? 1);
    const [limit, setLimit] = useState(initial.limit ?? 10);
    const [sortColumn, setSortColumn] = useState(initial.sortColumn ?? ({} as Column<T>));
    const [sortAsc, setSortAsc] = useState(initial.sortAsc ?? true);
    const [searchTerm, setSearchTerm] = useState(initial.searchTerm ?? '');

    const onSortColumnChange = (column: Column<T>) => {
        if (column.key === sortColumn.key) {
            setSortAsc(!sortAsc);
        } else {
            setSortColumn(column);
            setSortAsc(true);
        }
    };

    return {
        page,
        onPageChange: setPage,
        limit,
        onLimitChange: setLimit,
        sortColumn,
        onSortColumnChange,
        sortAsc,
        searchTerm,
        onSearchTermChange: setSearchTerm,
    };
};

export default useAPITableStates;
