import useStoresFetch from '@pages/stores/stores/hooks/useStoresFetch';
import Checkbox from 'lib/src/components/form/Checkbox';
import DatePicker from 'lib/src/components/form/DatePicker';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import FormSubTitle from 'lib/src/components/form/FormSubTitle';
import Select from 'lib/src/components/form/Select';
import TextInput from 'lib/src/components/form/TextInput';
import TextArea from 'lib/src/components/form/TextArea';
import Modal from 'lib/src/components/modal/Modal';
import { convertEnumToDropdownOption } from 'lib/src/shared/enums/dropdownEnums';
import React from 'react';
import { Voucher, VoucherStatuses } from '@customTypes/shared/Voucher';

import useUpdateVoucher from './hooks/useUpdateVoucher';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import config from '../../../config/index';
import { FILE_TYPES } from 'lib/src/constants/fileMimeTypes';
import dayjs from 'dayjs';
import FormError from 'lib/src/components/form/FormError';

const { API_URL, S3_URL } = config;
const { PNG, JPG } = FILE_TYPES;

const UpdateVoucherModal: React.FC<UpdateVoucherProps> = ({ voucher }) => {
    const {
        handleSubmit,
        handleChange,
        closeModal,
        isPosting,
        formState: {
            title,
            isUnlimited,
            internalName,
            shortDescription,
            description1,
            terms,
            status,
            usesPerIndividual,
            mainImageS3Key,
            thumbnailS3Key,
            validFromDate,
            validFromTime,
            validToDate,
            validToTime,
            storeIds,
            isHighlighted,
            customBarcode,
        },
    } = useUpdateVoucher(voucher);

    const { stores } = useStoresFetch();
    const storeOptions = Object.values(stores).map(store => ({
        value: store.id,
        label: store.name,
    }));

    return (
        <Modal
            title="Update Voucher"
            size="small"
            closeModal={closeModal}
            id="update-voucher-modal"
        >
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormSubTitle>About</FormSubTitle>
                <FormRow>
                    <TextInput
                        name="title"
                        value={title}
                        onChange={handleChange}
                        label="Title"
                        required
                    />
                    <TextInput
                        name="internalName"
                        value={internalName}
                        onChange={handleChange}
                        label="Internal Name"
                        required
                    />
                </FormRow>

                <FormRow>
                    <TextInput
                        name="shortDescription"
                        value={shortDescription}
                        onChange={handleChange}
                        label="Short Description"
                    />
                </FormRow>

                <FormRow>
                    <TextArea
                        name="description1"
                        value={description1}
                        onChange={handleChange}
                        label="Description"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextArea
                        name="terms"
                        value={terms}
                        onChange={handleChange}
                        label="Terms"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="isHighlighted"
                        value={isHighlighted}
                        onChange={handleChange}
                        label="Is Highlighted"
                    />
                </FormRow>

                <FormSubTitle>Usage</FormSubTitle>
                <FormRow>
                    <Checkbox
                        name="isUnlimited"
                        value={isUnlimited}
                        onChange={handleChange}
                        label="Is Unlimited"
                    />
                    <TextInput
                        type="number"
                        name="usesPerIndividual"
                        value={isUnlimited ? '' : usesPerIndividual?.toString()}
                        onChange={(name, value) => handleChange(name, +value)}
                        label="Uses Per Individual"
                        required={!isUnlimited}
                        disabled={isUnlimited}
                    />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="validFromDate"
                        value={validFromDate}
                        onChange={handleChange}
                        label="Valid From Date"
                        required
                    />
                    <TextInput
                        type="time"
                        name="validFromTime"
                        value={validFromTime}
                        onChange={handleChange}
                        label="Valid From Time"
                        required
                    />
                </FormRow>
                <FormRow>
                    <FormError name="validFrom" />
                </FormRow>
                <FormRow>
                    <DatePicker
                        name="validToDate"
                        value={validToDate}
                        onChange={handleChange}
                        label="Valid To Date"
                        minDate={dayjs(validFromDate).toDate()}
                        required
                    />
                    <TextInput
                        type="time"
                        name="validToTime"
                        value={validToTime}
                        onChange={handleChange}
                        label="Valid To Time"
                        required
                    />
                </FormRow>
                <FormRow>
                    <FormError name="validTo" />
                </FormRow>
                <FormRow>
                    <MultiSelect
                        name="storeIds"
                        value={storeIds}
                        onChange={handleChange}
                        options={storeOptions}
                        label="Stores"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="status"
                        value={status}
                        options={convertEnumToDropdownOption(VoucherStatuses)}
                        onChange={handleChange}
                        label="Status"
                        required
                    />
                </FormRow>

                <FormRow>
                    <TextInput
                        name="customBarcode"
                        value={customBarcode || ''}
                        onChange={handleChange}
                        label="Custom Barcode"
                    />
                </FormRow>

                <FormSubTitle>Images</FormSubTitle>
                <FormRow>
                    <FilePicker
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        name="thumbnailS3Key"
                        value={thumbnailS3Key ? [thumbnailS3Key] : []}
                        onChange={(name, value) => handleChange(name, value ? value[0] : [])}
                        maxFiles={1}
                        acceptedTypes={`${JPG}, ${PNG}`}
                        label="Thumbnail Image"
                        required
                    />
                </FormRow>
                <FormRow>
                    <FilePicker
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        name="mainImageS3Key"
                        value={mainImageS3Key ? [mainImageS3Key] : []}
                        onChange={(name, value) => handleChange(name, value ? value[0] : [])}
                        maxFiles={1}
                        acceptedTypes={`${JPG}, ${PNG}`}
                        label="Main Image"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateVoucherProps {
    voucher: Voucher;
}

export default UpdateVoucherModal;
