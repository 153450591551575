import { Voucher, VoucherPost } from '@customTypes/shared/Voucher';
import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const createVoucherRequest = createAction('createVoucherRequest');
export const createVoucherSuccess = createAction<Voucher>('createVoucherSuccess');
export const createVoucherFailure = createAction('createVoucherFailure');

export const createVoucher = (postbody: VoucherPost) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(createVoucherRequest());
    try {
        const { data } = await api.post<VoucherPost, Voucher>(`vouchers`, postbody);

        dispatch(createVoucherSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createVoucherFailure, e);
    }
};
