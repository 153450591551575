import { Redirect, Route, useLocation } from 'react-router-dom';

import { getJwtToken, validateJWT } from 'lib/src/utils/jwt';
import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';
import { hasRoles } from '@utils/generic';

const SubRouter: React.FC<SubRouterProps> = ({
    path,
    children,
    auth = false,
    exact = false,
    allowedRoles,
}) => {
    const location = useLocation();

    if (auth) {
        const token = getJwtToken();

        const isValidToken = token ? validateJWT(token) : false;

        if (!isValidToken) {
            return <Redirect to="/auth/login" from={location.pathname} />;
        }
    }

    if (allowedRoles) {
        if (!hasRoles(allowedRoles, 'some')) return <Redirect to="/" from={location.pathname} />;
    }

    return (
        <Route exact={exact} path={path}>
            {children}
        </Route>
    );
};

interface SubRouterProps {
    path: string;
    auth?: boolean;
    exact?: boolean;
    allowedRoles?: AdminUserRoles[];
}

export default SubRouter;
