import { Voucher } from '@customTypes/shared/Voucher';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchAllVouchersRequest = createAction('fetchAllVouchersRequest');
export const fetchAllVouchersSuccess = createAction<Voucher[]>('fetchAllVouchersSuccess');
export const fetchAllVouchersFailure = createAction('fetchAllVouchersFailure');

export const fetchAllVouchers = () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchAllVouchersRequest());

    try {
        const { data } = await api.get<Voucher[]>('vouchers');

        dispatch(fetchAllVouchersSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllVouchersFailure, e);
    }
};
