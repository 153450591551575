import { Voucher, VoucherStatusNames } from '@customTypes/shared/Voucher';
import Table from 'lib/src/components/table/Table';
import LinkButton from 'lib/src/components/button/LinkButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import useVouchersFetch from './hooks/useVouchersFetch';
import dayjs from 'dayjs';
import { useState } from 'react';
import Checkbox from 'lib/src/components/form/Checkbox';
import { Column } from 'lib/src/types/table';

const VouchersTable: React.FC = () => {
    const { vouchers, isFetching } = useVouchersFetch();

    const [showInactive, setShowInactive] = useState(false);

    const allVouchers = Object.values(vouchers);
    const activeVouchers = allVouchers.filter(({ validFrom, validTo }) => {
        return dayjs().isAfter(validFrom) && dayjs().isBefore(validTo);
    });

    return (
        <Table
            columns={columns}
            rows={showInactive ? allVouchers : activeVouchers}
            isLoading={isFetching}
            defaultSortColumn={columns[2]}
            defaultSortAsc={false}
            header={
                <Checkbox
                    name="showInactive"
                    value={showInactive}
                    onChange={(_, value) => setShowInactive(value)}
                    label="Show Inactive"
                />
            }
        />
    );
};

const columns: Column<Voucher>[] = [
    {
        key: 0,
        heading: 'Internal Name',
        getValue: (row: Voucher) => row.internalName,
        getSort: (a, b) => a.internalName?.localeCompare(b.internalName),
        searchable: true,
    },
    {
        key: 1,
        heading: 'Status',
        getValue: (row: Voucher) => VoucherStatusNames[row.status],
        getSort: (a, b) => a.status - b.status,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Valid From',
        getValue: (row: Voucher) => dayjs(row.validFrom).format('DD/MM/YYYY - HH:mm'),
        getSort: (a, b) => dayjs(a.validFrom).unix() - dayjs(b.validFrom).unix(),
        searchable: true,
    },
    {
        key: 3,
        heading: '',
        getValue: (row: Voucher) => <>({dayjs(row.validFrom).fromNow()})</>,
        searchable: true,
    },

    {
        key: 4,
        heading: 'Valid To',
        getValue: (row: Voucher) => dayjs(row.validTo).format('DD/MM/YYYY - HH:mm'),
        getSort: (a, b) => dayjs(a.validTo).unix() - dayjs(b.validTo).unix(),
        searchable: true,
    },
    {
        key: 5,
        heading: '',
        getValue: (row: Voucher) => <>({dayjs(row.validTo).fromNow()})</>,
        searchable: true,
    },
    {
        key: 6,
        heading: 'Redeemed',
        getValue: (row: Voucher) => row.numberOfUses?.toString(),
        getSort: (a, b) => a.numberOfUses - b.numberOfUses,
    },
    {
        key: 7,
        heading: 'Is Highlighted',
        getValue: (row: Voucher) => (row.isHighlighted ? 'Yes' : 'No'),
        getSort: (a, b) => Number(b.isHighlighted) - Number(a.isHighlighted),
    },
    {
        key: 8,
        heading: '',
        getValue: (row: Voucher) => (
            <ButtonRow alignment="right">
                <LinkButton href={`/vouchers/${row.id}`}>View</LinkButton>
                <LinkButton color="grey" href={`/vouchers/${row.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="red" href={`/vouchers/${row.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        ),
    },
];

export default VouchersTable;
