import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { SuppressedStatuses } from 'src/types/shared/Customer';

export const fetchCustomersCountRequest = createAction('fetchCustomersCountRequest');
export const fetchCustomersCountSuccess = createAction<FetchCustomersCountResponse>(
    'fetchCustomersCountSuccess',
);
export const fetchCustomersCountFailure = createAction('fetchCustomersCountFailure');

export const fetchCustomersCount = (
    limit: number,
    page: number,
    status: SuppressedStatuses | null,
    search?: Record<string, any>,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchCustomersCountRequest());

    const body = search || {};

    try {
        const { data } = await api.post<typeof body, FetchCustomersCountResponse>(
            'customers/count',
            body,
            {
                limit,
                page,
                status,
            },
        );

        dispatch(fetchCustomersCountSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchCustomersCountFailure, e);
    }
};

export interface FetchCustomersCountResponse {
    count: number;
}
