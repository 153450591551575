import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import FormError from 'lib/src/components/form/FormError';
import TextInput from 'lib/src/components/form/TextInput';
import Select from 'lib/src/components/form/Select';
import DatePicker from 'lib/src/components/form/DatePicker';
import FormSubTitle from 'lib/src/components/form/FormSubTitle';
import FormNote from 'lib/src/components/form/FormNote';
import useCreatePushNotification from './hooks/useCreatePushNotification';
import useVouchersFetch from '@pages/vouchers/vouchers/hooks/useVouchersFetch';
import Checkbox from 'lib/src/components/form/Checkbox';
import { convertEnumToDropdownOption } from 'lib/src/shared/enums/dropdownEnums';
import TextArea from 'lib/src/components/form/TextArea';
import useSendOn from '../hooks/useSendOn';
import { PushNotificationTimeframes } from '@customTypes/shared/PushNotification';
import {
    pushNotificationMessageHardLimit,
    pushNotificationMessageSoftLimit,
    pushNotificationTitleHardLimit,
    pushNotificationTitleSoftLimit,
} from '@constants/generic';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import useStoresFetch from '@pages/stores/stores/hooks/useStoresFetch';

const CreatePushNotificationModal: React.FC<CreatePushNotificationProps> = ({
    closeModal,
    voucherId: _voucherId,
}) => {
    const { formState, handleChange, handleSubmit, isPosting } = useCreatePushNotification(
        closeModal,
        _voucherId,
    );

    const {
        voucherId,
        title,
        message,
        sendOnDate,
        sendOnTime,
        isImmediate,
        timeFrame,
        timeFrameDays,
        timeFrameHours,
        timeFrameMinutes,
        stores,
    } = formState;

    const { sendOn, isTimeframeUsed } = useSendOn(formState);

    const { vouchers } = useVouchersFetch(undefined, _voucherId != null);
    const voucherOptions = Object.values(vouchers).map(voucher => ({
        value: voucher.id,
        label: voucher.internalName,
    }));

    const { stores: _stores } = useStoresFetch();
    const storeOptions = Object.values(_stores).map(store => ({
        value: store.id,
        label: store.name,
    }));

    return (
        <Modal
            closeModal={closeModal}
            title="Create Push Notification"
            id="create-push-notification-modal"
        >
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormSubTitle>About</FormSubTitle>

                <FormRow>
                    <TextInput
                        name="title"
                        value={title}
                        onChange={handleChange}
                        label={`Title (${title.length}/${pushNotificationTitleHardLimit})`}
                        maxLength={pushNotificationTitleHardLimit}
                        warning={
                            title.length > pushNotificationTitleSoftLimit
                                ? `Titles over ${pushNotificationTitleSoftLimit} characters may be truncated`
                                : null
                        }
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextArea
                        name="message"
                        value={message}
                        onChange={handleChange}
                        label={`Message (${message.length}/${pushNotificationMessageHardLimit})`}
                        maxLength={pushNotificationMessageHardLimit}
                        warning={
                            message.length > pushNotificationMessageSoftLimit
                                ? `Messages over ${pushNotificationMessageSoftLimit} characters may be truncated`
                                : null
                        }
                        required
                    />
                </FormRow>
                <FormRow>
                    <MultiSelect
                        name="stores"
                        value={stores ?? []}
                        onChange={handleChange}
                        options={storeOptions}
                        label="Stores"
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="voucherId"
                        value={voucherId ?? null}
                        onChange={handleChange}
                        options={voucherOptions}
                        label="Voucher"
                    />
                </FormRow>

                <FormSubTitle>When to Send</FormSubTitle>
                <FormRow>
                    <Checkbox
                        name="isImmediate"
                        value={isImmediate}
                        onChange={handleChange}
                        label="Send Immediately"
                    />
                    <DatePicker
                        name="sendOnDate"
                        value={isImmediate || isTimeframeUsed ? null : sendOnDate}
                        onChange={handleChange}
                        minDate={new Date()}
                        label="Send On Date"
                        disabled={isImmediate || isTimeframeUsed}
                        required={!isImmediate && !isTimeframeUsed}
                    />
                    <TextInput
                        type="time"
                        name="sendOnTime"
                        value={isImmediate || isTimeframeUsed ? '' : sendOnTime}
                        onChange={handleChange}
                        label="Send On Time"
                        disabled={isImmediate || isTimeframeUsed}
                        required={!isImmediate && !isTimeframeUsed}
                    />
                </FormRow>
                <FormRow>
                    <FormError name="sendOn" />
                </FormRow>

                <FormSubTitle>Vouchers</FormSubTitle>
                <FormNote>
                    This section allows you to determine exactly how long after or before the start
                    or end of the voucher, the push notification should be sent.
                </FormNote>
                <FormRow>
                    <Select
                        name="timeFrame"
                        value={timeFrame}
                        options={convertEnumToDropdownOption(PushNotificationTimeframes)}
                        onChange={handleChange}
                        label="Timeframe"
                        disabled={isImmediate || voucherId == null}
                    />
                </FormRow>

                <FormRow>
                    <TextInput
                        type="number"
                        name="timeFrameDays"
                        value={timeFrameDays}
                        onChange={handleChange}
                        label="Days"
                        required={isTimeframeUsed}
                        disabled={!isTimeframeUsed}
                    />
                    <TextInput
                        type="number"
                        name="timeFrameHours"
                        value={timeFrameHours}
                        onChange={handleChange}
                        label="Hours"
                        required={isTimeframeUsed}
                        disabled={!isTimeframeUsed}
                    />
                    <TextInput
                        type="number"
                        name="timeFrameMinutes"
                        value={timeFrameMinutes}
                        onChange={handleChange}
                        label="Minutes"
                        required={isTimeframeUsed}
                        disabled={!isTimeframeUsed}
                    />
                </FormRow>
                <FormRow>
                    <FormError name="timeFrameOffset" />
                </FormRow>

                <FormNote>
                    Notification will be sent on: {sendOn.format('DD-MM-YYYY - HH:mm')}
                </FormNote>
            </Form>
        </Modal>
    );
};

interface CreatePushNotificationProps {
    voucherId?: number;
    closeModal: () => void;
}

export default CreatePushNotificationModal;
