import { createAction, Dispatch } from '@reduxjs/toolkit';
import axios, { CancelTokenSource } from 'axios';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const importCustomersRequest = createAction<CancelTokenSource>('importCustomersRequest');
export const importCustomersSuccess = createAction('importCustomersSuccess');
export const importCustomersFailure = createAction('importCustomersFailure');
export const importCustomersCancel = createAction('importCustomersCancel');

export const importCustomers = (postbody: FormData) => async (
    dispatch: Dispatch,
): Promise<void> => {
    const cancelTokenSource = axios.CancelToken.source();
    dispatch(importCustomersRequest(cancelTokenSource));

    try {
        await api.post(`customers/import`, postbody, null, cancelTokenSource);

        dispatch(importCustomersSuccess());
    } catch (e) {
        handleApiErrors(dispatch, importCustomersFailure, e);
    }
};

export const cancelImportCustomers = () => (dispatch: Dispatch): void => {
    dispatch(importCustomersCancel());
};
