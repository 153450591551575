import { PushNotification } from '@customTypes/shared/PushNotification';
import Table from 'lib/src/components/table/Table';
import LinkButton from 'lib/src/components/button/LinkButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import usePushNotificationsFetch from './hooks/usePushNotificationsFetch';
import dayjs from 'dayjs';
import Checkbox from 'lib/src/components/form/Checkbox';
import { Column } from 'lib/src/types/table';
import NotificationStatus from '../pushNotification/NotificationStatus';

const PushNotificationsTable: React.FC = () => {
    const {
        pushNotifications,
        isFetching,
        showHistorical,
        setShowHistorical,
    } = usePushNotificationsFetch();

    return (
        <Table
            columns={columns}
            rows={pushNotifications}
            isLoading={isFetching}
            header={
                <Checkbox
                    name="showHistorical"
                    value={showHistorical}
                    onChange={(_, value) => setShowHistorical(value)}
                    label="Show Historical"
                />
            }
            defaultSortColumn={columns[1]}
            defaultSortAsc={false}
        />
    );
};

const columns: Column<PushNotification>[] = [
    {
        key: 0,
        heading: 'Title',
        getValue: (row: PushNotification) => row.title,
        getSort: (a, b) => a.title.localeCompare(b.title),
        searchable: true,
    },
    {
        key: 1,
        heading: 'Send Date',
        getValue: (row: PushNotification) => dayjs(row.sendOn).format('DD/MM/YYYY - HH:mm '),
        getSort: (a, b) => dayjs(a.sendOn).unix() - dayjs(b.sendOn).unix(),
    },
    {
        key: 2,
        heading: 'Sent / Will Send',
        getValue: (row: PushNotification) => dayjs(row.sentOn ? row.sentOn : row.sendOn).fromNow(),
        getSort: (a, b) => dayjs(a.sentOn || a.sendOn).unix() - dayjs(b.sentOn || b.sendOn).unix(),
    },
    {
        key: 3,
        heading: 'Status',
        getValue: (row: PushNotification) => (
            <NotificationStatus status={row.status} errorMessage={row.errorMessage} />
        ),
        getSort: (a, b) => a.status - b.status,
    },
    {
        key: 4,
        heading: 'Sent To / Total',
        getValue: (row: PushNotification) => {
            if (!row.totalSent || !row.totalToSend) return <p>-</p>;
            return (
                <p>
                    {row.totalSent} / {row.totalToSend}
                </p>
            );
        },
    },
    {
        key: 5,
        heading: '',
        getValue: (row: PushNotification) => (
            <ButtonRow alignment="right">
                <LinkButton href={`/push-notifications/${row.id}`}>View</LinkButton>
                <LinkButton color="grey" href={`/push-notifications/${row.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="red" href={`/push-notifications/${row.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        ),
    },
];

export default PushNotificationsTable;
