import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { PushNotification, PushNotificationPatch } from 'src/types/shared/PushNotification';

export const updatePushNotificationRequest = createAction('updatePushNotificationRequest');
export const updatePushNotificationSuccess = createAction<PushNotification>(
    'updatePushNotificationSuccess',
);
export const updatePushNotificationFailure = createAction('updatePushNotificationFailure');

export const updatePushNotification = (id: number, postbody: PushNotificationPatch) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updatePushNotificationRequest());

    try {
        const { data } = await api.patch<PushNotificationPatch, PushNotification>(
            `pushNotifications/${id}`,
            postbody,
        );

        dispatch(updatePushNotificationSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updatePushNotificationFailure, e);
    }
};
