import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { BusinessHoursExceptionPost, Store } from 'src/types/shared/Store';

export const createStoreExceptionRequest = createAction('createStoreExceptionRequest');
export const createStoreExceptionSuccess = createAction<Store>('createStoreExceptionSuccess');
export const createStoreExceptionFailure = createAction('createStoreExceptionFailure');

export const createStoreException = (
    storeID: number,
    postbody: BusinessHoursExceptionPost,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(createStoreExceptionRequest());
    try {
        const { data }: { data: Store } = await api.post(`stores/${storeID}/exceptions`, postbody);

        dispatch(createStoreExceptionSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createStoreExceptionFailure, e);
    }
};
