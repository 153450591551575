import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Customer } from 'src/types/shared/Customer';

export const fetchSingleCustomerRequest = createAction('fetchSingleCustomerRequest');
export const fetchSingleCustomerSuccess = createAction<Customer>('fetchSingleCustomerSuccess');
export const fetchSingleCustomerFailure = createAction('fetchSingleCustomerFailure');

export const fetchSingleCustomer = (id: number) => async (
    dispatch: AppDispatch,
    // todo really?
): Promise<void> => {
    dispatch(fetchSingleCustomerRequest());
    try {
        const { data } = await api.get<Customer>(`customers/${id}`);

        dispatch(fetchSingleCustomerSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleCustomerFailure, e);
    }
};
