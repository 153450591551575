import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

const InfoTooltip: React.FC<InfoTooltipProps> = ({ description }) => {
    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    return <i className="info fa fa-info-circle" data-tip={description}></i>;
};

interface InfoTooltipProps {
    description: string;
}

export default InfoTooltip;
