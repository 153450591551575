import { Store } from '@customTypes/shared/Store';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchSingleStoreRequest = createAction('fetchSingleStoreRequest');
export const fetchSingleStoreSuccess = createAction<Store>('fetchSingleStoreSuccess');
export const fetchSingleStoreFailure = createAction('fetchSingleStoreFailure');

export const fetchSingleStore = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSingleStoreRequest());
    try {
        const { data } = await api.get<Store>(`stores/${id}`);

        dispatch(fetchSingleStoreSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleStoreFailure, e);
    }
};
