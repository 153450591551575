import { Switch } from 'react-router-dom';

import AdminUsersRoutes from './AdminUsersRoutes';
import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import CustomersRoutes from './CustomersRoutes';
import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';
import StoresRoutes from './StoresRoutes';
import VouchersRoutes from './VouchersRoutes';
import PushNotificationsRoutes from './PushNotificationsRoutes';
import DefaultRedirect from './DefaultRedirect';
import ImportExportRoutes from './ImportExportRoutes';

const Routes: React.FC = () => (
    <Switch>
        <SubRouter exact path="/" auth>
            <DefaultRedirect />
        </SubRouter>
        <SubRouter path="/auth">
            <AuthRoutes />
        </SubRouter>
        <SubRouter path="/customers" auth allowedRoles={[AdminUserRoles.Customers]}>
            <CustomersRoutes />
        </SubRouter>
        <SubRouter path="/admins" auth allowedRoles={[AdminUserRoles.Admins]}>
            <AdminUsersRoutes />
        </SubRouter>
        <SubRouter path="/stores" auth allowedRoles={[AdminUserRoles.Stores]}>
            <StoresRoutes />
        </SubRouter>
        <SubRouter path="/vouchers" auth allowedRoles={[AdminUserRoles.Vouchers]}>
            <VouchersRoutes />
        </SubRouter>
        <SubRouter
            path="/push-notifications"
            auth
            allowedRoles={[AdminUserRoles.PushNotifications]}
        >
            <PushNotificationsRoutes />
        </SubRouter>
        <SubRouter
            path="/import-export"
            auth
            allowedRoles={[AdminUserRoles.Admins, AdminUserRoles.SuperAdmin]}
        >
            <ImportExportRoutes />
        </SubRouter>
    </Switch>
);

export default Routes;
