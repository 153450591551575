import React, { Dispatch, SetStateAction } from 'react';
import Modal from 'lib/src/components/modal/Modal';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import ActionButton from 'lib/src/components/button/ActionButton';
import { getDecodedJwtToken } from '../../utils/jwt';

const SuccessModal: React.FC<SuccessModalProps> = ({
    fetchSuccess,
    postSuccess,
    setShowSuccessModal = () => {},
}) => {
    const jwt = getDecodedJwtToken();
    console.log(jwt);
    return (
        <Modal title="Success" size="small" closeModal={() => setShowSuccessModal(false)}>
            {postSuccess && <p>Data upload successful. Please check your email for confirmation</p>}
            {fetchSuccess && (
                <p>
                    Successfully generated CSV file. The file will be emailed to you shortly
                    {jwt?.email ? `at ${jwt?.email}` : ''}.
                </p>
            )}
            <ButtonRow alignment="right">
                <ActionButton
                    color="green"
                    icon="check"
                    onClick={() => setShowSuccessModal(false)}
                    className="import-export-button"
                >
                    OK
                </ActionButton>
            </ButtonRow>
        </Modal>
    );
};

interface SuccessModalProps {
    fetchSuccess: boolean;
    postSuccess: boolean;
    setShowSuccessModal: Dispatch<SetStateAction<boolean>>;
}

export default SuccessModal;
