import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getStoreIsPosting, getStorePostSuccess, getStoresError } from '@selectors/stores';
import { CloseModal, HandleSubmit } from 'src/types/shared/Functions';
import dayjs from 'dayjs';
import { createStoreException } from '@actions/stores';

const initialState: FormState = {
    name: '',
    isDateRange: false,
    startOn: new Date(),
    endOn: null,
    openTime: '',
    closeTime: '',
    isClosed: false,
    isRecurring: false,
};

export default function useCreateStoreException(
    storeID: number,
    closeModal: CloseModal,
): UseCreateStoreReturn {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formState, handleChange] = useForm(initialState);

    const handleSubmit = useCallback(() => {
        const {
            name,
            startOn,
            endOn,
            openTime,
            closeTime,
            isClosed,
            isRecurring,
            isDateRange,
        } = formState;

        const postBody = {
            name,
            startOn: dayjs(startOn).format('YYYY-MM-DD'),
            endOn: isDateRange && !isRecurring && endOn ? dayjs(endOn).format('YYYY-MM-DD') : null,
            openTime: !isClosed ? openTime : null,
            closeTime: !isClosed ? closeTime : null,
            isClosed,
            isRecurring,
        };

        dispatch(createStoreException(storeID, postBody));
        history.push(`/stores/${storeID}/exceptions`);
    }, [dispatch, history, formState, storeID]);

    const isPosting = useSelector(getStoreIsPosting);
    const error = useSelector(getStoresError);
    const postSuccess = useSelector(getStorePostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
}

interface FormState {
    name: string;
    isDateRange: boolean;
    startOn: Date;
    endOn: Date | null;
    openTime: string | null;
    closeTime: string | null;
    isClosed: boolean;
    isRecurring: boolean;
}

interface UseCreateStoreReturn {
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
    error: string | null;
}
