import { PushNotification } from '@customTypes/shared/PushNotification';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchAllPushNotificationsRequest = createAction('fetchAllPushNotificationsRequest');
export const fetchAllPushNotificationsSuccess = createAction<PushNotification[]>(
    'fetchAllPushNotificationsSuccess',
);
export const fetchAllPushNotificationsFailure = createAction('fetchAllPushNotificationsFailure');

export const fetchAllPushNotifications = (includeHistorical: boolean) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchAllPushNotificationsRequest());

    try {
        const { data } = await api.get<PushNotification[]>(
            `pushNotifications?includeHistorical=${includeHistorical}`,
        );

        dispatch(fetchAllPushNotificationsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllPushNotificationsFailure, e);
    }
};
