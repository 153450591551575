import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ImportExport from '@pages/importExport/ImportExport';

const ImportExportRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <ImportExport />
            </Route>
        </Switch>
    );
};

export default ImportExportRoutes;
