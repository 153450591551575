import React, { FormEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useFieldValidation from '../../../hooks/useFieldValidation';
import { fileUploadStart, fileUploadFinish } from '../../../redux/actions/fileUpload';
import { FormInputProps } from '../../../types/shared/FormInputProps';
import { areArraysEqual } from '../../../utils/generic';
import {
    importCustomerCSVRequest,
    importCustomerCSVSuccess,
    importCustomerCSVFailure,
} from '../../../../../admin/src/redux/actions/importExport';
import { getConfig, handleApiErrors } from '../../../utils/api';
import axios, { AxiosResponse } from 'axios';
import config from '../../../../../admin/src/config';
const { API_URL } = config;

interface Props extends Partial<FormInputProps<string[] | null>> {
    name: string;
    maxFiles?: number;
    acceptedTypes?: string;
    source: any;
    apiURL: string;
}

interface FilePickerState {
    fileS3Keys: string[] | null;
    progress: number | null;
    uploadingFileName: string | null;
    softError: any | null;
}

interface SignedURLRequest {
    key: string;
    contentType: string;
    size: number | string;
}

interface SignedURLResponse {
    data: {
        signedS3Url: string;
        s3Key: string;
    };
}

const imageTypes = ['gif', 'jpeg', 'jpg', 'png'];

const useFilePicker = ({
    value = null,
    name,
    required,
    customValidate,
    acceptedTypes,
    onChange,
    maxFiles,
    source,
    apiURL,
}: Props) => {
    const dispatch = useDispatch();
    const inputRef = useRef<any>();

    const [error, showError] = useFieldValidation({
        name,
        required,
        customValidate,
        value,
    });

    const [filePicker, setFilePicker] = useState<FilePickerState>({
        fileS3Keys: value,
        progress: null,
        uploadingFileName: null,
        softError: null,
    });

    useEffect(() => {
        if (onChange && filePicker.fileS3Keys) {
            if (!areArraysEqual(filePicker.fileS3Keys, value ?? [])) {
                onChange(name, filePicker.fileS3Keys);
            }
        }
    }, [filePicker]);

    const validateFileType = (type: string) => {
        const fileType = type.toLowerCase();

        if (!acceptedTypes) {
            return true;
        }

        if (acceptedTypes.includes('image/*') && fileType.includes('image')) {
            return true;
        }

        return acceptedTypes.includes(fileType);
    };

    const handleFileChange = () => {
        if (onChange && maxFiles) {
            onChange(name, filePicker.fileS3Keys);
        }

        showError();
    };

    const handleFileDrop = async (files: any[]) => {
        dispatch(fileUploadStart(null));

        for (const file of files) {
            await uploadFile(file);
        }

        dispatch(fileUploadFinish(null));
    };

    const handleAddFileClick = (event: any) => {
        event.preventDefault();

        if (inputRef && inputRef?.current) {
            inputRef.current.click();
        }
    };

    const handleProgress = (e: any) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        setFilePicker({ ...filePicker, progress: percentCompleted });
    };

    const handleCancel = () => {
        source.cancel('Upload cancelled.');
    };

    const handleUpload = async (e: FormEvent) => {
        e.persist();
        const files = (e.target as HTMLInputElement).files;
        dispatch(fileUploadStart(null));

        if (maxFiles === 1) {
            setFilePicker({ ...filePicker, fileS3Keys: [] });
        }

        if (files) {
            for (const file of files) {
                await uploadFile(file);
            }
        }

        dispatch(fileUploadFinish(null));

        if (e && e.target) {
            (e.target as HTMLInputElement).value = '';
        }
    };

    const uploadFile = async (file: File) => {
        if (!file) {
            return;
        }

        if (filePicker.fileS3Keys && filePicker.fileS3Keys.length === maxFiles) {
            setFilePicker({
                ...filePicker,
                softError: `You can only upload a maximum of ${maxFiles} files.`,
            });
            return;
        }
        if (!validateFileType(file.type)) {
            setFilePicker({
                ...filePicker,
                softError: `The file type '${file.type}' is not permitted.`,
            });
            return;
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        source = axios.CancelToken.source();

        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'content-type': 'multipart/form-data',
        };

        const reqConfig = {
            headers,
            cancelToken: source.token,
            onUploadProgress: handleProgress,
        };

        try {
            setFilePicker({ ...filePicker, uploadingFileName: file.name });
            const response = await axios.post(`${apiURL}/media`, formData, reqConfig);
            const newS3Key = response.data.s3Key;
            setFilePicker({
                ...filePicker,
                fileS3Keys: [...(filePicker.fileS3Keys || []), newS3Key],
                progress: null,
                uploadingFileName: null,
                softError: null,
            });
        } catch (e) {
            if (!axios.isCancel(e)) {
                setFilePicker({
                    ...filePicker,
                    softError: `There was a problem uploading ${file.name}.`,
                    progress: null,
                    uploadingFileName: null,
                });
            }
        }
    };

    const handleUploadLargeFile = async () => {
        try {
            // dispatch(importCustomerCSVRequest());
            const file = inputRef?.current?.files[0];
            const postBody = {
                key: file?.name,
                contentType: file?.type,
                size: file?.size,
            };

            source = axios.CancelToken.source();

            const {
                data: { signedS3Url, s3Key },
            } = await axios.post<SignedURLRequest, SignedURLResponse>(
                `${API_URL}/media/file`,
                postBody,
                getConfig(),
            ); // Get signed URL from API

            setFilePicker(filePicker => ({ ...filePicker, uploadingFileName: file.name }));

            const options = {
                onUploadProgress: handleProgress,
                headers: { 'Content-Type': file?.type, 'x-amz-acl': 'public-read' },
            };

            await axios.put(signedS3Url, file, { ...options }); // Upload file to S3 using signed URL

            setFilePicker(filePicker => ({
                ...filePicker,
                progress: null,
                uploadingFileName: null,
                softError: null,
                fileS3Keys: [s3Key],
            }));

            // await axios.post(`${API_URL}/customers/import`, { s3Key }, getConfig()); // Send s3Key to API to import file
            // dispatch(importCustomerCSVSuccess());
        } catch (e) {
            console.dir(e);
            setFilePicker(filePicker => ({
                ...filePicker,
                progress: null,
                uploadingFileName: null,
                softError: null,
                fileS3Keys: [],
            }));
            handleApiErrors(dispatch, importCustomerCSVFailure, e);
        }
    }; // Only works with Admin side

    const handleDelete = (e: Event, s3Key: string) => {
        e.preventDefault();

        setFilePicker(state => ({
            ...state,
            fileS3Keys: state.fileS3Keys?.filter(key => key !== s3Key) || [],
            softError: null,
        }));

        handleFileChange();
    };

    const organizeS3KeysByType = (s3Keys: string[] | null) => {
        if (!s3Keys) {
            return { images: [], other: [] };
        }

        return s3Keys.reduce(
            (acc: any, s3Key: any = '') => {
                const fileTypeSuffix = s3Key.slice(s3Key.lastIndexOf('.') + 1).toLowerCase();

                if (imageTypes.includes(fileTypeSuffix)) {
                    acc.images.push(s3Key);
                } else {
                    acc.other.push(s3Key);
                }

                return acc;
            },
            { images: [], other: [] },
        );
    };

    useEffect(() => {
        if (!Array.isArray(value)) {
            setFilePicker({ ...filePicker, fileS3Keys: value ? [value] : [] });
        }

        return () => {
            dispatch(fileUploadFinish('close'));
        };
    }, []);

    return {
        inputRef,
        filePicker,
        handleFileChange,
        handleAddFileClick,
        handleUpload,
        handleDelete,
        handleCancel,
        organizeS3KeysByType,
        error,
        handleProgress,
        setFilePicker,
        handleUploadLargeFile,
    };
};

export default useFilePicker;
