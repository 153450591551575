import React, { useState } from 'react';

import Title from 'lib/src/components/typography/Title';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Voucher as VoucherTypes, VoucherStatusNames } from '@customTypes/shared/Voucher';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import ActionButton from 'lib/src/components/button/ActionButton';
import CreatePushNotificationModal from '@pages/pushNotifications/pushNotifications/CreatePushNotificationModal';
import config from '@config/index';
import VoucherPushNotificationsTable from './VoucherPushNotificationsTable';

const Voucher: React.FC<VoucherProps> = ({ voucher }) => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    if (!voucher) return null;

    const {
        id,
        title,
        isUnlimited,
        internalName,
        shortDescription,
        description1,
        terms,
        status,
        usesPerIndividual,
        numberOfViews,
        numberSent,
        numberOfUses,
        mainImageS3Key,
        thumbnailS3Key,
        validFrom,
        validTo,
        createdOn,
        updatedOn,
        stores,
        isHighlighted,
        customBarcode,
    } = voucher;

    return (
        <div>
            <Title backHref="/vouchers">Voucher - {title}</Title>

            <div className="split-page">
                <div>
                    <ContentBlock>
                        <ContentRow>
                            <ContentItem label="Title">
                                <p>{`${title}`}</p>
                            </ContentItem>
                            <ContentItem label="Internal Name">
                                <p>{internalName}</p>
                            </ContentItem>
                            <ContentItem label="Short Description">
                                <p>{shortDescription}</p>
                            </ContentItem>
                        </ContentRow>
                        <ContentRow>
                            <ContentItem label="Max Uses per Person">
                                <p>{isUnlimited ? 'Unlimited' : usesPerIndividual}</p>
                            </ContentItem>
                            <ContentItem label="Status">
                                <p>{VoucherStatusNames[status]}</p>
                            </ContentItem>
                            <ContentItem label="Is Highlighted">
                                <p>{isHighlighted ? 'Yes' : 'No'}</p>
                            </ContentItem>
                        </ContentRow>

                        <ContentRow>
                            <ContentItem label="Sent">
                                <p>{numberSent}</p>
                            </ContentItem>
                            <ContentItem label="Views">
                                <p>{numberOfViews}</p>
                            </ContentItem>
                            <ContentItem label="Redeemed">
                                <p>{numberOfUses}</p>
                            </ContentItem>
                        </ContentRow>
                    </ContentBlock>
                    <ContentBlock>
                        <ContentRow>
                            <ContentItem label="Description">
                                <p>{description1}</p>
                            </ContentItem>
                        </ContentRow>
                    </ContentBlock>
                    <ContentBlock>
                        <ContentRow>
                            <ContentItem label="Terms">
                                <p>{terms}</p>
                            </ContentItem>
                        </ContentRow>
                        <ContentRow>
                            <ContentItem label="Valid From">
                                <p>
                                    {dayjs(validFrom).format('DD/MM/YYYY - HH:mm')} (
                                    {dayjs(validFrom).fromNow()})
                                </p>
                            </ContentItem>
                            <ContentItem label="Created On">
                                <p>{dayjs(createdOn).format('DD/MM/YYYY - HH:mm')}</p>
                            </ContentItem>
                        </ContentRow>
                        <ContentRow>
                            <ContentItem label="Valid To">
                                <p>
                                    {dayjs(validTo).format('DD/MM/YYYY - HH:mm')} (
                                    {dayjs(validTo).fromNow()})
                                </p>
                            </ContentItem>
                            <ContentItem label="Updated On">
                                <p>{dayjs(updatedOn).format('DD/MM/YYYY - HH:mm')}</p>
                            </ContentItem>
                        </ContentRow>
                        <ContentRow>
                            <ContentItem label="Custom Barcode">
                                <p>{customBarcode ?? 'None set'}</p>
                            </ContentItem>
                        </ContentRow>
                    </ContentBlock>

                    <ContentBlock>
                        <ContentRow>
                            <ContentItem label="Main Image">
                                <div>
                                    <img src={`${config.S3_URL}/${mainImageS3Key}`} alt="Main" />
                                </div>
                            </ContentItem>
                        </ContentRow>
                        <ContentRow>
                            <ContentItem label="Thumnail Image">
                                <div>
                                    <img
                                        src={`${config.S3_URL}/${thumbnailS3Key}`}
                                        alt="Thumnail"
                                    />
                                </div>
                            </ContentItem>
                        </ContentRow>
                    </ContentBlock>

                    <ContentBlock>
                        <ContentRow>
                            <ContentItem label="Stores">
                                {stores?.map(store => (
                                    <p key={store.id}>
                                        <Link to={`/stores/${store.id}`}>
                                            {store.name} ({store.shortName})
                                        </Link>
                                    </p>
                                ))}
                            </ContentItem>
                        </ContentRow>
                    </ContentBlock>

                    <ButtonRow alignment="left">
                        <ActionButton
                            type="button"
                            color="green"
                            onClick={() => setShowCreateModal(true)}
                        >
                            Create Push Notification
                        </ActionButton>
                        <LinkButton color="grey" href={`/vouchers/${id}/edit`}>
                            Edit
                        </LinkButton>
                        <LinkButton color="red" href={`/vouchers/${id}/delete`}>
                            Delete
                        </LinkButton>
                    </ButtonRow>

                    {showCreateModal && (
                        <CreatePushNotificationModal
                            closeModal={() => setShowCreateModal(false)}
                            voucherId={id}
                        />
                    )}
                </div>
                <div>
                    <VoucherPushNotificationsTable voucher={voucher} />
                </div>
            </div>
        </div>
    );
};

interface VoucherProps {
    voucher: VoucherTypes | undefined;
}

export default Voucher;
