import { useDispatch, useSelector } from 'react-redux';

import { clearFormError, showFieldErrors } from '../../redux/actions/fieldErrors';
import { getFieldErrors, getFormError } from '../../redux/selectors/fieldErrors';
import ActionButton from '../button/ActionButton';
import ButtonRow from '../button/ButtonRow';
import { isEmpty } from '../../utils/generic';

const Form: React.FC<FormProps> = ({
    children,
    onSubmit,
    onCancel = () => {},
    omitButtons = false,
    showFormError = true,
    isPosting = false,
    className,
    isDelete,
}) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError);

    return (
        <form onSubmit={_handleSubmit} className={className}>
            {children}
            {!!formError && showFormError && <p className="form-generic-error">{formError}</p>}
            {!omitButtons && (
                <ButtonRow alignment="right">
                    <ActionButton
                        color="grey"
                        type="button"
                        onClick={_handleCancel}
                        disabled={isPosting}
                    >
                        Cancel
                    </ActionButton>
                    <ActionButton color={isDelete ? 'red' : 'blue'} isPosting={isPosting}>
                        {isDelete ? 'Delete' : 'Submit'}
                    </ActionButton>
                </ButtonRow>
            )}
        </form>
    );

    function _handleCancel(e: React.MouseEvent) {
        e.preventDefault();

        if (!isPosting) onCancel();
    }

    function _handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

interface FormProps {
    isPosting?: boolean;
    omitButtons?: boolean;
    showFormError?: boolean;
    onSubmit: () => void;
    onCancel?: () => void;
    className?: string;
    isDelete?: boolean;
}

export default Form;
