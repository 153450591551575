import {
    postcodeAddress,
    postcodeFind,
    postcodeRetrieve,
    resetPostcodeFinder,
} from '../../redux/actions/postcodeFinder';
import { PostcodeSuggestion } from '../../types/shared/PostcodeFinder';
import {
    getPostcodeFinderAddresses,
    getPostcodeFinderError,
    getPostcodeFinderIsFetching,
    getPostcodeFinderSuggestions,
} from '../../redux/selectors/postcodeFinder';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import FormField from './FormField';
import DataCheck from '../layout/dataCheck/DataCheck';
import { isEmpty } from '../../utils/generic';

const AddressLookup: React.FC<Props> = ({
    name = 'address-search',
    label,
    placeholder = 'Enter postcode',
    disabled = false,
    onSelect = () => {},
    isAdmin = false,
    handleAddressSelection,
}) => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getPostcodeFinderIsFetching);
    const error = useSelector(getPostcodeFinderError);
    const suggestions = useSelector(getPostcodeFinderSuggestions) as PostcodeSuggestion[];
    const address = useSelector(getPostcodeFinderAddresses)[0];

    const [initLoad, setInitLoad] = useState<boolean>(true);
    const [text, setText] = useState<string>('');
    const [addressAdded, setAddressAdded] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        dispatch(resetPostcodeFinder());
        setInitLoad(false);
    }, [dispatch]);

    const handleChange = (value: string) => {
        setText(value);
        if (value) dispatch(postcodeFind(isAdmin, { postcode: value }));
        if (addressAdded) setAddressAdded(false);
    };

    const handleSelect = (suggestion: PostcodeSuggestion) => {
        const { id, text, description, type } = suggestion;

        setText(text);

        if (type === 'Address') {
            dispatch(postcodeRetrieve(isAdmin, { id }));
            setAddressAdded(true);

            if (handleAddressSelection) handleAddressSelection();
            return;
        }

        dispatch(postcodeAddress(isAdmin, { id, description }));
        inputRef.current?.focus();
    };

    useEffect(() => {
        if (!address || initLoad) return;
        const { line1, line2, line3, provinceName, city, postalCode } = address;
        onSelect({
            addressLine1: line1,
            addressLine2: line2,
            addressLine3: line3,
            county: provinceName,
            town: city,
            postCode: postalCode,
        });
    }, [address]);

    return (
        <div className="address-lookup">
            <FormField name={name} label={label}>
                <input
                    type="text"
                    className={`form-input text-area`}
                    placeholder={placeholder}
                    name={name}
                    id={name}
                    value={text}
                    onChange={e => handleChange(e.target.value)}
                    disabled={disabled}
                    ref={inputRef}
                />
                {!!text && !addressAdded && (
                    <div className="options">
                        <DataCheck
                            isEmpty={isEmpty(suggestions)}
                            isLoading={isFetching}
                            error={error}
                            emptyClasses="empty-option"
                            errorClasses="empty-option"
                        >
                            {suggestions.map(suggestion => (
                                <button
                                    key={suggestion.id}
                                    type="button"
                                    className="option"
                                    onClick={() => handleSelect(suggestion)}
                                >
                                    {suggestion.text} {suggestion.description}
                                </button>
                            ))}
                        </DataCheck>
                    </div>
                )}
            </FormField>
        </div>
    );
};

export interface Address {
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    postCode?: string;
    town?: string;
    county?: string;
}

interface Props {
    name?: string;
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    onSelect?: (address: Address) => void;
    isAdmin?: boolean;
    handleAddressSelection?: () => void;
}

export default AddressLookup;
