import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getStore } from '@selectors/stores';

import UpdateStoreModal from './UpdateStoreModal';

import { RootState } from '@reducers/index';
import Store from './Store';
import DeleteStoreModal from './DeleteStoreModal';
import useStoresFetch from '../stores/hooks/useStoresFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';

const StoreContainer: React.FC = () => {
    const { id, action } = useParams<ParamTypes>();

    const { isFetching, fetchError } = useStoresFetch(+id);
    const store = useSelector((state: RootState) => getStore(state, +id));

    return (
        <DataCheck isLoading={isFetching} error={fetchError} isEmpty={isEmpty(store)}>
            <Store store={store} />
            {!!store && action === 'edit' && <UpdateStoreModal store={store} />}
            {!!store && action === 'delete' && <DeleteStoreModal store={store} />}
        </DataCheck>
    );
};

interface ParamTypes {
    id: string;
    action: string;
}

export default StoreContainer;
