import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';
import { getDecodedJwtToken } from './jwt';

export const getRoles = (): AdminUserRoles[] => {
    const jwt = getDecodedJwtToken();
    const roles = jwt?.roles;
    return roles ?? [];
};

/**
 * Checks if user is either super admin or has the given role
 * @role Role to check [Optional] (If omitted, will only check for super admin role)
 * @returns Whether user has role
 */
export const hasRole = (role?: number): boolean => {
    const roles = getRoles();
    if (roles.includes(AdminUserRoles.SuperAdmin)) return true;
    if (role == null) return false;
    return roles.includes(role);
};

export const hasRoles = (roles: number[], mode: 'all' | 'some'): boolean => {
    switch (mode) {
        case 'all':
            return roles.every(role => hasRole(role));
        case 'some':
            return roles.some(role => hasRole(role));
        default:
            return false;
    }
};

interface Address {
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
}

export const buildAddress = (address: Address): string => {
    const { addressLine1, addressLine2, addressLine3, town, county, postCode } = address;
    return [addressLine1, addressLine2, addressLine3, town, county, postCode]
        .filter(value => value)
        .join(', ');
};
