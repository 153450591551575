import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { PushNotification } from 'src/types/shared/PushNotification';

export const fetchSinglePushNotificationRequest = createAction(
    'fetchSinglePushNotificationRequest',
);
export const fetchSinglePushNotificationSuccess = createAction<PushNotification>(
    'fetchSinglePushNotificationSuccess',
);
export const fetchSinglePushNotificationFailure = createAction(
    'fetchSinglePushNotificationFailure',
);

export const fetchSinglePushNotification = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchSinglePushNotificationRequest());
    try {
        const { data } = await api.get<PushNotification>(`pushNotifications/${id}`);

        dispatch(fetchSinglePushNotificationSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSinglePushNotificationFailure, e);
    }
};
