import { CustomerFilters } from '@reducers/customers';
import { createAction } from '@reduxjs/toolkit';

export const setCustomerFilters = createAction<Record<string, any>>('setCustomerFilters');
export const clearCustomerFilters = createAction('clearCustomerFilters');

export const setCustomerFilter = createAction<{ key: keyof CustomerFilters; value: any }>(
    'setCustomerFilter',
);
export const resetCustomerFilter = createAction<string>('resetCustomerFilter');
