import { useEffect, useState } from 'react';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import UploadModal from './UploadModal';
import useDownloadFile from './hooks/useDownloadFile';
import Select from 'lib/src/components/form/Select';
import Error from 'lib/src/components/layout/error/Error';
import { useSelector } from 'react-redux';
import {
    getImportExportFetchSuccess,
    // getImportExportIsPosting,
    getImportExportPostSuccess,
} from '@selectors/importExport';
import usePrevious from 'lib/src/hooks/usePrevious';
import SuccessModal from './SuccessModal';
import ButtonRow from 'lib/src/components/button/ButtonRow';

const ImportExport: React.FC = () => {
    // const isPosting = useSelector(getImportExportIsPosting);
    const postSuccess = useSelector(getImportExportPostSuccess);
    const fetchSuccess = useSelector(getImportExportFetchSuccess);
    const prevProps = usePrevious({ postSuccess, fetchSuccess });
    const [showUploadMatchingModal, setShowUploadMatchingModal] = useState(false);
    const [showUploadSuppressionsModal, setShowUploadSuppressionsModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const {
        downloadType,
        handleChangeType,
        typeOptions,
        downloadFile,
        isFetching,
        fetchError,
    } = useDownloadFile();

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            setShowUploadMatchingModal(false);
            setShowUploadSuppressionsModal(false);
        }
        if ((postSuccess && !prevProps.postSuccess) || (fetchSuccess && !prevProps.fetchSuccess)) {
            setShowSuccessModal(true);
        }
    }, [postSuccess, fetchSuccess, prevProps]);

    return (
        <>
            <CreateHeader>
                <Title>Import/Export</Title>
            </CreateHeader>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Import .txt file">
                        <ButtonRow alignment="left" extraClasses="top-margin">
                            <ActionButton
                                color="yellow"
                                icon="upload"
                                onClick={() => setShowUploadMatchingModal(true)}
                                className="import-export-button"
                            >
                                Matching
                            </ActionButton>
                            <ActionButton
                                color="yellow"
                                icon="upload"
                                onClick={() => setShowUploadSuppressionsModal(true)}
                                className="import-export-button"
                            >
                                Suppressions
                            </ActionButton>
                        </ButtonRow>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Export .csv file">
                        <div style={{ marginTop: '20px' }} />
                        <Select
                            name="exportType"
                            options={typeOptions}
                            value={downloadType}
                            onChange={handleChangeType}
                            placeholder="Select export type"
                            disableCross
                        />
                        {fetchError && <Error>{fetchError}</Error>}
                        <ActionButton
                            color="green"
                            icon="download"
                            onClick={() => downloadFile()}
                            className="import-export-button"
                            isPosting={isFetching}
                        >
                            Export
                        </ActionButton>
                    </ContentItem>
                    <ContentItem label="" />
                    <ContentItem label="" />
                </ContentRow>
            </ContentBlock>
            {(showUploadMatchingModal || showUploadSuppressionsModal) && (
                <UploadModal
                    closeUploadModal={() => {
                        setShowUploadMatchingModal(false);
                        setShowUploadSuppressionsModal(false);
                    }}
                    isMatching={showUploadMatchingModal}
                />
            )}
            {showSuccessModal && (
                <SuccessModal
                    setShowSuccessModal={setShowSuccessModal}
                    fetchSuccess={fetchSuccess}
                    postSuccess={postSuccess}
                />
            )}
        </>
    );
};

export default ImportExport;
