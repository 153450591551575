import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import {
    getCustomerIsPosting,
    getCustomerPostSuccess,
    getCustomersError,
} from '@selectors/customers';
import { useHistory } from 'react-router-dom';
import { ContactPreferences, Customer, SuppressedStatuses } from 'src/types/shared/Customer';
import { updateCustomer } from '@actions/customers';
import dayjs from 'dayjs';

export default function useUpdateCustomer({
    id,
    title,
    firstName,
    lastName,
    addressLine1,
    addressLine2,
    addressLine3,
    town,
    county,
    postCode,
    contactEmail,
    phone,
    dateOfBirth,
    gender,
    storeID,
    suppressedStatus,
    emailSuppressedFlag,
    contactPreferences,
    isRegistered,
}: Customer): UpdateCustomerReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/customers/${id}`);
    }, [history, id]);

    const [formState, handleChange] = useForm({
        id,
        title,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        addressLine3,
        town,
        county,
        postCode,
        contactEmail,
        phone,
        dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
        gender,
        storeID,
        suppressedStatus,
        emailSuppressedFlag,
        contactPreferences,
        createUser: false,
        password: '',
        confirmPassword: '',
    });

    const handleSubmit = useCallback(() => {
        const { storeID, dateOfBirth } = formState;
        if (storeID == null) return;
        dispatch(
            updateCustomer(id, {
                ...formState,
                dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : null,
                storeID,
                password:
                    formState.createUser || (isRegistered && formState.password.length > 0)
                        ? formState.password
                        : null,
            }),
        );
    }, [dispatch, id, formState, isRegistered]);

    const isPosting = useSelector(getCustomerIsPosting);
    const error = useSelector(getCustomersError);
    const postSuccess = useSelector(getCustomerPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        handleSubmit,
        formState,
        handleChange,
        closeModal,
        isPosting,
        error,
    };
}

interface FormState {
    title: string | null;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
    contactEmail: string | null;
    phone: string | null;
    dateOfBirth: Date | null;
    gender: string | null;
    storeID: number | null;
    contactPreferences: ContactPreferences;
    suppressedStatus: SuppressedStatuses;
    emailSuppressedFlag: number;
    createUser: boolean;
    password: string;
    confirmPassword: string;
}

interface UpdateCustomerReturn {
    handleSubmit: () => void;
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    closeModal: () => void;
    isPosting: boolean;
    error: string | null;
}
