import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import { PushNotificationStatuses } from '@customTypes/shared/PushNotification';
import { Voucher } from '@customTypes/shared/Voucher';
import NotificationStatus from '@pages/pushNotifications/pushNotification/NotificationStatus';
import dayjs from 'dayjs';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import Table from 'lib/src/components/table/Table';
import { Column } from 'lib/src/types/table';
import React, { ReactElement } from 'react';

interface Props {
    voucher: Voucher;
}

const VoucherPushNotificationsTable = ({ voucher }: Props): ReactElement => {
    let rows = [...voucher.pushNotifications].map<Row>(voucherPushNotification => {
        const {
            id,
            title,
            sentOn,
            sendOn,
            status,
            errorMessage,
            totalSent,
            totalToSend,
        } = voucherPushNotification;
        return {
            id,
            title,
            date: sentOn ?? sendOn,
            status,
            errorMessage,
            totalSent,
            totalToSend,
        };
    });

    if (rows.length > 0) {
        rows.push({
            key: -1,
            title: 'Voucher Start Date',
            date: voucher.validFrom,
            color: 'green',
        });

        rows.push({
            key: -2,
            title: 'Voucher End Date',
            date: voucher.validTo,
            color: 'red',
        });
    }

    rows = rows.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());

    return (
        <ContentBlock>
            <ContentRow>
                <ContentItem label="Push Notifications">
                    <Table columns={columns} rows={rows} pageSizes={[100]} />
                </ContentItem>
            </ContentRow>
        </ContentBlock>
    );
};

interface Row {
    key?: number;
    id?: number;
    title: string;
    date: string;
    status?: PushNotificationStatuses;
    color?: string;
    errorMessage?: string | null;
    totalSent?: number | null;
    totalToSend?: number | null;
}

const columns: Column<Row>[] = [
    {
        key: 0,
        heading: 'Title',
        getValue: (row: Row) => row.title ?? '',
    },
    {
        key: 1,
        heading: 'Send Date',
        getValue: (row: Row) => dayjs(row.date).format('DD/MM/YYYY - HH:mm '),
    },
    {
        key: 3,
        heading: 'Status',
        getValue: (row: Row) => {
            if (row.status == null) return <></>;
            return (
                <NotificationStatus status={row.status} errorMessage={row.errorMessage || null} />
            );
        },
    },
    {
        key: 4,
        heading: 'Sent To / Total',
        getValue: (row: Row) => {
            if (row.totalSent === undefined || row.totalToSend === undefined) return <></>;
            if (!row.totalSent || !row.totalToSend) return <p>-</p>;
            return (
                <p>
                    {row.totalSent} / {row.totalToSend}
                </p>
            );
        },
    },
    {
        key: 5,
        heading: '',
        getValue: (row: Row) => {
            if (row.id == null) return <></>;
            return (
                <ButtonRow alignment="right">
                    <LinkButton href={`/push-notifications/${row.id}`}>View</LinkButton>
                    <LinkButton color="grey" href={`/push-notifications/${row.id}/edit`}>
                        Edit
                    </LinkButton>
                    <LinkButton color="red" href={`/push-notifications/${row.id}/delete`}>
                        Delete
                    </LinkButton>
                </ButtonRow>
            );
        },
    },
];
export default VoucherPushNotificationsTable;
