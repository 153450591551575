import React from 'react';

import { AdminUser } from 'src/types/shared/AdminUser';
import { convertEnumToDropdownOption } from 'lib/src/shared/enums/dropdownEnums';

import useUpdateAdmin from './hooks/useUpdateAdmin';

import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import MultiSelect from 'lib/src/components/form/MultiSelect';
import TextInput from 'lib/src/components/form/TextInput';
import Modal from 'lib/src/components/modal/Modal';
import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';
import Checkbox from 'lib/src/components/form/Checkbox';

const adminRoleOptions = convertEnumToDropdownOption(AdminUserRoles).filter(
    option => option.value !== AdminUserRoles.SuperAdmin,
);

const UpdateAdminModal: React.FC<UpdateAdminProps> = ({ user }) => {
    const {
        handleSubmit,
        handleChange,
        closeModal,
        isPosting,
        // error,
        formState: { email, firstName, lastName, roles },
    } = useUpdateAdmin(user);

    const isSuperAdmin = roles.includes(AdminUserRoles.SuperAdmin);

    return (
        <Modal title="Update Admin" size="small" closeModal={closeModal}>
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <TextInput
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        label="Email address"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        label="First name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        label="Surname"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="isSuperAdmin"
                        value={isSuperAdmin}
                        onChange={() =>
                            handleChange('roles', isSuperAdmin ? [] : [AdminUserRoles.SuperAdmin])
                        }
                        label="Is Super Admin"
                    />
                    <MultiSelect
                        name="roles"
                        value={isSuperAdmin ? [] : roles}
                        options={adminRoleOptions}
                        onChange={handleChange}
                        label="Admin roles"
                        disabled={isSuperAdmin}
                        required={!isSuperAdmin}
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateAdminProps {
    user: AdminUser;
}

export default UpdateAdminModal;
