import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import { getStoreIsPosting, getStorePostSuccess, getStoresError } from '@selectors/stores';
import { useHistory } from 'react-router-dom';
import { BusinessHours, Store } from 'src/types/shared/Store';
import { updateStore } from '@actions/stores';

export default function useUpdateStore({
    id,
    businessHours,
    shortName,
    name,
    website,
    imageS3Key,
    homeScreenImageS3Key,
    googleMapsUrl,
    addressLine1,
    addressLine2,
    addressLine3,
    town,
    county,
    postCode,
}: Store): UpdateStoreReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/stores/${id}`);
    }, [history, id]);

    const [formState, handleChange] = useForm({
        businessHours,
        shortName,
        name,
        website,
        imageS3Key,
        googleMapsUrl,
        homeScreenImageS3Key,
        addressLine1,
        addressLine2,
        addressLine3,
        town,
        county,
        postCode,
    });

    const handleSubmit = useCallback(() => {
        dispatch(updateStore(id, formState));
    }, [dispatch, id, formState]);

    const isPosting = useSelector(getStoreIsPosting);
    const error = useSelector(getStoresError);
    const postSuccess = useSelector(getStorePostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        handleSubmit,
        formState,
        handleChange,
        closeModal,
        isPosting,
        error,
    };
}

interface FormState {
    businessHours: BusinessHours;
    shortName: string;
    name: string;
    website: string;
    imageS3Key: string;
    homeScreenImageS3Key: string;
    googleMapsUrl: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
}

interface UpdateStoreReturn {
    handleSubmit: () => void;
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    closeModal: () => void;
    isPosting: boolean;
    error: string | null;
}
