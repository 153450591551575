import { createAction, Dispatch } from '@reduxjs/toolkit';
import {
    PostcodeAddress,
    PostcodeRetrieveRequest,
    PostcodeRetrieveResponse,
} from '../../../types/shared/PostcodeFinder';
import { api, handleApiErrors } from '../../../utils/api';
import { APIError } from '../../../types/APIError';

// postcode retrieve (step 3)
export const postcodeRetrieveRequest = createAction('postcodeRetrieveRequest');
export const postcodeRetrieveSuccess = createAction<PostcodeAddress[]>('postcodeRetrieveSuccess');
export const postcodeRetrieveFailure = createAction('postcodeRetrieveFailure');

export const postcodeRetrieve = (isAdmin: boolean, postBody: PostcodeRetrieveRequest) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(postcodeRetrieveRequest());

    try {
        const { data } = await api.post<PostcodeRetrieveRequest, PostcodeRetrieveResponse>(
            `${isAdmin ? 'customers' : 'user'}/postcode/retrieve`,
            postBody,
        );

        dispatch(postcodeRetrieveSuccess(data.items));
    } catch (e) {
        handleApiErrors(dispatch, postcodeRetrieveFailure, e as APIError);
    }
};
