const ButtonRow: React.FC<ButtonRowProps> = ({ children, alignment = 'center', extraClasses = '' }) => (
    <div className={`button-row ${alignment} ${extraClasses}`}>{children}</div>
);

interface ButtonRowProps {
    alignment?: string;
    extraClasses?: string;
}

export default ButtonRow;
