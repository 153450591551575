import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Voucher } from 'src/types/shared/Voucher';

export const fetchSingleVoucherRequest = createAction('fetchSingleVoucherRequest');
export const fetchSingleVoucherSuccess = createAction<Voucher>('fetchSingleVoucherSuccess');
export const fetchSingleVoucherFailure = createAction('fetchSingleVoucherFailure');

export const fetchSingleVoucher = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSingleVoucherRequest());
    try {
        const { data } = await api.get<Voucher>(`vouchers/${id}`);

        dispatch(fetchSingleVoucherSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleVoucherFailure, e);
    }
};
