import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { Action, ActionCreator, Dispatch } from 'redux';
import { setFieldErrors } from '../redux/actions/fieldErrors';
import { addFormError } from '../redux/actions/fieldErrors';
import { setRedirectUrl } from '../redux/actions/redirects';

export const getConfig = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
});

let API_URL = '';
export const initApi = (apiUrl: string) => {
    API_URL = apiUrl;
};
const get = <TResponseBody>(
    url: string,
    params?: AxiosRequestConfig['params'],
    cancelTokenSource?: CancelTokenSource,
): Promise<AxiosResponse<TResponseBody>> => {
    return axios.get<TResponseBody>(`${API_URL}/${url}`, {
        ...getConfig(),
        params,
        cancelToken: cancelTokenSource?.token,
    });
};
const post = <TRequestBody, TResponseBody>(
    url: string,
    data: TRequestBody,
    params?: AxiosRequestConfig['params'],
    cancelTokenSource?: CancelTokenSource,
) => {
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, {
        ...getConfig(),
        params,
        cancelToken: cancelTokenSource?.token,
    });
};
const put = <TRequestBody, TResponseBody>(
    url: string,
    data: TRequestBody,
    params?: AxiosRequestConfig['params'],
) => {
    return axios.put<TResponseBody>(`${API_URL}/${url}`, data, { ...getConfig(), params });
};
const patch = <TRequestBody, TResponseBody>(
    url: string,
    data: TRequestBody,
    params?: AxiosRequestConfig['params'],
) => {
    return axios.patch<TResponseBody>(`${API_URL}/${url}`, data, { ...getConfig(), params });
};
const del = <TResponseBody>(url: string, params?: AxiosRequestConfig['params']) => {
    return axios.delete<TResponseBody>(`${API_URL}/${url}`, { ...getConfig(), params });
};
export const api = {
    get,
    post,
    put,
    patch,
    delete: del,
};

const isAxiosError = (error: any): error is AxiosError => {
    return error.isAxiosError;
};

export const handleApiErrors = <A extends Action>(
    dispatch: Dispatch,
    failureAction: ActionCreator<A>,
    err: any,
) => {
    if (isAxiosError(err)) {
        const { response, message } = err;

        if (response && [400, 409].includes(response.status)) {
            typeof response.data === 'string'
                ? dispatch(addFormError(response.data))
                : dispatch(setFieldErrors(response.data.errors));

            return dispatch(failureAction(null));
        }
        if (response && response.status === 401) {
            dispatch(failureAction('Unauthorized'));
            localStorage.removeItem('token');
            return dispatch(setRedirectUrl('/auth/login'));
        }

        return dispatch(failureAction(message));
    }
};
