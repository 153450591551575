import { fetchAllCustomers, fetchCustomersCount, fetchSingleCustomer } from '@actions/customers';
import { CustomerFilters } from '@reducers/customers';
import {
    getCustomers,
    getCustomersCount,
    getCustomersError,
    getCustomersIsFetching,
    getCustomersCountIsFetching,
} from '@selectors/customers';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Customer } from 'src/types/shared/Customer';
import useCustomerSearchFilters from './useCustomerSearchFilters';

const useCustomersFetch = (
    limit?: number | null,
    page?: number | null,
    customerId?: number,
): CustomersFetchReturn => {
    const dispatch = useDispatch();

    const { filters, setFilter } = useCustomerSearchFilters();
    const [_filters, _setFilters] = useState<CustomerFilters>(filters);

    useEffect(() => {
        if (limit && page && _filters) {
            dispatch(fetchAllCustomers(limit, page, _filters.status, _filters));
        } else if (customerId != null) dispatch(fetchSingleCustomer(customerId));
    }, [dispatch, limit, page, _filters, customerId]);

    useEffect(() => {
        if (limit && page && _filters) {
            dispatch(fetchCustomersCount(limit, page, _filters.status, _filters));
        }
    }, [dispatch, _filters]); /* eslint-disable-line */

    const handleSubmit = () => _setFilters(filters);

    const customers = Object.values(useSelector(getCustomers)).sort((a, b) => a.sort - b.sort);
    const count = useSelector(getCustomersCount);
    const isFetching = useSelector(getCustomersIsFetching);
    const fetchError = useSelector(getCustomersError);
    const isFetchingCount = useSelector(getCustomersCountIsFetching);

    return {
        customers,
        count,
        isFetching,
        fetchError,
        filters,
        setFilter,
        handleSubmit,
        isFetchingCount,
    };
};

interface CustomersFetchReturn {
    customers: Customer[];
    count: number | null;
    isFetching: boolean;
    fetchError: string | null;
    filters: CustomerFilters;
    setFilter: (key: keyof CustomerFilters, value: any) => void;
    handleSubmit: () => void;
    isFetchingCount: boolean;
}

export default useCustomersFetch;
