const ActionButton: React.FC<ActionButtonProps> = ({
    children,
    type = 'submit',
    color = 'blue',
    icon,
    isPosting = false,
    disabled = false,
    onClick = () => {},
    className = '',
}) => (
    <button
        className={`button ${color} ${className}`}
        type={type}
        disabled={isPosting || disabled}
        onClick={onClick}
    >
        {icon && !isPosting && <i className={`icon far fa-fw fa-${icon}`}></i>}
        {isPosting && <i className="icon far fa-fw fa-spinner fa-spin"></i>}
        <span className="text">{children}</span>
    </button>
);

interface ActionButtonProps {
    type?: 'submit' | 'button' | 'reset';
    color?: string;
    icon?: string;
    isPosting?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean;
    className?: string;
}

export default ActionButton;
