import React from 'react';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import Modal from 'lib/src/components/modal/Modal';
import { FILE_TYPES } from 'lib/src/constants/fileMimeTypes';
import useUploadFile from './hooks/useUploadFile';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import config from '../../config/index';

const { TXT } = FILE_TYPES;

const UploadModal: React.FC<UploadModalProps> = ({ closeUploadModal = () => {}, isMatching }) => {
    const { formData, onChange, handleSubmit, isPosting, error } = useUploadFile(isMatching);
    const { API_URL, S3_URL } = config;

    return (
        <Modal title="Import .txt file" size="small" closeModal={closeUploadModal}>
            <Form onCancel={closeUploadModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormRow>
                    <FilePicker
                        label="Choose File"
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        name="fileS3Key"
                        value={formData.fileS3Key}
                        onChange={onChange}
                        maxFiles={1}
                        acceptedTypes={`${TXT}`}
                        largeFile
                    />
                </FormRow>
                {error && <p className="form-generic-error">{error}</p>}
            </Form>
        </Modal>
    );
};

interface UploadModalProps {
    closeUploadModal: () => void;
    isMatching: boolean;
}

export default UploadModal;
