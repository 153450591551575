import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteVoucherRequest = createAction('deleteVoucherRequest');
export const deleteVoucherSuccess = createAction('deleteVoucherSuccess');
export const deleteVoucherFailure = createAction('deleteVoucherFailure');

export const deleteVoucher = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteVoucherRequest());

    try {
        await api.delete(`vouchers/${id}`);

        dispatch(deleteVoucherSuccess());
    } catch (e) {
        handleApiErrors(dispatch, deleteVoucherFailure, e);
    }
};
