import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import useCreateStore from './hooks/useCreateStore';
import TextInput from 'lib/src/components/form/TextInput';
import Checkbox from 'lib/src/components/form/Checkbox';
import FormSubTitle from 'lib/src/components/form/FormSubTitle';
import { toTitleCase } from 'lib/src/utils/generic';
import { Fragment } from 'react';
import dayjs from 'dayjs';
import config from '@config/index';
import { FILE_TYPES } from 'lib/src/constants/fileMimeTypes';
import FilePicker from 'lib/src/components/filePicker/FilePicker';
import { days } from 'lib/src/constants/generic';
import { Day } from '@customTypes/shared/generic';
import AddressLookup, { Address } from 'lib/src/components/form/AddressLookup';

const { API_URL, S3_URL } = config;
const { PNG, JPG } = FILE_TYPES;

const CreateStoreModal: React.FC<CreateCustomerProps> = ({ closeModal }) => {
    const {
        formState: {
            name,
            shortName,
            website,
            businessHours,
            imageS3Key,
            homeScreenImageS3Key,
            googleMapsUrl,
            addressLine1,
            addressLine2,
            addressLine3,
            town,
            county,
            postCode,
        },
        handleChange,
        handleSubmit,
        isPosting,
    } = useCreateStore(closeModal);

    const handleSelect = (address: Address) => {
        const keys: Array<keyof Address> = [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'postCode',
            'town',
            'county',
        ];

        for (const key of keys) handleChange(key, address[key]);
    };

    return (
        <Modal closeModal={closeModal} title="Create Store">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormSubTitle>Business Details</FormSubTitle>
                <FormRow>
                    <TextInput
                        name="name"
                        value={name}
                        onChange={handleChange}
                        label="Name"
                        placeholder="Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="shortName"
                        value={shortName}
                        onChange={handleChange}
                        label="Short Name"
                        placeholder="Forename"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="website"
                        value={website}
                        onChange={handleChange}
                        label="Website"
                        placeholder="Website"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="googleMapsUrl"
                        value={googleMapsUrl}
                        onChange={handleChange}
                        label="Google Maps URL"
                        placeholder="Google Maps URL"
                        required
                    />
                </FormRow>
                <FormSubTitle>Address</FormSubTitle>
                <FormRow>
                    <AddressLookup label="Address Lookup" onSelect={handleSelect} isAdmin />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="addressLine1"
                        value={addressLine1}
                        onChange={handleChange}
                        label="Address Line 1"
                        required
                    />
                    <TextInput
                        name="addressLine2"
                        value={addressLine2 ?? ''}
                        onChange={handleChange}
                        label="Address Line 2"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="addressLine3"
                        value={addressLine3 ?? ''}
                        onChange={handleChange}
                        label="Address Line 3"
                    />
                    <TextInput
                        name="postCode"
                        value={postCode}
                        onChange={handleChange}
                        label="Post Code"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="town"
                        value={town ?? ''}
                        onChange={handleChange}
                        label="Town"
                    />
                    <TextInput
                        name="county"
                        value={county ?? ''}
                        onChange={handleChange}
                        label="County"
                    />
                </FormRow>
                <FormSubTitle>Business Hours</FormSubTitle>
                {days.map(day => {
                    day = day.toLowerCase();
                    const hours = businessHours[day as Day];
                    if (!hours) return null;

                    return (
                        <Fragment key={day}>
                            {toTitleCase(day)}
                            <FormRow>
                                <Checkbox
                                    name={`${day}IsClosed`}
                                    value={hours.isClosed}
                                    onChange={(_, value) =>
                                        handleChange('businessHours', {
                                            ...businessHours,
                                            [day]: { hours, isClosed: value },
                                        })
                                    }
                                    label="Closed"
                                />
                                <TextInput
                                    name={`${day}OpenTime`}
                                    type="time"
                                    value={hours.isClosed ? '' : hours.openTime}
                                    onChange={(_, value) =>
                                        handleChange('businessHours', {
                                            ...businessHours,
                                            [day]: {
                                                ...hours,
                                                openTime: value,
                                            },
                                        })
                                    }
                                    label="Open Time"
                                    disabled={hours.isClosed}
                                    required={!hours.isClosed}
                                />
                                <TextInput
                                    name={`${day}CloseTime`}
                                    type="time"
                                    value={hours.isClosed ? '' : hours.closeTime}
                                    onChange={(_, value) =>
                                        handleChange('businessHours', {
                                            ...businessHours,
                                            [day]: {
                                                ...hours,
                                                closeTime: value,
                                            },
                                        })
                                    }
                                    label="Close Time"
                                    disabled={hours.isClosed}
                                    required={!hours.isClosed}
                                    customValidate={value =>
                                        !hours.isClosed &&
                                        !dayjs(`0000-00-00T${value}`).isAfter(
                                            `0000-00-00T${hours.openTime}`,
                                        )
                                            ? 'Close time must be after open time'
                                            : null
                                    }
                                />
                            </FormRow>
                        </Fragment>
                    );
                })}
                <FormRow>
                    <FilePicker
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        name="imageS3Key"
                        value={imageS3Key ? [imageS3Key] : []}
                        onChange={(name, value) => handleChange(name, value ? value[0] : [])}
                        maxFiles={1}
                        acceptedTypes={`${JPG}, ${PNG}`}
                        label="Thumbnail Image"
                        required
                    />
                </FormRow>
                <FormRow>
                    <FilePicker
                        apiURL={API_URL}
                        storageURL={S3_URL}
                        name="homeScreenImageS3Key"
                        value={homeScreenImageS3Key ? [homeScreenImageS3Key] : []}
                        onChange={(name, value) => handleChange(name, value ? value[0] : [])}
                        maxFiles={1}
                        acceptedTypes={`${JPG}, ${PNG}`}
                        label="Home Image"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface CreateCustomerProps {
    closeModal: () => void;
}

export default CreateStoreModal;
