import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Store, StorePost } from 'src/types/shared/Store';

export const createStoreRequest = createAction('createStoreRequest');
export const createStoreSuccess = createAction<Store>('createStoreSuccess');
export const createStoreFailure = createAction('createStoreFailure');

export const createStore = (postbody: StorePost) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(createStoreRequest());
    try {
        const { data }: { data: Store } = await api.post(`stores`, postbody);

        dispatch(createStoreSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createStoreFailure, e);
    }
};
