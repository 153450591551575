import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CustomerContainer from '@pages/customers/customer/CustomerContainer';
import Customers from '@pages/customers/customers/Customers';

const CustomersRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Customers />
            </Route>
            <Route exact path={`${path}/:id`}>
                <CustomerContainer />
            </Route>
            <Route exact path={`${path}/:id/:action`}>
                <CustomerContainer />
            </Route>
        </Switch>
    );
};

export default CustomersRoutes;
