import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import { getStorePostSuccess, getStoreIsPosting, getStoresError } from '@selectors/stores';
import { useHistory } from 'react-router-dom';
import { deleteStoreException } from '@actions/stores';

export default function useDeleteStoreException(id: number, storeID: number): UseDeleteStoreReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteStoreException(id, storeID));
    }, [dispatch, id, storeID]);

    const isPosting = useSelector(getStoreIsPosting);
    const error = useSelector(getStoresError);
    const deleteSuccess = useSelector(getStorePostSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace(`/stores/${storeID}/exceptions`);
        }
    }, [deleteSuccess, prevDeleteSuccess, history, storeID]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteStoreReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
