import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import { deleteStore } from '@actions/stores/deleteStore';
import { getStorePostSuccess, getStoreIsPosting, getStoresError } from '@selectors/stores';
import { useHistory } from 'react-router-dom';

export default function useDeleteStore(storeID: number): UseDeleteStoreReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteStore(storeID));
    }, [dispatch, storeID]);

    const isPosting = useSelector(getStoreIsPosting);
    const error = useSelector(getStoresError);
    const deleteSuccess = useSelector(getStorePostSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace('/stores');
        }
    }, [deleteSuccess, prevDeleteSuccess, history]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteStoreReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
