import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    deleteCustomerFailure,
    deleteCustomerRequest,
    deleteCustomerSuccess,
    fetchAllCustomersFailure,
    fetchAllCustomersRequest,
    fetchAllCustomersSuccess,
    fetchSingleCustomerFailure,
    fetchSingleCustomerRequest,
    fetchSingleCustomerSuccess,
    createCustomerFailure,
    createCustomerRequest,
    createCustomerSuccess,
    updateCustomerFailure,
    updateCustomerRequest,
    updateCustomerSuccess,
    updateCustomerPasswordFailure,
    updateCustomerPasswordRequest,
    updateCustomerPasswordSuccess,
    FetchAllCustomersResponse,
    FetchCustomersCountResponse,
    fetchCustomersCountRequest,
    fetchCustomersCountSuccess,
    fetchCustomersCountFailure,
} from '@actions/customers';
import {
    importCustomersCancel,
    importCustomersFailure,
    importCustomersRequest,
    importCustomersSuccess,
} from '@actions/customers/postImportCustomers';
import { CancelTokenSource } from 'axios';
import {
    setCustomerFilter,
    clearCustomerFilters,
    resetCustomerFilter,
    setCustomerFilters,
} from '@actions/customers/customerFilters';
import { Customer, SuppressedStatuses } from '@customTypes/shared/Customer';

interface CustomersState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    customers: { [key: number]: Customer };
    page: number | null;
    cancelTokenSource?: CancelTokenSource | null;
    filters: CustomerFilters;
    count: number | null;
    isFetchingCount: boolean;
    countError: string | null;
}
export interface CustomerFilters extends Record<string, any> {
    status: SuppressedStatuses | null;
    id: null | number;
    firstName: string;
    lastName: string;
    contactEmail: string;
    addressLine1: string;
    postCode: string;
    page: number;
    limit: number;
}

const initialFilters: CustomerFilters = {
    status: null,
    id: null,
    firstName: '',
    lastName: '',
    contactEmail: '',
    addressLine1: '',
    postCode: '',
    page: 1,
    limit: 10,
};

const initialState: CustomersState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    customers: {},
    page: null,
    cancelTokenSource: null,
    filters: initialFilters,
    count: null,
    isFetchingCount: false,
    countError: null,
};

export default createReducer(initialState, {
    [fetchAllCustomersRequest.type]: handleFetchRequest,
    [fetchAllCustomersSuccess.type]: handleFetchCustomersSuccess,
    [fetchAllCustomersFailure.type]: handleFetchError,
    [fetchSingleCustomerRequest.type]: handleFetchRequest,
    [fetchSingleCustomerSuccess.type]: handleFetchSingleCustomerSuccess,
    [fetchSingleCustomerFailure.type]: handleFetchError,
    [createCustomerRequest.type]: handlePostRequest,
    [createCustomerSuccess.type]: handleCreateCustomerSuccess,
    [createCustomerFailure.type]: handlePostError,
    [updateCustomerRequest.type]: handlePostRequest,
    [updateCustomerSuccess.type]: handleUpdateCustomerSuccess,
    [updateCustomerFailure.type]: handlePostError,
    [updateCustomerPasswordRequest.type]: handlePostRequest,
    [updateCustomerPasswordSuccess.type]: handleUpdateCustomerPasswordSuccess,
    [updateCustomerPasswordFailure.type]: handlePostError,
    [deleteCustomerRequest.type]: handleDeleteRequest,
    [deleteCustomerFailure.type]: handleDeleteError,
    [deleteCustomerSuccess.type]: handleDeleteSuccess,

    [importCustomersRequest.type]: handlePostRequest,
    [importCustomersFailure.type]: handlePostError,
    [importCustomersSuccess.type]: handleImportCustomersSuccess,
    [importCustomersCancel.type]: handlePostCancel,

    [setCustomerFilters.type]: handleSetCustomerFilters,
    [clearCustomerFilters.type]: handleClearCustomerFilters,
    [setCustomerFilter.type]: handleAddCustomerFilter,
    [resetCustomerFilter.type]: handleRemoveCustomerFilter,

    [fetchCustomersCountRequest.type]: handleCountFetchRequest,
    [fetchCustomersCountSuccess.type]: handleFetchCustomersCountSuccess,
    [fetchCustomersCountFailure.type]: handleFetchCountError,
});

function handleFetchRequest(state: CustomersState) {
    state.isFetching = true;
}

function handleCountFetchRequest(state: CustomersState) {
    state.isFetchingCount = true;
}

function handlePostRequest(state: CustomersState, action: PayloadAction<CancelTokenSource>) {
    if (action.payload != null) state.cancelTokenSource = action.payload;
    state.isPosting = true;
    state.postSuccess = false;
}
function handleDeleteRequest(state: CustomersState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handleFetchError(state: CustomersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
function handleFetchCountError(state: CustomersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetchingCount = false;
}
function handlePostError(state: CustomersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}
function handleDeleteError(state: CustomersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}

function handleFetchCustomersSuccess(
    state: CustomersState,
    action: PayloadAction<FetchAllCustomersResponse>,
) {
    state.customers = convertArrToObj(action.payload.customers);
    state.page = action.payload.page;
    state.isFetching = false;
}

function handleFetchCustomersCountSuccess(
    state: CustomersState,
    action: PayloadAction<FetchCustomersCountResponse>,
) {
    state.count = action.payload.count;
    state.isFetchingCount = false;
}

function handleFetchSingleCustomerSuccess(state: CustomersState, action: PayloadAction<Customer>) {
    state.customers[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleCreateCustomerSuccess(state: CustomersState, action: PayloadAction<Customer>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.customers[action.payload.id] = action.payload;
}
function handleUpdateCustomerSuccess(state: CustomersState, action: PayloadAction<Customer>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.customers[action.payload.id] = action.payload;
}
function handleUpdateCustomerPasswordSuccess(state: CustomersState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleDeleteSuccess(state: CustomersState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.customers[action.payload];
}

function handleImportCustomersSuccess(state: CustomersState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handlePostCancel(state: CustomersState) {
    state.cancelTokenSource?.cancel();
    state.cancelTokenSource = null;
    state.isPosting = false;
}

function handleSetCustomerFilters(state: CustomersState, action: PayloadAction<CustomerFilters>) {
    state.filters = action.payload;
}

function handleClearCustomerFilters(state: CustomersState) {
    state.filters = {} as CustomerFilters;
}

function handleAddCustomerFilter(
    state: CustomersState,
    action: PayloadAction<{ key: string; value: any }>,
) {
    state.filters[action.payload.key] = action.payload.value;
}

function handleRemoveCustomerFilter(state: CustomersState, action: PayloadAction<string>) {
    state.filters[action.payload] = initialFilters[action.payload];
}
