import React from 'react';

import Title from 'lib/src/components/typography/Title';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { PushNotification as PushNotificationTypes } from '@customTypes/shared/PushNotification';
import dayjs from 'dayjs';
import NotificationStatus from './NotificationStatus';

const PushNotification: React.FC<PushNotificationProps> = ({ pushNotification }) => {
    if (!pushNotification) return null;

    const {
        id,
        voucherId,
        voucherName,
        createdByAdminUserId,
        createdByAdminUserName,
        title,
        message,
        sendOn,
        sentOn,
        createdOn,
        updatedOn,
        status,
        errorMessage,
        totalSent,
        totalToSend,
    } = pushNotification;

    return (
        <>
            <Title backHref="/push-notifications">Push Notification - {title}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Title">
                        <p>{title}</p>
                    </ContentItem>
                    <ContentItem label="Message">
                        <p>{message}</p>
                    </ContentItem>
                    <ContentItem label="Voucher">
                        <p>
                            {voucherId ? (
                                <a href={`/vouchers/${voucherId}`} target="_blank" rel="noreferrer">
                                    {voucherName}
                                </a>
                            ) : (
                                'N/A'
                            )}
                        </p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Status">
                        <NotificationStatus status={status} errorMessage={errorMessage} />
                    </ContentItem>
                    <ContentItem label="Sent To / Total">
                        <p>{!totalSent || !totalToSend ? '-' : `${totalSent} / ${totalToSend}`}</p>
                    </ContentItem>
                    <ContentItem label="Send Date">
                        <p>{dayjs(sendOn).format('DD-MM-YYYY - HH:mm')}</p>
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Sent / Will Send">
                        <p>{dayjs(sentOn ? sentOn : sendOn).fromNow()}</p>
                    </ContentItem>
                    <ContentItem label="Created On">
                        <p>{dayjs(createdOn).format('DD-MM-YYYY - HH:mm')}</p>
                    </ContentItem>
                    <ContentItem label="Updated On">
                        {updatedOn ? <p>{dayjs(updatedOn).format('DD-MM-YYYY - HH:mm')}</p> : 'N/A'}
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Created By">
                        <p>
                            <a
                                href={`/admins/${createdByAdminUserId}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {createdByAdminUserName}
                            </a>
                        </p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <LinkButton color="grey" href={`/push-notifications/${id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="red" href={`/push-notifications/${id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        </>
    );
};

interface PushNotificationProps {
    pushNotification: PushNotificationTypes | undefined;
}
export default PushNotification;
