import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getCustomer } from '@selectors/customers';

import DeleteCustomerModal from './DeleteCustomerModal';
import UpdateCustomerModal from './UpdateCustomerModal';

import { RootState } from '@reducers/index';
import Customer from './Customer';
import useCustomersFetch from '../customers/hooks/useCustomersFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';
import { hasRole } from '@utils/generic';

const CustomerContainer: React.FC = () => {
    const { id, action } = useParams<ParamTypes>();

    const { isFetching, fetchError } = useCustomersFetch(null, null, +id);
    const customer = useSelector((state: RootState) => getCustomer(state, +id));

    return (
        <DataCheck isLoading={isFetching} error={fetchError} isEmpty={isEmpty(customer)}>
            <Customer customer={customer} />
            {action === 'delete' && hasRole() && <DeleteCustomerModal customer={customer} />}
            {!!customer && action === 'edit' && <UpdateCustomerModal customer={customer} />}
        </DataCheck>
    );
};

interface ParamTypes {
    id: string;
    action: string;
}

export default CustomerContainer;
