import useStoresFetch from '@pages/stores/stores/hooks/useStoresFetch';
import Checkbox from 'lib/src/components/form/Checkbox';
import DatePicker from 'lib/src/components/form/DatePicker';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import FormSubTitle from 'lib/src/components/form/FormSubTitle';
import Select from 'lib/src/components/form/Select';
import TextInput from 'lib/src/components/form/TextInput';
import Modal from 'lib/src/components/modal/Modal';
import {
    convertArrayToDropdownOption,
    convertEnumToDropdownOption,
} from 'lib/src/shared/enums/dropdownEnums';
import { toTitleCase } from 'lib/src/utils/generic';
import React from 'react';
import { Customer, SuppressedStatuses } from '@customTypes/shared/Customer';

import useUpdateCustomer from './hooks/useUpdateCustomer';
import AddressLookup, { Address } from 'lib/src/components/form/AddressLookup';

const UpdateCustomerModal: React.FC<UpdateCustomerProps> = ({ customer }) => {
    const {
        handleSubmit,
        handleChange,
        closeModal,
        isPosting,
        formState: {
            title,
            firstName,
            lastName,
            addressLine1,
            addressLine2,
            addressLine3,
            town,
            county,
            postCode,
            contactEmail,
            phone,
            dateOfBirth,
            gender,
            storeID,
            contactPreferences,
            suppressedStatus,
            createUser,
            password,
            confirmPassword,
        },
    } = useUpdateCustomer(customer);

    const { stores } = useStoresFetch();

    const isRegistered = customer.isRegistered;

    const handleSelect = (address: Address) => {
        const keys: Array<keyof Address> = [
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'postCode',
            'town',
            'county',
        ];

        for (const key of keys) handleChange(key, address[key]);
    };

    return (
        <Modal title="Update Customer" size="small" closeModal={closeModal}>
            <Form onCancel={closeModal} onSubmit={handleSubmit} isPosting={isPosting}>
                <FormSubTitle>Personal Details</FormSubTitle>
                <FormRow>
                    <Select
                        name="title"
                        value={title}
                        options={convertArrayToDropdownOption([
                            'Mr',
                            'Mrs',
                            'Miss',
                            'Ms',
                            'Mr & Mrs',
                            'Dr',
                        ])}
                        onChange={handleChange}
                        label="Title"
                        required={customer.title != null}
                    />
                    <TextInput
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        label="Forename"
                        required
                    />
                    <TextInput
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        label="Surname"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="gender"
                        value={gender}
                        options={[
                            { value: 'M', label: 'Male' },
                            { value: 'F', label: 'Female' },
                            { value: 'X', label: 'Prefer not to say' },
                        ]}
                        onChange={handleChange}
                        label="Gender"
                    />
                    <DatePicker
                        name="dateOfBirth"
                        value={dateOfBirth}
                        onChange={handleChange}
                        label="Date of Birth"
                        maxDate={new Date()}
                    />
                </FormRow>
                <FormSubTitle>Address</FormSubTitle>
                <FormRow>
                    <AddressLookup label="Address Lookup" onSelect={handleSelect} isAdmin />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="addressLine1"
                        value={addressLine1}
                        onChange={handleChange}
                        label="Address Line 1"
                        required
                    />
                    <TextInput
                        name="addressLine2"
                        value={addressLine2 ?? ''}
                        onChange={handleChange}
                        label="Address Line 2"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="addressLine3"
                        value={addressLine3 ?? ''}
                        onChange={handleChange}
                        label="Address Line 3"
                    />
                    <TextInput
                        name="postCode"
                        value={postCode}
                        onChange={handleChange}
                        label="Post Code"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="town"
                        value={town ?? ''}
                        onChange={handleChange}
                        label="Town"
                    />
                    <TextInput
                        name="county"
                        value={county ?? ''}
                        onChange={handleChange}
                        label="County"
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="phone"
                        value={phone ?? ''}
                        onChange={handleChange}
                        label="Phone"
                    />
                    <TextInput
                        type="email"
                        name="contactEmail"
                        value={contactEmail ?? ''}
                        onChange={handleChange}
                        label="Contact Email"
                    />
                </FormRow>
                <FormSubTitle>Contact Preferences</FormSubTitle>
                <FormRow>
                    {Object.entries(contactPreferences).map(([key, value]) => {
                        let disabled = false;
                        if (key === 'email' && !contactEmail) disabled = true;
                        if ((key === 'phone' || key === 'sms') && !phone) disabled = true;

                        return (
                            <Checkbox
                                key={key}
                                name={`${key}ContactPref`}
                                value={disabled ? false : value}
                                onChange={(_, value) => {
                                    handleChange('contactPreferences', {
                                        ...contactPreferences,
                                        [key]: value,
                                    });
                                }}
                                disabled={disabled}
                                label={toTitleCase(key)}
                            />
                        );
                    })}
                </FormRow>
                <FormRow>
                    <Select
                        name="storeID"
                        value={storeID}
                        options={Object.values(stores).map(store => ({
                            value: store.id,
                            label: store.name,
                        }))}
                        onChange={handleChange}
                        label="Store"
                        required
                    />
                </FormRow>
                <FormRow>
                    <Select
                        name="suppressedStatus"
                        value={suppressedStatus}
                        options={convertEnumToDropdownOption(SuppressedStatuses)}
                        onChange={handleChange}
                        label="Suppressed Status"
                        required
                    />
                </FormRow>
                <FormSubTitle>
                    {isRegistered ? 'Update Password' : 'Create User Account'}
                </FormSubTitle>
                <FormRow>
                    {!isRegistered && (
                        <Checkbox
                            name="createUser"
                            value={createUser}
                            onChange={handleChange}
                            label="Create User"
                        />
                    )}
                    <TextInput
                        type="password"
                        name="password"
                        value={createUser || isRegistered ? password : ''}
                        onChange={handleChange}
                        label="Password"
                        required={createUser}
                        disabled={!createUser && !isRegistered}
                    />
                    <TextInput
                        type="password"
                        name="confirmPassword"
                        value={createUser || isRegistered ? confirmPassword : ''}
                        onChange={handleChange}
                        label="Confirm Password"
                        required={createUser}
                        disabled={!createUser && !isRegistered}
                        customValidate={value =>
                            (createUser || isRegistered) && value !== password
                                ? 'Passwords do not match'
                                : null
                        }
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateCustomerProps {
    customer: Customer;
}

export default UpdateCustomerModal;
