import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import { getVoucherIsPosting, getVoucherPostSuccess, getVouchersError } from '@selectors/vouchers';
import { useHistory } from 'react-router-dom';
import { Voucher, VoucherStatuses } from 'src/types/shared/Voucher';
import dayjs from 'dayjs';
import { updateVoucher } from '@actions/vouchers';
import { removeFieldError } from 'lib/src/redux/actions/fieldErrors';

export default function useUpdateVoucher({
    id,
    title,
    internalName,
    validFrom,
    validTo,
    usesPerIndividual,
    isUnlimited,
    shortDescription,
    description1,
    terms,
    status,
    thumbnailS3Key,
    mainImageS3Key,
    stores,
    isHighlighted,
    customBarcode,
}: Voucher): UpdateVoucherReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/vouchers/${id}`);
    }, [history, id]);

    const [formState, handleChange] = useForm({
        id,
        title,
        internalName,
        validFromDate: new Date(validFrom),
        validFromTime: dayjs(validFrom).format('HH:mm'),
        validToDate: new Date(validTo),
        validToTime: dayjs(validTo).format('HH:mm'),
        usesPerIndividual,
        isUnlimited,
        shortDescription,
        description1: description1 ?? '',
        terms: terms ?? '',
        status,
        thumbnailS3Key,
        mainImageS3Key,
        storeIds: stores.map(store => store.id),
        isHighlighted,
        customBarcode,
    });

    const handleSubmit = useCallback(() => {
        const {
            validFromDate,
            validFromTime,
            validToDate,
            validToTime,
            isUnlimited,
            usesPerIndividual,
        } = formState;
        if (!validFromDate || !validFromTime) return;
        dispatch(
            updateVoucher(id, {
                ...formState,
                usesPerIndividual: isUnlimited ? null : usesPerIndividual,
                validFrom: dayjs(validFromDate).format(`YYYY-MM-DDT${validFromTime}`),
                validTo: dayjs(validToDate).format(`YYYY-MM-DDT${validToTime}`),
            }),
        );
    }, [dispatch, id, formState]);

    const isPosting = useSelector(getVoucherIsPosting);
    const error = useSelector(getVouchersError);
    const postSuccess = useSelector(getVoucherPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    useEffect(() => {
        dispatch(removeFieldError('validFrom'));
        dispatch(removeFieldError('validTo'));
    }, [dispatch, formState]);

    return {
        handleSubmit,
        formState,
        handleChange,
        closeModal,
        isPosting,
        error,
    };
}

interface FormState {
    title: string;
    internalName: string;
    validFromDate: Date;
    validFromTime: string;
    validToDate: Date;
    validToTime: string;
    usesPerIndividual: number;
    isUnlimited: boolean;
    shortDescription: string;
    description1: string;
    terms: string;
    status: VoucherStatuses;
    thumbnailS3Key: string | null;
    mainImageS3Key: string | null;
    storeIds: number[];
    isHighlighted: boolean;
    customBarcode: string | null;
}

interface UpdateVoucherReturn {
    handleSubmit: () => void;
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    closeModal: () => void;
    isPosting: boolean;
    error: string | null;
}
