import { CustomerFilters } from '@reducers/customers';
import { RootState } from '@reducers/index';
import { Customer } from 'src/types/shared/Customer';

export const getCustomers = (state: RootState): Record<number, Customer> =>
    state.customersReducer.customers;
export const getCustomersCount = (state: RootState): number | null => state.customersReducer.count;
export const getCustomer = (state: RootState, id: number): Customer =>
    state.customersReducer.customers[id];
export const getCustomersIsFetching = (state: RootState): boolean =>
    state.customersReducer.isFetching;
export const getCustomersError = (state: RootState): string | null => state.customersReducer.error;
export const getCustomerFilters = (state: RootState): CustomerFilters =>
    state.customersReducer.filters;

export const getCustomerIsPosting = (state: RootState): boolean => state.customersReducer.isPosting;
export const getCustomerPostSuccess = (state: RootState): boolean =>
    state.customersReducer.postSuccess;

export const getCustomersCountIsFetching = (state: RootState): boolean =>
    state.customersReducer.isFetchingCount;
export const getCustomersCountError = (state: RootState): string | null =>
    state.customersReducer.countError;
