import React from 'react';

import Title from 'lib/src/components/typography/Title';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Store as StoreTypes } from '@customTypes/shared/Store';
import { toTitleCase } from 'lib/src/utils/generic';
import { days } from 'lib/src/constants/generic';
import { Day } from '@customTypes/shared/generic';
import config from '@config/index';
import { buildAddress } from '@utils/generic';

const { S3_URL } = config;

const Store: React.FC<StoreProps> = ({ store }) => {
    if (!store) return null;

    const { id, name, shortName, website, businessHours, imageS3Key, homeScreenImageS3Key } = store;
    return (
        <>
            <Title backHref="/stores">Store - {name}</Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{name}</p>
                    </ContentItem>
                    <ContentItem label="Short Name">
                        <p>{shortName}</p>
                    </ContentItem>

                    <ContentItem label="Full Address">
                        <p>{buildAddress(store)}</p>
                    </ContentItem>
                    <ContentItem label="Website">
                        <p>
                            <a href={website} target="_blank" rel="noreferrer">
                                {website}
                            </a>
                        </p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>
            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Business Hours" />
                </ContentRow>
                <ContentRow>
                    {Object.entries(businessHours).length === 0 ? (
                        <p>No business hours set</p>
                    ) : (
                        days.map(day => {
                            const hours = businessHours[day.toLowerCase() as Day];
                            return (
                                <ContentItem label={toTitleCase(day)} key={day}>
                                    <p>
                                        {hours.isClosed
                                            ? 'Closed'
                                            : `${hours.openTime} - ${hours.closeTime}`}
                                    </p>
                                </ContentItem>
                            );
                        })
                    )}
                </ContentRow>
            </ContentBlock>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Store Image">
                        <div>
                            <img src={`${S3_URL}/${imageS3Key}`} alt="Store" />
                        </div>
                    </ContentItem>
                    <ContentItem label="Home Image">
                        <div>
                            <img src={`${S3_URL}/${homeScreenImageS3Key}`} alt="Store" />
                        </div>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <LinkButton color="grey" href={`/stores/${id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="red" href={`/stores/${id}/delete`}>
                    Delete
                </LinkButton>
                <LinkButton color="blue" href={`/stores/${id}/exceptions`}>
                    Exceptions
                </LinkButton>
            </ButtonRow>
        </>
    );
};

interface StoreProps {
    store: StoreTypes | undefined;
}
export default Store;
