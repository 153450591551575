import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { BusinessHoursExceptionPatch, Store } from 'src/types/shared/Store';

export const updateStoreExceptionRequest = createAction('updateStoreExceptionRequest');
export const updateStoreExceptionSuccess = createAction<Store>('updateStoreExceptionSuccess');
export const updateStoreExceptionFailure = createAction('updateStoreExceptionFailure');

export const updateStoreException = (
    id: number,
    storeID: number,
    postbody: BusinessHoursExceptionPatch,
) => async (dispatch: Dispatch): Promise<void> => {
    dispatch(updateStoreExceptionRequest());
    try {
        const { data }: { data: Store } = await api.patch(
            `stores/${storeID}/exceptions/${id}`,
            postbody,
        );

        dispatch(updateStoreExceptionSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateStoreExceptionFailure, e);
    }
};
