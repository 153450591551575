import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    deletePushNotificationFailure,
    deletePushNotificationRequest,
    deletePushNotificationSuccess,
    fetchAllPushNotificationsFailure,
    fetchAllPushNotificationsRequest,
    fetchAllPushNotificationsSuccess,
    fetchSinglePushNotificationFailure,
    fetchSinglePushNotificationRequest,
    fetchSinglePushNotificationSuccess,
    createPushNotificationFailure,
    createPushNotificationRequest,
    createPushNotificationSuccess,
    updatePushNotificationFailure,
    updatePushNotificationRequest,
    updatePushNotificationSuccess,
} from '@actions/pushNotifications';
import { PushNotification } from 'src/types/shared/PushNotification';

interface PushNotificationsState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    pushNotifications: { [key: number]: PushNotification };
}

const initialState: PushNotificationsState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    pushNotifications: {},
};

export default createReducer(initialState, {
    [fetchAllPushNotificationsRequest.type]: handleFetchRequest,
    [fetchAllPushNotificationsSuccess.type]: handleFetchPushNotificationsSuccess,
    [fetchAllPushNotificationsFailure.type]: handleFetchError,
    [fetchSinglePushNotificationRequest.type]: handleFetchRequest,
    [fetchSinglePushNotificationSuccess.type]: handleFetchSinglePushNotificationSuccess,
    [fetchSinglePushNotificationFailure.type]: handleFetchError,
    [createPushNotificationRequest.type]: handlePostRequest,
    [createPushNotificationSuccess.type]: handleCreatePushNotificationSuccess,
    [createPushNotificationFailure.type]: handlePostError,
    [updatePushNotificationRequest.type]: handlePostRequest,
    [updatePushNotificationSuccess.type]: handleUpdatePushNotificationSuccess,
    [updatePushNotificationFailure.type]: handlePostError,
    [deletePushNotificationRequest.type]: handleDeleteRequest,
    [deletePushNotificationFailure.type]: handleDeleteError,
    [deletePushNotificationSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: PushNotificationsState) {
    state.isFetching = true;
}

function handlePostRequest(state: PushNotificationsState) {
    state.isPosting = true;
    state.postSuccess = false;
}
function handleDeleteRequest(state: PushNotificationsState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handleFetchError(state: PushNotificationsState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
function handlePostError(state: PushNotificationsState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}
function handleDeleteError(state: PushNotificationsState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}

function handleFetchPushNotificationsSuccess(
    state: PushNotificationsState,
    action: PayloadAction<PushNotification[]>,
) {
    state.pushNotifications = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handleFetchSinglePushNotificationSuccess(
    state: PushNotificationsState,
    action: PayloadAction<PushNotification>,
) {
    state.pushNotifications[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleCreatePushNotificationSuccess(
    state: PushNotificationsState,
    action: PayloadAction<PushNotification>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.pushNotifications[action.payload.id] = action.payload;
}
function handleUpdatePushNotificationSuccess(
    state: PushNotificationsState,
    action: PayloadAction<PushNotification>,
) {
    state.isPosting = false;
    state.postSuccess = true;
    state.pushNotifications[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: PushNotificationsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.pushNotifications[action.payload];
}
