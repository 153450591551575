import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import {
    importCustomerCSVRequest,
    importCustomerCSVSuccess,
    importCustomerCSVFailure,
    exportCustomerCSVRequest,
    exportCustomerCSVSuccess,
    exportCustomerCSVFailure,
} from '@actions/importExport';

interface ImportExportState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    fetchSuccess: boolean;
    error: string | null;
}

const initialState: ImportExportState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    fetchSuccess: false,
    error: null,
};

export default createReducer(initialState, {
    [importCustomerCSVRequest.type]: handlePostRequest,
    [importCustomerCSVSuccess.type]: handlePostSuccess,
    [importCustomerCSVFailure.type]: handlePostError,
    [exportCustomerCSVRequest.type]: handleFetchRequest,
    [exportCustomerCSVSuccess.type]: handleFetchSuccess,
    [exportCustomerCSVFailure.type]: handleFetchError,
});

function handleFetchRequest(state: ImportExportState) {
    state.isFetching = true;
    state.fetchSuccess = false;
    state.error = null;
}

function handlePostRequest(state: ImportExportState) {
    state.isPosting = true;
    state.error = null;
    state.postSuccess = false;
}

function handlePostSuccess(state: ImportExportState) {
    state.isPosting = false;
    state.postSuccess = true;
}

function handleFetchSuccess(state: ImportExportState) {
    state.isFetching = false;
    state.fetchSuccess = true;
}

function handleFetchError(state: ImportExportState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}

function handlePostError(state: ImportExportState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}
