import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AdminUserContainer from '@pages/adminUsers/adminUser/AdminUserContainer';
import AdminUsers from '@pages/adminUsers/adminUsers/AdminUsers';

const AdminUsersRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <AdminUsers />
            </Route>
            <Route exact path={`${path}/create`}>
                <AdminUsers showCreateModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <AdminUserContainer />
            </Route>
            <Route exact path={`${path}/:id/:action`}>
                <AdminUserContainer />
            </Route>
        </Switch>
    );
};

export default AdminUsersRoutes;
