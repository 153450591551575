import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import useForm from 'lib/src/hooks/useForm';

import {
    getPushNotificationIsPosting,
    getPushNotificationPostSuccess,
    getPushNotificationsError,
} from '@selectors/pushNotifications';
import { useHistory } from 'react-router-dom';
import { PushNotification, PushNotificationTimeframes } from 'src/types/shared/PushNotification';
import dayjs from 'dayjs';
import { updatePushNotification } from '@actions/pushNotifications';
import { removeFieldError } from 'lib/src/redux/actions/fieldErrors';

export default function useUpdatePushNotification({
    id,
    voucherId,
    title,
    message,
    sendOn: _sendOn,
    isImmediate,
    timeFrame,
    timeFrameOffset,
    stores,
}: PushNotification): UpdatePushNotificationReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const closeModal = useCallback(() => {
        history.replace(`/push-notifications/${id}`);
    }, [history, id]);

    timeFrameOffset = timeFrameOffset ?? 0;
    const timeFrameDays = Math.floor(timeFrameOffset / (24 * 60));
    timeFrameOffset = timeFrameOffset - timeFrameDays * (24 * 60);
    const timeFrameHours = Math.floor(timeFrameOffset / 60);
    timeFrameOffset = timeFrameOffset - timeFrameHours * 60;
    const timeFrameMinutes = timeFrameOffset;

    const [formState, handleChange] = useForm({
        id,
        voucherId,
        title,
        message,
        sendOnDate: dayjs(_sendOn).toDate(),
        sendOnTime: dayjs(_sendOn).format('HH:mm'),
        isImmediate,
        timeFrame: timeFrame || null,
        timeFrameDays,
        timeFrameHours,
        timeFrameMinutes,
        stores,
    });

    const handleSubmit = useCallback(() => {
        const {
            sendOnDate,
            sendOnTime,
            timeFrame,
            timeFrameDays,
            timeFrameHours,
            timeFrameMinutes,
        } = formState;

        const sendOnWithTime = dayjs(sendOnDate).utc().format(`YYYY-MM-DDT${sendOnTime}`);
        const sendOn = dayjs(sendOnWithTime).utc().format();

        dispatch(
            updatePushNotification(id, {
                ...formState,
                ...(timeFrame == null
                    ? {
                          sendOn,
                      }
                    : {
                          timeFrame,
                          timeFrameOffset:
                              timeFrameMinutes + timeFrameHours * 60 + timeFrameDays * 24 * 60,
                      }),
            }),
        );
    }, [dispatch, id, formState]);

    const isPosting = useSelector(getPushNotificationIsPosting);
    const error = useSelector(getPushNotificationsError);
    const postSuccess = useSelector(getPushNotificationPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    useEffect(() => {
        if (formState.voucherId == null) handleChange('timeFrame', null);
        // eslint-disable-next-line
    }, [formState.voucherId]);

    useEffect(() => {
        dispatch(removeFieldError('timeFrameOffset'));
        dispatch(removeFieldError('sendOn'));
    }, [dispatch, formState]);

    return {
        handleSubmit,
        formState,
        handleChange,
        closeModal,
        isPosting,
        error,
    };
}

interface FormState {
    voucherId: number | null;
    title: string;
    message: string;
    sendOnDate: Date;
    sendOnTime: string;
    isImmediate: boolean;
    timeFrame: PushNotificationTimeframes | null;
    timeFrameDays: number;
    timeFrameHours: number;
    timeFrameMinutes: number;
    stores?: number[];
}

interface UpdatePushNotificationReturn {
    handleSubmit: () => void;
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    closeModal: () => void;
    isPosting: boolean;
    error: string | null;
}
