import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../../redux/store';

import { getAdminUser } from '@selectors/adminUsers';

import AdminUser from './AdminUser';

import UpdateAdminPasswordModal from './UpdateAdminPasswordModal';
import DeleteAdminModal from './DeleteAdminModal';
import UpdateAdminModal from './UpdateAdminModal';
import useAdminUsersFetch from '../adminUsers/hooks/useAdminUsersFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';

const AdminUserContainer: React.FC = () => {
    const { id, action } = useParams<{ id: string; action?: string }>();

    const user = useAppSelector(state => getAdminUser(state, parseInt(id)));

    const { isFetching, fetchError } = useAdminUsersFetch(+id);

    return (
        <DataCheck isLoading={isFetching} error={fetchError} isEmpty={isEmpty(user)}>
            <AdminUser user={user} />
            {action === 'delete' && <DeleteAdminModal user={user} />}
            {!!user && action === 'edit-password' && <UpdateAdminPasswordModal user={user} />}
            {!!user && action === 'edit' && <UpdateAdminModal user={user} />}
        </DataCheck>
    );
};

export default AdminUserContainer;
