import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStore } from '@actions/stores/postCreateStore';
import { getStoreIsPosting, getStorePostSuccess, getStoresError } from '@selectors/stores';
import { CloseModal, HandleSubmit } from 'src/types/shared/Functions';
import { BusinessHours, BusinessHoursDay } from '@customTypes/shared/Store';
import dayjs from 'dayjs';

const businessHoursDay: BusinessHoursDay = {
    isClosed: true,
    openTime: dayjs().format('HH:mm'),
    closeTime: dayjs().format('HH:mm'),
};

const initialState: FormState = {
    businessHours: {
        monday: businessHoursDay,
        tuesday: businessHoursDay,
        wednesday: businessHoursDay,
        thursday: businessHoursDay,
        friday: businessHoursDay,
        saturday: businessHoursDay,
        sunday: businessHoursDay,
    },
    shortName: '',
    name: '',
    website: '',
    imageS3Key: '',
    homeScreenImageS3Key: '',
    googleMapsUrl: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    county: '',
    postCode: '',
};

export default function useCreateStore(closeModal: CloseModal): UseCreateStoreReturn {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formState, handleChange] = useForm(initialState);

    const handleSubmit = useCallback(() => {
        dispatch(
            createStore({
                ...formState,
            }),
        );
        history.push('/stores');
    }, [dispatch, history, formState]);

    const isPosting = useSelector(getStoreIsPosting);
    const error = useSelector(getStoresError);
    const postSuccess = useSelector(getStorePostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
}

interface FormState {
    businessHours: BusinessHours;
    shortName: string;
    name: string;
    website: string;
    imageS3Key: string;
    homeScreenImageS3Key: string;
    googleMapsUrl: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
}

interface UseCreateStoreReturn {
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
    error: string | null;
}
