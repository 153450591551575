import React from 'react';

const TableSearch: React.FC<TableSearchProps> = ({ onChange, onSubmit = () => null, value }) => {
    return (
        <form className="table-search" onSubmit={handleSubmit}>
            <i className="icon fal fa-search"></i>
            <input
                type="text"
                onChange={handleChange}
                onBlur={handleSubmit}
                value={value}
                placeholder="Search..."
            />
        </form>
    );

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        onChange(e.target.value);
    }

    function handleSubmit(
        e: React.FormEvent<HTMLFormElement> | React.FocusEvent<HTMLInputElement>,
    ) {
        e.preventDefault();
        onSubmit();
    }
};

interface TableSearchProps {
    onChange: (value: string) => void;
    onSubmit?: () => void;
    value: string;
}

export default TableSearch;
