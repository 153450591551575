import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    deleteVoucherFailure,
    deleteVoucherRequest,
    deleteVoucherSuccess,
    fetchAllVouchersFailure,
    fetchAllVouchersRequest,
    fetchAllVouchersSuccess,
    fetchSingleVoucherFailure,
    fetchSingleVoucherRequest,
    fetchSingleVoucherSuccess,
    createVoucherFailure,
    createVoucherRequest,
    createVoucherSuccess,
    updateVoucherFailure,
    updateVoucherRequest,
    updateVoucherSuccess,
} from '@actions/vouchers';
import { Voucher } from 'src/types/shared/Voucher';

interface VouchersState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    vouchers: { [key: number]: Voucher };
}

const initialState: VouchersState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    vouchers: {},
};

export default createReducer(initialState, {
    [fetchAllVouchersRequest.type]: handleFetchRequest,
    [fetchAllVouchersSuccess.type]: handleFetchVouchersSuccess,
    [fetchAllVouchersFailure.type]: handleFetchError,
    [fetchSingleVoucherRequest.type]: handleFetchRequest,
    [fetchSingleVoucherSuccess.type]: handleFetchSingleVoucherSuccess,
    [fetchSingleVoucherFailure.type]: handleFetchError,
    [createVoucherRequest.type]: handlePostRequest,
    [createVoucherSuccess.type]: handleCreateVoucherSuccess,
    [createVoucherFailure.type]: handlePostError,
    [updateVoucherRequest.type]: handlePostRequest,
    [updateVoucherSuccess.type]: handleUpdateVoucherSuccess,
    [updateVoucherFailure.type]: handlePostError,
    [deleteVoucherRequest.type]: handleDeleteRequest,
    [deleteVoucherFailure.type]: handleDeleteError,
    [deleteVoucherSuccess.type]: handleDeleteSuccess,
});

function handleFetchRequest(state: VouchersState) {
    state.isFetching = true;
}

function handlePostRequest(state: VouchersState) {
    state.isPosting = true;
    state.postSuccess = false;
}
function handleDeleteRequest(state: VouchersState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handleFetchError(state: VouchersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
function handlePostError(state: VouchersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}
function handleDeleteError(state: VouchersState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}

function handleFetchVouchersSuccess(state: VouchersState, action: PayloadAction<Voucher[]>) {
    state.vouchers = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handleFetchSingleVoucherSuccess(state: VouchersState, action: PayloadAction<Voucher>) {
    state.vouchers[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleCreateVoucherSuccess(state: VouchersState, action: PayloadAction<Voucher>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.vouchers[action.payload.id] = action.payload;
}
function handleUpdateVoucherSuccess(state: VouchersState, action: PayloadAction<Voucher>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.vouchers[action.payload.id] = action.payload;
}

function handleDeleteSuccess(state: VouchersState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.vouchers[action.payload];
}
