import dayjs from 'dayjs';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import Table from 'lib/src/components/table/Table';
import { adminUserRoleNames } from 'lib/src/shared/enums/roleEnums';
import { Column } from 'lib/src/types/table';
import { AdminUser } from 'src/types/shared/AdminUser';

const AdminUsersTable: React.FC<AdminUsersTableProps> = ({ adminUsers, isFetching }) => {
    return <Table columns={columns} rows={Object.values(adminUsers)} isLoading={isFetching} />;
};

const columns: Column<AdminUser>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: row => `${row.firstName || ''} ${row.lastName || ''}`,
        getSort: (a, b) => a.firstName.localeCompare(b.firstName),
        searchable: true,
    },
    {
        key: 2,
        heading: 'Created',
        getValue: row => dayjs(row.createdOn).format('DD-MM-YYYY'),
        getSort: (a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
        searchable: true,
    },
    {
        key: 3,
        heading: 'Email',
        getValue: row => row.email,
        getSort: (a, b) => a.email.localeCompare(b.email),
    },
    {
        key: 4,
        heading: 'Roles',
        getValue: row => {
            if (!row.roles) return 'No roles';
            return row.roles.map(roleEnum => adminUserRoleNames[roleEnum] || '').join(', ');
        },
    },
    {
        key: 5,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/admins/${row.id}`}>View</LinkButton>
                <LinkButton color="grey" href={`/admins/${row.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="red" href={`/admins/${row.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        ),
    },
];

interface AdminUsersTableProps {
    adminUsers: Record<number, AdminUser>;
    isFetching: boolean;
}

export default AdminUsersTable;
