import { SuppressedStatuses } from '@customTypes/shared/Customer';
import { CustomerFilters } from '@reducers/customers';
import ActionButton from 'lib/src/components/button/ActionButton';
import Form from 'lib/src/components/form/Form';
import Select from 'lib/src/components/form/Select';
import TextInput from 'lib/src/components/form/TextInput';
import { convertEnumToDropdownOption } from 'lib/src/shared/enums/dropdownEnums';
import React, { ReactElement, useEffect } from 'react';

interface Props {
    filters: CustomerFilters;
    onChange: (name: keyof CustomerFilters, value: any) => void;
    onSubmit: () => void;
}

function CustomersTableSearch({ filters, onChange, onSubmit }: Props): ReactElement {
    const { status, id, firstName, lastName, contactEmail, addressLine1, postCode } = filters;

    useEffect(() => {
        onSubmit();
        // eslint-disable-next-line
    }, [status]);

    return (
        <Form onSubmit={onSubmit} omitButtons className="header-search" showFormError={false}>
            <Select
                name="status"
                label="Suppressed Status"
                options={[
                    { value: null, label: 'All' },
                    ...convertEnumToDropdownOption(SuppressedStatuses),
                ]}
                value={status}
                onChange={onChange}
                disableCross
            />
            <TextInput
                name="id"
                label="URN"
                value={id ?? ''}
                onChange={(name, value) => {
                    const _value = parseInt(value.toString());
                    return onChange(name, isNaN(_value) ? null : _value);
                }}
            />
            <TextInput name="firstName" label="Forename" value={firstName} onChange={onChange} />
            <TextInput name="lastName" label="Surname" value={lastName} onChange={onChange} />
            <TextInput name="contactEmail" label="Email" value={contactEmail} onChange={onChange} />
            <TextInput
                name="addressLine1"
                label="Address Line 1"
                value={addressLine1}
                onChange={onChange}
            />
            <TextInput name="postCode" label="Postcode" value={postCode} onChange={onChange} />
            <ActionButton type="submit">Search</ActionButton>
        </Form>
    );
}

export default CustomersTableSearch;
