import { useState } from 'react';

import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';
import StoresTable from './StoresTable';
import CreateStoreModal from './CreateStoreModal';

const Stores: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    return (
        <>
            <CreateHeader>
                <Title>Stores</Title>
                <ActionButton color="green" icon="plus" onClick={() => setShowCreateModal(true)}>
                    Create
                </ActionButton>
            </CreateHeader>

            <StoresTable />
            {showCreateModal && <CreateStoreModal closeModal={() => setShowCreateModal(false)} />}
        </>
    );
};

export default Stores;
