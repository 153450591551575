import { hasRole } from '@utils/generic';
import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';
import React, { ReactElement } from 'react';
import { Redirect } from 'react-router';

const DefaultRedirect = (): ReactElement | null => {
    const redirects = [
        { to: '/stores', role: AdminUserRoles.Stores },
        { to: '/customers', role: AdminUserRoles.Customers },
        { to: '/vouchers', role: AdminUserRoles.Vouchers },
        { to: '/push-notifications', role: AdminUserRoles.PushNotifications },
        { to: '/admins', role: AdminUserRoles.Admins },
    ];

    for (const { to, role } of redirects) {
        if (hasRole(role)) return <Redirect to={to} />;
    }

    return null;
};

export default DefaultRedirect;
