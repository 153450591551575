import { fetchAllVouchers, fetchSingleVoucher } from '@actions/vouchers';
import { getVouchers, getVouchersError, getVouchersIsFetching } from '@selectors/vouchers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Voucher } from 'src/types/shared/Voucher';

const useVouchersFetch = (id?: number, dontFetch?: boolean): VouchersFetchReturn => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (dontFetch) return;
        if (id != null) dispatch(fetchSingleVoucher(id));
        else dispatch(fetchAllVouchers());
    }, [dispatch, id, dontFetch]);

    const vouchers = useSelector(getVouchers);
    const isFetching = useSelector(getVouchersIsFetching);
    const fetchError = useSelector(getVouchersError);

    return {
        vouchers,
        isFetching,
        fetchError,
    };
};

interface VouchersFetchReturn {
    vouchers: Record<number, Voucher>;
    isFetching: boolean;
    fetchError: string | null;
}

export default useVouchersFetch;
