import LinkButton from '../button/LinkButton';

// alignment options are: left, center, right
const Title: React.FC<TitleProps> = ({
    children,
    alignment = '',
    className = '',
    containerClassName = '',
    backHref = '',
    ...props
}) => {
    return (
        <div className={`page-title-wrapper ${containerClassName}`}>
            {backHref && (
                <LinkButton href={backHref} color="grey" className="back-button">
                    <i className="far fa-chevron-left" />
                    Back
                </LinkButton>
            )}
            <h1 className={`page-title ${alignment} ${className}`} {...props}>
                {children}
            </h1>
        </div>
    );
};

interface TitleProps {
    alignment?: string;
    className?: string;
    containerClassName?: string;
    backHref?: string;
}

export default Title;
