import { RootState } from '@reducers/index';

export const getImportExportIsFetching = (state: RootState): boolean =>
    state.importExportReducer.isFetching;
export const getImportExportFetchSuccess = (state: RootState): boolean =>
    state.importExportReducer.fetchSuccess;
export const getImportExportError = (state: RootState): string | null =>
    state.importExportReducer.error;
export const getImportExportIsPosting = (state: RootState): boolean =>
    state.importExportReducer.isPosting;
export const getImportExportPostSuccess = (state: RootState): boolean =>
    state.importExportReducer.postSuccess;
