import { PushNotification } from './PushNotification';
import { Store } from './Store';

export enum VoucherStatuses {
    Published = 1,
    Unpublished = 2,
}

export const VoucherStatusNames = {
    [VoucherStatuses.Published]: 'Published',
    [VoucherStatuses.Unpublished]: 'Unpublished',
};

export type VocuherStore = Pick<Store, 'id' | 'shortName' | 'name' | 'website'>;

export type VoucherPushNotification = Omit<PushNotification, 'voucherId'>;

export interface Voucher {
    id: number;
    createdByAdminUserId: number;
    title: string;
    isUnlimited: boolean;
    internalName: string;
    shortDescription: string;
    description1: string | null;
    terms: string | null;
    status: VoucherStatuses;
    usesPerIndividual: number; // How many times it can be used by one person
    numberOfViews: number; // How many times it's been viewed on mobile
    numberSent: number; // How many times it's been sent to mobile
    numberRedeemed: number; // Believed to be deprecated (count in DB for all vouchers is 0)
    numberOfUses: number; // How many times it's been redeemed
    mainImageS3Key: string | null;
    thumbnailS3Key: string | null;
    validFrom: string;
    validTo: string;
    createdOn: string;
    updatedOn: string;
    stores: VocuherStore[];
    pushNotifications: VoucherPushNotification[];
    isHighlighted: boolean;
    customBarcode: string | null;
}

export interface VoucherPost {
    title: string;
    internalName: string;
    validFrom: string;
    validTo: string;
    usesPerIndividual: number | null;
    isUnlimited: boolean;
    shortDescription: string;
    description1: string | null;
    terms: string | null;
    status: VoucherStatuses;
    thumbnailS3Key: string | null;
    mainImageS3Key: string | null;
    storeIds: number[];
    isHighlighted: boolean;
}

export interface VoucherPatch {
    title: string;
    internalName: string;
    validFrom: string;
    validTo: string;
    usesPerIndividual: number | null;
    isUnlimited: boolean;
    shortDescription: string;
    description1: string | null;
    terms: string | null;
    status: VoucherStatuses;
    thumbnailS3Key: string | null;
    mainImageS3Key: string | null;
    storeIds: number[];
    isHighlighted: boolean;
}
