import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { Voucher } from 'src/types/shared/Voucher';
import useDeleteVoucher from './hooks/useDeleteVoucher';

const DeleteVoucherModal: React.FC<DeleteVoucherProps> = ({ voucher }) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeleteVoucher(voucher.id);

    return (
        <ConfirmModal
            title="Delete Voucher"
            description={`Are you sure you want to delete '${voucher.title}'?`}
            closeModal={() => history.replace(`/vouchers/${voucher.id}`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
            isDelete
        />
    );
};

interface DeleteVoucherProps {
    voucher: Voucher;
}
export default DeleteVoucherModal;
