import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deletePushNotificationRequest = createAction('deletePushNotificationRequest');
export const deletePushNotificationSuccess = createAction('deletePushNotificationSuccess');
export const deletePushNotificationFailure = createAction('deletePushNotificationFailure');

export const deletePushNotification = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(deletePushNotificationRequest());

    try {
        await api.delete(`pushNotifications/${id}`);

        dispatch(deletePushNotificationSuccess());
    } catch (e) {
        handleApiErrors(dispatch, deletePushNotificationFailure, e);
    }
};
