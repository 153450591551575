import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Customer, CustomerPatch } from 'src/types/shared/Customer';

export const updateCustomerRequest = createAction('updateCustomerRequest');
export const updateCustomerSuccess = createAction<Customer>('updateCustomerSuccess');
export const updateCustomerFailure = createAction('updateCustomerFailure');

export const updateCustomer = (id: number, postbody: CustomerPatch) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateCustomerRequest());

    try {
        const { data }: UpdateCustomerResponse = await api.patch(`customers/${id}`, postbody);

        dispatch(updateCustomerSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateCustomerFailure, e);
    }
};

interface UpdateCustomerResponse {
    data: Customer;
}
