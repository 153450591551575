import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { Store } from 'src/types/shared/Store';
import useDeleteStore from './hooks/useDeleteStore';

const DeleteStoreModal: React.FC<DeleteStoreProps> = ({ store }) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeleteStore(store.id);

    return (
        <ConfirmModal
            title="Delete Store"
            description={
                <>
                    Are you sure you want to delete {store.name} ({store.shortName})?
                    <p className="text-error">Warning: This is a permanent action</p>
                </>
            }
            closeModal={() => history.replace(`/stores/${store.id}`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
            isDelete
        />
    );
};

interface DeleteStoreProps {
    store: Store;
}
export default DeleteStoreModal;
