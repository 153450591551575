import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Store, StorePatch } from 'src/types/shared/Store';

export const updateStoreRequest = createAction('updateStoreRequest');
export const updateStoreSuccess = createAction<Store>('updateStoreSuccess');
export const updateStoreFailure = createAction('updateStoreFailure');

export const updateStore = (id: number, postbody: StorePatch) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateStoreRequest());

    try {
        const { data }: UpdateStoreResponse = await api.patch(`stores/${id}`, postbody);

        dispatch(updateStoreSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateStoreFailure, e);
    }
};

interface UpdateStoreResponse {
    data: Store;
}
