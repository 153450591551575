import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import config from '../../../../src/config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
    exportCustomerCSVFailure,
    exportCustomerCSVRequest,
    exportCustomerCSVSuccess,
} from '@actions/importExport';
import { getImportExportError, getImportExportIsFetching } from '@selectors/importExport';
import usePrevious from 'lib/src/hooks/usePrevious';
import { handleApiErrors } from 'lib/src/utils/api';
import { DropdownOption } from 'lib/src/types/shared/DropdownOption';

const useDownloadFile = (): Hook => {
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [downloadType, setDownloadType] = useState<string | number | null>(1);

    const dispatch = useDispatch();

    const { API_URL } = config;

    const isFetching = useSelector(getImportExportIsFetching);
    const fetchError = useSelector(getImportExportError);
    const prevProps = usePrevious({ fetchError });

    const typeOptions: DropdownOption<number>[] = [
        { label: 'All unsurpressed customers', value: 1 },
        { label: '5-years and active customers', value: 2 },
        { label: 'Mailing List', value: 3 },
        { label: 'Customers with email addresses', value: 4 },
    ];

    const handleChangeType = (_: string, value: string | number | null) => {
        if (typeof value === 'string') return;
        setDownloadType(value);
    };

    useEffect(() => {
        if (fetchError && !prevProps.fetchError) setShowErrorModal(true);
    }, [isFetching, fetchError, prevProps.fetchError]);

    const downloadFile = async () => {
        // const filename = `Boundary Outlet Customer Export - ${dayjs().format('DD-MM-YYYY')}.csv`;

        dispatch(exportCustomerCSVRequest());

        axios({
            url: `${API_URL}/customers/export/${downloadType ?? 1}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        })
            .then(response => {
                dispatch(exportCustomerCSVSuccess());

                // const url = window.URL.createObjectURL(
                //     new Blob([response.data], {
                //         type: 'text/csv',
                //     }),
                // );

                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', filename);
                // document.body.appendChild(link);
                // link.click();
            })
            .catch(e => {
                handleApiErrors(dispatch, exportCustomerCSVFailure, e);
            });
    };

    return {
        downloadFile,
        showErrorModal,
        setShowErrorModal,
        downloadType,
        handleChangeType,
        typeOptions,
        isFetching,
        fetchError,
    };
};

interface Hook {
    downloadFile: () => void;
    showErrorModal: boolean;
    setShowErrorModal: Dispatch<SetStateAction<boolean>>;
    downloadType: string | number | null;
    typeOptions: DropdownOption<number>[];
    handleChangeType: (name: string, value: string | number | null) => void;
    isFetching: boolean;
    fetchError: string | null;
}

export default useDownloadFile;
