import ButtonRow from 'lib/src/components/button/ButtonRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Customer } from '@customTypes/shared/Customer';
import Checkbox from 'lib/src/components/form/Checkbox';
import APITable from 'lib/src/components/table/APITable';
import useAPITableStates from 'lib/src/components/table/useAPITableStates';
import useCustomersFetch from './hooks/useCustomersFetch';
import CustomersTableSearch from './CustomersTableSearch';
import { Column } from 'lib/src/types/table';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCustomerFilters } from '@selectors/customers';
import { hasRole } from '@utils/generic';

const CustomersTable: React.FC = () => {
    const { page: initialPage, limit: initialLimit } = useSelector(getCustomerFilters);

    const { page, onPageChange, limit, onLimitChange } = useAPITableStates({
        page: initialPage,
        limit: initialLimit,
    });

    const {
        customers,
        count,
        isFetching,
        filters,
        setFilter,
        handleSubmit,
        isFetchingCount,
    } = useCustomersFetch(limit, page);

    useEffect(() => {
        setFilter('page', page);
        setFilter('limit', limit);
    }, [page, limit, setFilter]);

    return (
        <div className="customers-table">
            <APITable
                columns={columns}
                rows={customers}
                rowCount={count ?? 0}
                isLoading={isFetching}
                isFetchingCount={isFetchingCount}
                page={page}
                onPageChange={onPageChange}
                limit={limit}
                onLimitChange={onLimitChange}
                header={
                    <CustomersTableSearch
                        filters={filters}
                        onChange={setFilter}
                        onSubmit={() => {
                            handleSubmit();
                            onPageChange(1);
                        }}
                    />
                }
            />
        </div>
    );
};

const columns: Column<Customer>[] = [
    {
        key: 'id',
        heading: 'URN',
        getValue: (row: Customer) => row.id.toString(),
    },
    {
        key: 'title',
        heading: 'Title',
        getValue: (row: Customer) => row.title ?? '',
    },
    {
        key: 'firstName',
        heading: 'Forename',
        getValue: (row: Customer) => row.firstName ?? '',
    },
    {
        key: 'lastName',
        heading: 'Surname',
        getValue: (row: Customer) => row.lastName,
    },
    {
        key: 'addressLine1',
        heading: 'Address',
        getValue: (row: Customer) => row.addressLine1,
    },
    {
        key: 'contactEmail',
        heading: 'Contact Email',
        getValue: (row: Customer) => row.contactEmail ?? '',
    },
    {
        key: 'mailPreference',
        heading: 'Mail Preference',
        getValue: (row: Customer) => (
            <Checkbox
                name="meailPreference"
                value={row.contactPreferences.post}
                onChange={() => null}
                disabled
            />
        ),
    },
    {
        key: 'emailPreference',
        heading: 'Email Preference',
        getValue: (row: Customer) => (
            <Checkbox
                name="emailPreference"
                value={row.contactPreferences.email}
                onChange={() => null}
                disabled
            />
        ),
    },
    {
        key: 'eligibleForEmail',
        heading: 'Eligible for Mail?',
        getValue: (row: Customer) => (row.isEligibleForMail ? 'Yes' : 'No'),
    },
    {
        key: 'isRegistered',
        heading: 'App User',
        getValue: (row: Customer) => (
            <Checkbox name="isRegistered" value={row.isRegistered} onChange={() => null} disabled />
        ),
    },
    {
        key: 'actions',
        heading: '',
        getValue: (row: Customer) => (
            <ButtonRow alignment="right">
                <LinkButton href={`/customers/${row.id}`}>View</LinkButton>
                <LinkButton color="grey" href={`/customers/${row.id}/edit`}>
                    Edit
                </LinkButton>
                {hasRole() && (
                    <LinkButton color="red" href={`/customers/${row.id}/delete`}>
                        Delete
                    </LinkButton>
                )}
            </ButtonRow>
        ),
    },
];

export default CustomersTable;
