import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { CancelTokenSource } from 'axios';
import {
    downloadFileCancel,
    downloadFileFailure,
    downloadFileRequest,
    downloadFileSuccess,
} from '../actions/files/downloadFile';

interface FileState {
    isFetching: boolean;
    error: string | null;
    cancelTokenSource: CancelTokenSource | null;
}

const initialState: FileState = {
    isFetching: false,
    error: null,
    cancelTokenSource: null,
};

export default createReducer(initialState, {
    [downloadFileRequest.type]: handleFetchRequest,
    [downloadFileSuccess.type]: handleFetchSuccess,
    [downloadFileFailure.type]: handleFailure,
    [downloadFileCancel.type]: handleFetchCancel,
});

function handleFetchRequest(state: FileState, action: PayloadAction<CancelTokenSource>) {
    if (action.payload != null) state.cancelTokenSource = action.payload;
    state.isFetching = true;
    state.error = null;
}

function handleFetchSuccess(state: FileState) {
    state.isFetching = false;
}

function handleFetchCancel(state: FileState) {
    state.cancelTokenSource?.cancel();
    state.cancelTokenSource = null;
    state.isFetching = false;
}

function handleFailure(state: FileState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.error = action.payload;
}
