import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import { deleteCustomer } from '@actions/customers/deleteCustomer';
import {
    getCustomerPostSuccess,
    getCustomerIsPosting,
    getCustomersError,
} from '@selectors/customers';
import { useHistory } from 'react-router-dom';

export default function useDeleteCustomer(customerID: number): UseDeleteCustomerReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteCustomer(customerID));
    }, [dispatch, customerID]);

    const isPosting = useSelector(getCustomerIsPosting);
    const error = useSelector(getCustomersError);
    const deleteSuccess = useSelector(getCustomerPostSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace('/customers');
        }
    }, [deleteSuccess, prevDeleteSuccess, history]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteCustomerReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
