import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllAdminUsers } from '@actions/adminUsers/fetchAllAdminUsers';
import { fetchSingleAdminUser } from '@actions/adminUsers';
import { getAdminUsers, getAdminUsersError, getAdminUsersIsFetching } from '@selectors/adminUsers';
import { AdminUser } from '@customTypes/shared/AdminUser';

const useAdminUsersFetch = (id?: number): StoresFetchReturn => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (id != null) dispatch(fetchSingleAdminUser(id));
        else dispatch(fetchAllAdminUsers());
    }, [dispatch, id]);

    const stores = useSelector(getAdminUsers);
    const isFetching = useSelector(getAdminUsersIsFetching);
    const fetchError = useSelector(getAdminUsersError);

    return {
        stores,
        isFetching,
        fetchError,
    };
};

interface StoresFetchReturn {
    stores: Record<number, AdminUser>;
    isFetching: boolean;
    fetchError: string | null;
}

export default useAdminUsersFetch;
