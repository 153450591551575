import { PushNotification, PushNotificationPost } from '@customTypes/shared/PushNotification';
import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const createPushNotificationRequest = createAction('createPushNotificationRequest');
export const createPushNotificationSuccess = createAction<PushNotification>(
    'createPushNotificationSuccess',
);
export const createPushNotificationFailure = createAction('createPushNotificationFailure');

export const createPushNotification = (postbody: PushNotificationPost) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(createPushNotificationRequest());
    try {
        const { data } = await api.post<PushNotificationPost, PushNotification>(
            `pushNotifications`,
            postbody,
        );

        dispatch(createPushNotificationSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createPushNotificationFailure, e);
    }
};
