import { createAction, Dispatch } from '@reduxjs/toolkit';

import {
    PostcodeAddressRequest,
    PostcodeAddressResponse,
    PostcodeSuggestion,
} from '../../../types/shared/PostcodeFinder';
import { api, handleApiErrors } from '../../../utils/api';
import { APIError } from '../../../types/APIError';

// postcode address (step 2)
export const postcodeAddressRequest = createAction('postcodeAddressRequest');
export const postcodeAddressSuccess = createAction<PostcodeSuggestion[]>('postcodeAddressSuccess');
export const postcodeAddressFailure = createAction('postcodeAddressFailure');

export const postcodeAddress = (isAdmin: boolean, postBody: PostcodeAddressRequest) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(postcodeAddressRequest());

    try {
        const { data } = await api.post<PostcodeAddressRequest, PostcodeAddressResponse>(
            `${isAdmin ? 'customers' : 'user'}/postcode/address`,
            postBody,
        );

        dispatch(postcodeAddressSuccess(data.items));
    } catch (e) {
        handleApiErrors(dispatch, postcodeAddressFailure, e as APIError);
    }
};
