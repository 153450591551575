import { Children } from 'react';

const NavGroup: React.FC<NavGroupProps> = ({ children, text }) => {
    if (Children.toArray(children).length === 0) return null;

    return (
        <>
            <li className="nav-group">
                <p>{text}</p>
            </li>
            {children}
        </>
    );
};

interface NavGroupProps {
    text: string;
}

export default NavGroup;
