import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const exportCustomerCSVRequest = createAction('exportCustomerCSVRequest');
export const exportCustomerCSVSuccess = createAction('exportCustomerCSVSuccess');
export const exportCustomerCSVFailure = createAction('exportCustomerCSVFailure');

export const exportCustomerCSV = () => async (dispatch: Dispatch): Promise<void> => {
    dispatch(exportCustomerCSVRequest());
    try {
        await api.get(`customers/import`);

        dispatch(exportCustomerCSVSuccess());
    } catch (e) {
        handleApiErrors(dispatch, exportCustomerCSVFailure, e);
    }
};
