import { useState } from 'react';

import CustomersTable from './CustomersTable';
import CreateCustomerModal from './CreateCustomerModal';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';

const Customers: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    return (
        <>
            <CreateHeader>
                <Title>Customers</Title>
                <ButtonRow>
                    <ActionButton
                        color="green"
                        icon="plus"
                        onClick={() => setShowCreateModal(true)}
                    >
                        Create
                    </ActionButton>
                </ButtonRow>
            </CreateHeader>

            <CustomersTable />
            {showCreateModal && (
                <CreateCustomerModal closeModal={() => setShowCreateModal(false)} />
            )}
        </>
    );
};

export default Customers;
