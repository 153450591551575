import { fetchAllStores, fetchSingleStore } from '@actions/stores';
import { getStores, getStoresError, getStoresIsFetching } from '@selectors/stores';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'src/types/shared/Store';

const useStoresFetch = (id?: number): StoresFetchReturn => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (id != null) dispatch(fetchSingleStore(id));
        else dispatch(fetchAllStores());
    }, [dispatch, id]);

    const stores = useSelector(getStores);
    const isFetching = useSelector(getStoresIsFetching);
    const fetchError = useSelector(getStoresError);

    return {
        stores,
        isFetching,
        fetchError,
    };
};

interface StoresFetchReturn {
    stores: Record<number, Store>;
    isFetching: boolean;
    fetchError: string | null;
}

export default useStoresFetch;
