export function isEmpty(item: any): boolean {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

function isObjEmpty(obj: Record<string, unknown>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj<T>(arr: T[], field: string = 'id'): Record<string, T> {
    return arr.reduce<Record<string, T>>((acc, item) => {
        // todo
        //@ts-ignore
        acc[item[field]] = item;
        return acc;
    }, {});
}

export const boolToYesOrNo = (bool: boolean) => (bool ? 'Yes' : 'No');

export const toTitleCase = (string: string) =>
    string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

export function getFileName(src: any) {
    return src.match('[^/]*$')[0];
}

export const areArraysEqual = (a: any[], b: any[]): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};

export const convertStringArrayToDropdownOptions = (options: string[]) => {
    const formatted = options.map(opt => ({
        value: opt,
        label: opt,
    }));

    return formatted;
};