import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteStoreRequest = createAction('deleteStoreRequest');
export const deleteStoreSuccess = createAction('deleteStoreSuccess');
export const deleteStoreFailure = createAction('deleteStoreFailure');

export const deleteStore = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteStoreRequest());

    try {
        await api.delete(`stores/${id}`);

        dispatch(deleteStoreSuccess());
    } catch (e) {
        handleApiErrors(dispatch, deleteStoreFailure, e);
    }
};
