import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Customer, SuppressedStatuses } from 'src/types/shared/Customer';

export const fetchAllCustomersRequest = createAction('fetchAllCustomersRequest');
export const fetchAllCustomersSuccess = createAction<FetchAllCustomersResponse>(
    'fetchAllCustomersSuccess',
);
export const fetchAllCustomersFailure = createAction('fetchAllCustomersFailure');

export const fetchAllCustomers = (
    limit: number,
    page: number,
    status: SuppressedStatuses | null,
    search?: Record<string, any>,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchAllCustomersRequest());

    const body = search || {};

    try {
        const { data } = await api.post<typeof body, FetchAllCustomersResponse>(
            'customers/search',
            body,
            {
                limit,
                page,
                status,
            },
        );

        dispatch(fetchAllCustomersSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllCustomersFailure, e);
    }
};

export interface FetchAllCustomersResponse {
    page: number;
    customers: Customer[];
}
