import { Store } from '@customTypes/shared/Store';
import useStoresFetch from './hooks/useStoresFetch';
import Table from 'lib/src/components/table/Table';
import LinkButton from 'lib/src/components/button/LinkButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import { Column } from 'lib/src/types/table';

const StoresTable: React.FC = () => {
    const { stores, isFetching } = useStoresFetch();

    return <Table columns={columns} rows={Object.values(stores)} isLoading={isFetching} />;
};

const columns: Column<Store>[] = [
    {
        key: 0,
        heading: 'Name',
        getValue: (row: Store) => row.name,
        getSort: (a, b) => a.name.localeCompare(b.name),
        searchable: true,
    },
    {
        key: 1,
        heading: 'Short Name',
        getValue: (row: Store) => row.shortName,
        getSort: (a, b) => a.shortName.localeCompare(b.shortName),
        searchable: true,
    },
    {
        key: 2,
        heading: 'Website',
        getValue: (row: Store) => (
            <a href={row.website} target="_blank" rel="noreferrer">
                {row.website}
            </a>
        ),
        getSort: (a, b) => a.website.localeCompare(b.website),
        searchable: true,
    },
    {
        key: 3,
        heading: '',
        getValue: (row: Store) => (
            <ButtonRow alignment="right">
                <LinkButton href={`/stores/${row.id}`}>View</LinkButton>
                <LinkButton color="grey" href={`/stores/${row.id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="red" href={`/stores/${row.id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        ),
    },
];

export default StoresTable;
