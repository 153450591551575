import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createCustomer } from '@actions/customers/postCreateCustomer';
import {
    getCustomerIsPosting,
    getCustomerPostSuccess,
    getCustomersError,
} from '@selectors/customers';
import { CloseModal, HandleSubmit } from 'src/types/shared/Functions';
import dayjs from 'dayjs';
import { ContactPreferences } from '@customTypes/shared/Customer';

const initialState: FormState = {
    title: '',
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    town: '',
    county: '',
    postCode: '',
    contactEmail: '',
    phone: '',
    dateOfBirth: new Date(),
    gender: 'X',
    storeID: null,
    contactPreferences: {
        email: false,
        post: false,
        phone: false,
        sms: false,
    },
    createUser: false,
    password: '',
    confirmPassword: '',
};

export default function useCreateCustomer(closeModal: CloseModal): UseCreateCustomerReturn {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formState, handleChange] = useForm(initialState);

    const handleSubmit = useCallback(() => {
        const { storeID, dateOfBirth } = formState;
        if (storeID == null) return;
        dispatch(
            createCustomer({
                ...formState,
                dateOfBirth: dateOfBirth ? dayjs(dateOfBirth).format('YYYY-MM-DD') : null,
                storeID,
                password: formState.createUser ? formState.password : null,
            }),
        );
        history.push('/customers');
    }, [dispatch, history, formState]);

    const isPosting = useSelector(getCustomerIsPosting);
    const error = useSelector(getCustomersError);
    const postSuccess = useSelector(getCustomerPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
}
type FormState = {
    title: string;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
    contactEmail: string | null;
    phone: string | null;
    dateOfBirth: Date | null;
    gender: string | null;
    storeID: number | null;
    contactPreferences: ContactPreferences;
    createUser: boolean;
    password: string;
    confirmPassword: string;
};

interface UseCreateCustomerReturn {
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
    error: string | null;
}
