import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import { deleteVoucher } from '@actions/vouchers/deleteVoucher';
import { getVoucherPostSuccess, getVoucherIsPosting, getVouchersError } from '@selectors/vouchers';
import { useHistory } from 'react-router-dom';

export default function useDeleteVoucher(voucherID: number): UseDeleteVoucherReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deleteVoucher(voucherID));
    }, [dispatch, voucherID]);

    const isPosting = useSelector(getVoucherIsPosting);
    const error = useSelector(getVouchersError);
    const deleteSuccess = useSelector(getVoucherPostSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace('/vouchers');
        }
    }, [deleteSuccess, prevDeleteSuccess, history]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeleteVoucherReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
