import { createAction, Dispatch } from '@reduxjs/toolkit';
import axios, { CancelTokenSource } from 'axios';
import fileDownload from 'js-file-download';
import { api, handleApiErrors } from '../../../utils/api';

export const downloadFileRequest = createAction<CancelTokenSource>('downloadFileRequest');
export const downloadFileSuccess = createAction('downloadFileSuccess');
export const downloadFileFailure = createAction('downloadFileFailure');
export const downloadFileCancel = createAction('downloadFileCancel');

export const downloadFile = (url: string, filename: string) => async (dispatch: Dispatch) => {
    const cancelTokenSource = axios.CancelToken.source();
    dispatch(downloadFileRequest(cancelTokenSource));

    try {
        const { data } = await api.get<string>(url, null, cancelTokenSource);

        fileDownload(data, filename);

        dispatch(downloadFileSuccess());
    } catch (e) {
        handleApiErrors(dispatch, downloadFileFailure, e);
    }
};
