import { Store } from '@customTypes/shared/Store';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const fetchAllStoresRequest = createAction('fetchAllStoresRequest');
export const fetchAllStoresSuccess = createAction<FetchAllStoresResponse>('fetchAllStoresSuccess');
export const fetchAllStoresFailure = createAction('fetchAllStoresFailure');

export const fetchAllStores = () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchAllStoresRequest());

    try {
        const { data } = await api.get<FetchAllStoresResponse>('stores');

        dispatch(fetchAllStoresSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllStoresFailure, e);
    }
};

export type FetchAllStoresResponse = Store[];
