import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { UpdatePasswordPost } from 'src/types/shared/AdminUser';

export const updateCustomerPasswordRequest = createAction('updateCustomerPasswordRequest');
export const updateCustomerPasswordSuccess = createAction('updateCustomerPasswordSuccess');
export const updateCustomerPasswordFailure = createAction('updateCustomerPasswordFailure');

export const updateCustomerPassword = (id: number, postbody: UpdatePasswordPost) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateCustomerPasswordRequest());

    try {
        await api.patch(`Customers/${id}/updatePassword`, postbody);

        dispatch(updateCustomerPasswordSuccess());
    } catch (e) {
        handleApiErrors(dispatch, updateCustomerPasswordFailure, e);
    }
};
