import { BusinessHoursException, BusinessHoursExceptions } from '@customTypes/shared/Store';
import Table from 'lib/src/components/table/Table';
import LinkButton from 'lib/src/components/button/LinkButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import { useSelector } from 'react-redux';
import { getStoresIsFetching } from '@selectors/stores';
import Checkbox from 'lib/src/components/form/Checkbox';
import dayjs from 'dayjs';
import { Column } from 'lib/src/types/table';

interface Props {
    storeID: number;
    exceptions: BusinessHoursExceptions;
}

const StoreExceptionsTable: React.FC<Props> = ({ storeID, exceptions }) => {
    const isFetching = useSelector(getStoresIsFetching);

    const columns: Column<BusinessHoursException>[] = [
        {
            key: 0,
            heading: 'Name',
            getValue: (row: BusinessHoursException) => row.name,
            getSort: (a, b) => a.name.localeCompare(b.name),
            searchable: true,
        },
        {
            key: 1,
            heading: 'Start Date',
            getValue: (row: BusinessHoursException) => dayjs(row.startOn).format('DD/MM/YYYY'),
            getSort: (a, b) => dayjs(a.startOn).unix() - dayjs(b.startOn).unix(),
        },
        {
            key: 2,
            heading: 'End Date',
            getValue: (row: BusinessHoursException) =>
                row.endOn ? dayjs(row.endOn).format('DD/MM/YYYY') : 'N/A',
            getSort: (a, b) => dayjs(a.startOn).unix() - dayjs(b.startOn).unix(),
        },
        {
            key: 3,
            heading: 'Closed',
            getValue: (row: BusinessHoursException) => (
                <Checkbox name="isClosed" onChange={() => null} value={row.isClosed} />
            ),
            getSort: (a, b) => +a.isClosed - +b.isClosed,
        },
        {
            key: 4,
            heading: 'Opening Time',
            getValue: (row: BusinessHoursException) =>
                row.openTime ? dayjs(`0000-00-00T${row.openTime}`).format('HH:mm') : 'N/A',
            getSort: (a, b) =>
                dayjs(`0000-00-00T${a.openTime}`).unix() - dayjs(`0000-00-00T${b.openTime}`).unix(),
        },
        {
            key: 5,
            heading: 'Closing Time',
            getValue: (row: BusinessHoursException) =>
                row.closeTime ? dayjs(`0000-00-00T${row.closeTime}`).format('HH:mm') : 'N/A',
            getSort: (a, b) =>
                dayjs(`0000-00-00T${a.closeTime}`).unix() -
                dayjs(`0000-00-00T${b.closeTime}`).unix(),
        },
        {
            key: 6,
            heading: '',
            getValue: (row: BusinessHoursException) => (
                <ButtonRow alignment="right">
                    <LinkButton color="grey" href={`/stores/${storeID}/exceptions/${row.id}/edit`}>
                        Edit
                    </LinkButton>
                    <LinkButton color="red" href={`/stores/${storeID}/exceptions/${row.id}/delete`}>
                        Delete
                    </LinkButton>
                </ButtonRow>
            ),
        },
    ];

    return <Table columns={columns} rows={exceptions} isLoading={isFetching} />;
};

export default StoreExceptionsTable;
