import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Stores from '@pages/stores/stores/Stores';
import StoreContainer from '@pages/stores/store/StoreContainer';
import StoreExceptionsContainer from '@pages/stores/store/exceptions/StoreExceptionsContainer';

const StoresRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Stores />
            </Route>

            <Route exact path={`${path}/:storeID/exceptions`}>
                <StoreExceptionsContainer />
            </Route>
            <Route exact path={`${path}/:storeID/exceptions/:id/:action`}>
                <StoreExceptionsContainer />
            </Route>

            <Route exact path={`${path}/:id`}>
                <StoreContainer />
            </Route>
            <Route exact path={`${path}/:id/:action`}>
                <StoreContainer />
            </Route>
        </Switch>
    );
};

export default StoresRoutes;
