import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';

export const deleteCustomerRequest = createAction('deleteCustomerRequest');
export const deleteCustomerSuccess = createAction('deleteCustomerSuccess');
export const deleteCustomerFailure = createAction('deleteCustomerFailure');

export const deleteCustomer = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(deleteCustomerRequest());

    try {
        await api.delete(`customers/${id}`);

        dispatch(deleteCustomerSuccess());
    } catch (e) {
        handleApiErrors(dispatch, deleteCustomerFailure, e);
    }
};
