import { setCustomerFilter } from '@actions/customers/customerFilters';
import { CustomerFilters } from '@reducers/customers';
import { getCustomerFilters } from '@selectors/customers';
import { useDispatch, useSelector } from 'react-redux';

const useCustomerSearchFilters = (): UseCustomerSearchFiltersReturn => {
    const dispatch = useDispatch();
    const filters = useSelector(getCustomerFilters);

    const setFilter = (key: keyof CustomerFilters, value: any) => {
        dispatch(setCustomerFilter({ key, value }));
    };

    return { filters, setFilter };
};

interface UseCustomerSearchFiltersReturn {
    filters: CustomerFilters;
    setFilter: (key: keyof CustomerFilters, value: any) => void;
}

export default useCustomerSearchFilters;
