import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from 'lib/src/hooks/usePrevious';
import { deletePushNotification } from '@actions/pushNotifications/deletePushNotification';
import {
    getPushNotificationPostSuccess,
    getPushNotificationIsPosting,
    getPushNotificationsError,
} from '@selectors/pushNotifications';
import { useHistory } from 'react-router-dom';

export default function useDeletePushNotification(
    pushNotificationID: number,
): UseDeletePushNotificationReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = useCallback(() => {
        dispatch(deletePushNotification(pushNotificationID));
    }, [dispatch, pushNotificationID]);

    const isPosting = useSelector(getPushNotificationIsPosting);
    const error = useSelector(getPushNotificationsError);
    const deleteSuccess = useSelector(getPushNotificationPostSuccess);
    const prevDeleteSuccess = usePrevious(deleteSuccess);

    useEffect(() => {
        if (!prevDeleteSuccess && deleteSuccess) {
            history.replace('/push-notifications');
        }
    }, [deleteSuccess, prevDeleteSuccess, history]);

    return {
        handleSubmit,
        isPosting,
        error,
    };
}

interface UseDeletePushNotificationReturn {
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}
