import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertArrayToDropdownOption } from '../../shared/enums/dropdownEnums';
import { Column } from '../../types/table';
import TableHeader from './TableHeader';
import TablePagination from './TablePagination';
import TableSearch from './TableSearch';

const APITable = <TColumn, TRow extends { key?: number; id: number }>({
    columns = [],
    rows = [],
    rowCount = 0,
    page = 1,
    onPageChange = () => null,
    limit = 10,
    limitOptions = [10, 25, 50],
    onLimitChange = () => null,
    sortColumn,
    sortAsc,
    onSortColumnChange = () => null,
    canSearch = false,
    showSearch = false,
    searchableColumns = [],
    searchTerm = '',
    onSearchTermChange = () => null,
    isLoading = false,
    noDataMessage = 'There is no data to display',
    header,
    isFetchingCount,
}: TableProps<TRow>): JSX.Element => {
    const isEmpty = !rows.length;

    const [_searchTerm, setSearchTerm] = useState(searchTerm);
    const [_page, _setPage] = useState(page);

    useEffect(() => onPageChange(_page), [_page]);

    useEffect(() => {
        if (page !== 1 && isEmpty) onPageChange(1);
    }, [page, isEmpty]);

    const Loading = () => (
        <tr>
            <td className="loading-row" colSpan={columns.length}>
                <i className="fal fa-spinner fa-spin"></i>
            </td>
        </tr>
    );

    const paginationDescription = useMemo(() => {
        const count = rowCount;
        const startRow = (page - 1) * limit + 1;
        let endRow = page * limit;

        if (endRow > count) endRow = count;

        return `${startRow}-${endRow} of ${count}`;
    }, [page, limit, rowCount]);

    return (
        <>
            <TableHeader>
                {canSearch && (
                    <TableSearch
                        value={_searchTerm}
                        onChange={setSearchTerm}
                        onSubmit={() => onSearchTermChange(_searchTerm)}
                    />
                )}
                {header}
            </TableHeader>
            <table>
                <thead>
                    <tr>{columns.map(renderHeading)}</tr>
                </thead>
                <tbody>
                    {isLoading && <Loading />}
                    {isEmpty && !isLoading && (
                        <tr className="no-data-row">
                            <td colSpan={columns.length}>{noDataMessage}</td>
                        </tr>
                    )}
                    {!isLoading &&
                        rows.map(row => (
                            <tr key={row.key || row.id}>
                                {columns.map(col => (
                                    <td key={col.key}>{col.getValue(row)} </td>
                                ))}
                            </tr>
                        ))}
                </tbody>
            </table>
            {!isEmpty && (
                <TablePagination
                    isFetchingCount={isFetchingCount}
                    page={page}
                    setPage={_setPage}
                    maxPage={Math.ceil(rowCount / limit)}
                    pageSizeOpts={convertArrayToDropdownOption(limitOptions)}
                    pageSize={limit}
                    setPageSize={size => {
                        onLimitChange(size);
                        _setPage(1);
                    }}
                    description={paginationDescription}
                />
            )}
        </>
    );

    function renderHeading(col: Column<TRow>) {
        const { key, heading, getSort } = col;
        const canSort = getSort !== undefined;
        const isSorting = sortColumn?.key === key;

        const searchable = searchableColumns.includes(key.toString());

        return (
            <th
                key={key}
                className={canSort ? 'sortable' : ''}
                onClick={() => !isLoading && canSort && onSortColumnChange(col)}
            >
                {/* Applicable in cases where search is required, but sorting is not */}
                {showSearch
                    ? isSorting && searchable && <i className="fad fa-search" />
                    : canSort && (
                          <>
                              {isSorting && sortAsc && <i className="fad fa-sort-up" />}
                              {isSorting && !sortAsc && <i className="fad fa-sort-down" />}
                          </>
                      )}
                {heading}
            </th>
        );
    }
};

interface TableProps<T> {
    columns: Column<T>[];
    rows: T[];
    rowCount?: number;
    page?: number;
    onPageChange?: (page: number) => void;
    limit?: number;
    limitOptions?: number[];
    onLimitChange?: (limit: number) => void;
    sortColumn?: Column<T>;
    sortAsc?: boolean;
    onSortColumnChange?: (column: Column<T>) => void;
    canSearch?: boolean;
    showSearch?: boolean;
    searchableColumns?: string[];
    searchTerm?: string;
    onSearchTermChange?: (searchTerm: string) => void;
    isLoading?: boolean;
    noDataMessage?: string;
    header?: ReactNode;
    isFetchingCount: boolean;
}

export default APITable;
