import { Store } from '@customTypes/shared/Store';
import { RootState } from '@reducers/index';

export const getStores = (state: RootState): Record<number, Store> => state.storesReducer.stores;
export const getStore = (state: RootState, id: number): Store => state.storesReducer.stores[id];
export const getStoresIsFetching = (state: RootState): boolean => state.storesReducer.isFetching;
export const getStoresError = (state: RootState): string | null => state.storesReducer.error;

export const getStoreIsPosting = (state: RootState): boolean => state.storesReducer.isPosting;
export const getStorePostSuccess = (state: RootState): boolean => state.storesReducer.postSuccess;
