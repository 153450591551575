export enum PushNotificationStatuses {
    NotSent = 0,
    Queued = 1,
    Sending = 2,
    Sent = 3,
    Failed = 99,
}

export const PushNotificationStatusNames = {
    [PushNotificationStatuses.NotSent]: 'Not Sent',
    [PushNotificationStatuses.Queued]: 'Queued',
    [PushNotificationStatuses.Sending]: 'Sending',
    [PushNotificationStatuses.Sent]: 'Sent',
    [PushNotificationStatuses.Failed]: 'Failed',
};

export enum PushNotificationTimeframes {
    BeforeVoucherStarts = 1,
    AfterVoucherStarts = 2,
    BeforeVoucherEnds = 3,
    AfterVoucherEnds = 4,
}

export interface PushNotification {
    id: number;
    voucherId: number | null;
    voucherName: string | null;
    createdByAdminUserId: number;
    createdByAdminUserName: string;
    title: string;
    message: string;
    sendTo: string;
    sendOn: string;
    sentOn: string | null;
    createdOn: string;
    updatedOn: string | null;
    isImmediate: boolean;
    timeFrame: PushNotificationTimeframes | null;
    timeFrameOffset: number | null;
    stores?: number[];
    status: PushNotificationStatuses;
    totalToSend: number | null;
    totalSent: number | null;
    errorMessage: string | null;
}

export interface PushNotificationPost {
    voucherId?: number | null;
    title: string;
    message: string;
    sendOn?: string | null;
    isImmediate: boolean;
    timeFrame?: PushNotificationTimeframes | null;
    timeFrameOffset?: number | null;
    stores?: number[];
}

export interface PushNotificationPatch {
    voucherId?: number | null;
    title: string;
    message: string;
    sendOn?: string | null;
    isImmediate: boolean;
    timeFrame?: PushNotificationTimeframes | null;
    timeFrameOffset?: number | null;
    stores?: number[];
}
