import React, { FC } from 'react';
import Empty from '../empty/Empty';
import Error from '../error/Error';
import Loading from '../loading/Loading';

interface Props {
    isLoading?: boolean;
    error?: string | null;
    isEmpty?: boolean;
    emptyClasses?: string;
    errorClasses?: string;
}

const DataCheck: FC<Props> = ({ isLoading, error, children, isEmpty, emptyClasses, errorClasses }) => {
    if (error) return <Error extraClasses={errorClasses}>{error}</Error>;
    if (isLoading) return <Loading />;
    if (isEmpty) return <Empty extraClasses={emptyClasses}>No Data</Empty>;

    return <>{children}</>;
};

export default DataCheck;
