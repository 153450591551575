import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPushNotification } from '@selectors/pushNotifications';

import DeletePushNotificationModal from './DeletePushNotificationModal';
import UpdatePushNotificationModal from './UpdatePushNotificationModal';

import { RootState } from '@reducers/index';
import PushNotification from './PushNotification';
import usePushNotificationsFetch from '../pushNotifications/hooks/usePushNotificationsFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';

const PushNotificationContainer: React.FC = () => {
    const { id, action } = useParams<ParamTypes>();

    const { isFetching, fetchError } = usePushNotificationsFetch(+id);
    const pushNotification = useSelector((state: RootState) => getPushNotification(state, +id));

    return (
        <DataCheck isLoading={isFetching} error={fetchError} isEmpty={isEmpty(pushNotification)}>
            <PushNotification pushNotification={pushNotification} />
            {action === 'delete' && (
                <DeletePushNotificationModal pushNotification={pushNotification} />
            )}
            {!!pushNotification && action === 'edit' && (
                <UpdatePushNotificationModal pushNotification={pushNotification} />
            )}
        </DataCheck>
    );
};

interface ParamTypes {
    id: string;
    action: string;
}

export default PushNotificationContainer;
