import { BusinessHoursException } from '@customTypes/shared/Store';
import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import useDeleteStoreException from './hooks/useDeleteStoreException';

const DeleteStoreExceptionModal: React.FC<DeleteCustomerProps> = ({ exception, storeID }) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeleteStoreException(exception.id, storeID);

    return (
        <ConfirmModal
            title="Delete Store Exception"
            description={`Are you sure you want to delete '${exception.name}'?`}
            closeModal={() => history.replace(`/stores/${storeID}/exceptions`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
            isDelete
        />
    );
};

interface DeleteCustomerProps {
    exception: BusinessHoursException;
    storeID: number;
}
export default DeleteStoreExceptionModal;
