import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getStore } from '@selectors/stores';

import { RootState } from '@reducers/index';
import StoreExceptions from './StoreExceptions';
import UpdateStoreExceptionModal from './UpdateStoreExceptionModal';
import DeleteStoreExceptionModal from './DeleteStoreExceptionModal';
import useStoresFetch from '@pages/stores/stores/hooks/useStoresFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';

const StoreExceptionsContainer: React.FC = () => {
    const { storeID, id, action } = useParams<ParamTypes>();

    const { isFetching, fetchError } = useStoresFetch(+storeID);

    const store = useSelector((state: RootState) => getStore(state, +storeID));
    const exception = store?.businessHoursExceptions.find(exception => exception.id === +id);

    return (
        <DataCheck isLoading={isFetching} error={fetchError} isEmpty={isEmpty(store)}>
            <StoreExceptions store={store} />
            {action === 'edit' && exception && (
                <UpdateStoreExceptionModal storeID={+storeID} exception={exception} />
            )}
            {action === 'delete' && exception && (
                <DeleteStoreExceptionModal storeID={+storeID} exception={exception} />
            )}
        </DataCheck>
    );
};

interface ParamTypes {
    storeID: string;
    id: string;
    action: string;
}

export default StoreExceptionsContainer;
