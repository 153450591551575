import { DropdownOption } from '../../types/shared/DropdownOption';

export const reverseEnum = (baseEnum: { [key: string]: number }): { [key: number]: string } => {
    const reversed: { [key: number]: string } = {};
    Object.entries(baseEnum).forEach(([key, value]) => {
        reversed[value] = key;
    });
    return reversed;
};

export function enumKeys<O extends Record<string, unknown>, K extends keyof O = keyof O>(
    obj: O,
): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export const convertEnumToDropdownOption = (
    enums: Record<string, string | number>,
): DropdownOption<number>[] => {
    const filteredArr: DropdownOption<number>[] = [];

    Object.entries(enums).forEach(([key, value]) => {
        if (typeof value === 'number') {
            filteredArr.push({
                value: value,
                label: key.replace(/([A-Z])/g, ' $1'),
            });
        }
        return;
    });

    return filteredArr;
};

export const convertArrayToDropdownOption = <T>(array: Array<T>): DropdownOption<T>[] =>
    array.map(item => ({
        value: item,
        label: item + '',
    }));
