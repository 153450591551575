import { useState } from 'react';

import PushNotificationsTable from './PushNotificationsTable';
import CreatePushNotificationModal from './CreatePushNotificationModal';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';

const PushNotifications: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    return (
        <>
            <CreateHeader>
                <Title>Push Notifications</Title>
                <ActionButton color="green" icon="plus" onClick={() => setShowCreateModal(true)}>
                    Create
                </ActionButton>
            </CreateHeader>

            <PushNotificationsTable />
            {showCreateModal && (
                <CreatePushNotificationModal closeModal={() => setShowCreateModal(false)} />
            )}
        </>
    );
};

export default PushNotifications;
