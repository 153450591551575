import React from 'react';
import { useSelector } from 'react-redux';
import { getFieldError } from '../../redux/selectors/fieldErrors';

interface Props {
    name: string;
}

const FormError = ({ name }: Props) => {
    const error = useSelector(state => getFieldError(state, name));

    if (error == null) return null;

    return (
        <div className="form-field">
            <p className="form-error">{error}</p>
        </div>
    );
};

export default FormError;
