import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const importCustomerCSVRequest = createAction('importCustomerCSVRequest');
export const importCustomerCSVSuccess = createAction('importCustomerCSVSuccess');
export const importCustomerCSVFailure = createAction('importCustomerCSVFailure');

export const importCustomerCSV = (postbody: FormData) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(importCustomerCSVRequest());
    try {
        await api.post(`customers/import`, postbody);

        dispatch(importCustomerCSVSuccess());
    } catch (e) {
        handleApiErrors(dispatch, importCustomerCSVFailure, e);
    }
};
interface FormData {
    s3Key: string | null;
    processMatchRef: boolean;
}
