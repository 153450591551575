import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getStoreIsPosting, getStorePostSuccess, getStoresError } from '@selectors/stores';
import { CloseModal, HandleSubmit } from 'src/types/shared/Functions';
import dayjs from 'dayjs';
import { updateStoreException } from '@actions/stores/postUpdateStoreException';
import { BusinessHoursException } from '@customTypes/shared/Store';

export default function useUpdateStoreException(
    {
        id,
        name,
        startOn,
        endOn,
        openTime,
        closeTime,
        isClosed,
        isRecurring,
    }: BusinessHoursException,
    storeID: number,
): UseUpdateStoreReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const [formState, handleChange] = useForm({
        name,
        startOn: new Date(startOn),
        endOn: endOn ? new Date(endOn) : null,
        openTime,
        closeTime,
        isClosed,
        isRecurring,
        isDateRange: !isRecurring && !!endOn,
    });

    const closeModal = useCallback(() => {
        history.replace(`/stores/${storeID}/exceptions`);
    }, [history, storeID]);

    const handleSubmit = useCallback(() => {
        const {
            name,
            startOn,
            endOn,
            openTime,
            closeTime,
            isClosed,
            isRecurring,
            isDateRange,
        } = formState;

        const postBody = {
            name,
            startOn: dayjs(startOn).format('YYYY-MM-DD'),
            endOn: isDateRange && !isRecurring && endOn ? dayjs(endOn).format('YYYY-MM-DD') : null,
            openTime: !isClosed ? openTime : null,
            closeTime: !isClosed ? closeTime : null,
            isClosed,
            isRecurring,
        };

        dispatch(updateStoreException(id, storeID, postBody));
    }, [dispatch, formState, id, storeID]);

    const isPosting = useSelector(getStoreIsPosting);
    const error = useSelector(getStoresError);
    const postSuccess = useSelector(getStorePostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
        }
    }, [postSuccess, prevPostSuccess, closeModal]);

    return {
        formState,
        handleChange,
        closeModal,
        handleSubmit,
        isPosting,
        error,
    };
}

interface FormState {
    name: string;
    startOn: Date;
    endOn: Date | null;
    openTime: string | null;
    closeTime: string | null;
    isClosed: boolean;
    isRecurring: boolean;
    isDateRange: boolean;
}

interface UseUpdateStoreReturn {
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    handleSubmit: HandleSubmit;
    closeModal: CloseModal;
    isPosting: boolean;
    error: string | null;
}
