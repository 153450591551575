import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from 'lib/src/utils/generic';
import {
    fetchAllStoresFailure,
    fetchAllStoresRequest,
    fetchAllStoresSuccess,
    FetchAllStoresResponse,
    fetchSingleStoreRequest,
    fetchSingleStoreSuccess,
    fetchSingleStoreFailure,
    createStoreRequest,
    createStoreSuccess,
    createStoreFailure,
    updateStoreRequest,
    updateStoreSuccess,
    updateStoreFailure,
    createStoreExceptionRequest,
    createStoreExceptionSuccess,
    createStoreExceptionFailure,
    deleteStoreExceptionRequest,
    deleteStoreExceptionSuccess,
    deleteStoreExceptionFailure,
    deleteStoreRequest,
    deleteStoreSuccess,
    deleteStoreFailure,
} from '@actions/stores';
import { Store } from '@customTypes/shared/Store';
import {
    updateStoreExceptionFailure,
    updateStoreExceptionRequest,
    updateStoreExceptionSuccess,
} from '@actions/stores/postUpdateStoreException';

interface StoresState {
    isFetching: boolean;
    isPosting: boolean;
    postSuccess: boolean;
    error: string | null;
    stores: { [key: number]: Store };
}

const initialState: StoresState = {
    isFetching: false,
    isPosting: false,
    postSuccess: false,
    error: null,
    stores: {},
};

export default createReducer(initialState, {
    [fetchAllStoresRequest.type]: handleFetchRequest,
    [fetchAllStoresSuccess.type]: handleFetchStoresSuccess,
    [fetchAllStoresFailure.type]: handleFetchError,

    [fetchSingleStoreRequest.type]: handleFetchRequest,
    [fetchSingleStoreSuccess.type]: handleFetchSingleStoreSuccess,
    [fetchSingleStoreFailure.type]: handleFetchError,

    [createStoreRequest.type]: handlePostRequest,
    [createStoreSuccess.type]: handleCreateStoreSuccess,
    [createStoreFailure.type]: handlePostError,

    [updateStoreRequest.type]: handlePostRequest,
    [updateStoreSuccess.type]: handleUpdateStoreSuccess,
    [updateStoreFailure.type]: handlePostError,

    [deleteStoreRequest.type]: handleDeleteRequest,
    [deleteStoreSuccess.type]: handleDeleteStoreSuccess,
    [deleteStoreFailure.type]: handleDeleteError,

    [createStoreExceptionRequest.type]: handlePostRequest,
    [createStoreExceptionSuccess.type]: handleCreateStoreExceptionSuccess,
    [createStoreExceptionFailure.type]: handlePostError,

    [updateStoreExceptionRequest.type]: handlePostRequest,
    [updateStoreExceptionSuccess.type]: handleUpdateStoreExceptionSuccess,
    [updateStoreExceptionFailure.type]: handlePostError,

    [deleteStoreExceptionRequest.type]: handleDeleteRequest,
    [deleteStoreExceptionSuccess.type]: handleDeleteStoreExceptionSuccess,
    [deleteStoreExceptionFailure.type]: handleDeleteError,
});

function handleFetchRequest(state: StoresState) {
    state.isFetching = true;
}

function handlePostRequest(state: StoresState) {
    state.isPosting = true;
    state.postSuccess = false;
}
function handleDeleteRequest(state: StoresState) {
    state.isPosting = true;
    state.postSuccess = false;
}

function handleFetchError(state: StoresState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isFetching = false;
}
function handlePostError(state: StoresState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}
function handleDeleteError(state: StoresState, action: PayloadAction<string>) {
    state.error = action.payload;
    state.isPosting = false;
}

function handleFetchStoresSuccess(
    state: StoresState,
    action: PayloadAction<FetchAllStoresResponse>,
) {
    state.stores = convertArrToObj(action.payload);
    state.isFetching = false;
}

function handleFetchSingleStoreSuccess(state: StoresState, action: PayloadAction<Store>) {
    state.stores[action.payload.id] = action.payload;
    state.isFetching = false;
}

function handleCreateStoreSuccess(state: StoresState, action: PayloadAction<Store>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.stores[action.payload.id] = action.payload;
}
function handleUpdateStoreSuccess(state: StoresState, action: PayloadAction<Store>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.stores[action.payload.id] = action.payload;
}
function handleDeleteStoreSuccess(state: StoresState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.stores[action.payload];
}

function handleCreateStoreExceptionSuccess(state: StoresState, action: PayloadAction<Store>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.stores[action.payload.id] = action.payload;
}
function handleUpdateStoreExceptionSuccess(state: StoresState, action: PayloadAction<Store>) {
    state.isPosting = false;
    state.postSuccess = true;
    state.stores[action.payload.id] = action.payload;
}

function handleDeleteStoreExceptionSuccess(
    state: StoresState,
    action: PayloadAction<{ id: number; storeID: number }>,
) {
    state.isPosting = false;
    state.postSuccess = true;

    const { id, storeID } = action.payload;
    const store = state.stores[storeID];
    store.businessHoursExceptions = store.businessHoursExceptions.filter(
        exception => exception.id !== id,
    );
}
