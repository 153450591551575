import { PushNotificationTimeframes } from '@customTypes/shared/PushNotification';
import { getVouchers } from '@selectors/vouchers';
import dayjs, { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';

const useSendOn = (formState: FormState): UseSendOnReturn => {
    const {
        voucherId,
        sendOnDate,
        sendOnTime,
        isImmediate,
        timeFrame,
        timeFrameDays,
        timeFrameHours,
        timeFrameMinutes,
    } = formState;

    const vouchers = useSelector(getVouchers);
    const voucher = voucherId == null ? null : vouchers[voucherId];

    const isTimeframeUsed = timeFrame != null && voucher != null && !isImmediate;

    let sendOn = dayjs(dayjs(sendOnDate).format(`YYYY-MM-DDT${sendOnTime}`));
    if (isTimeframeUsed && voucher) {
        const { validFrom, validTo } = voucher;
        const minutes = timeFrameMinutes + timeFrameHours * 60 + timeFrameDays * 24 * 60;

        switch (timeFrame) {
            case PushNotificationTimeframes.BeforeVoucherStarts:
                sendOn = dayjs(validFrom).subtract(minutes, 'minutes');
                break;
            case PushNotificationTimeframes.AfterVoucherStarts:
                sendOn = dayjs(validFrom).add(minutes, 'minutes');
                break;
            case PushNotificationTimeframes.BeforeVoucherEnds:
                if (validTo) sendOn = dayjs(validTo).subtract(minutes, 'minutes');
                break;
            case PushNotificationTimeframes.AfterVoucherEnds:
                if (validTo) sendOn = dayjs(validTo).add(minutes, 'minutes');
                break;
            default:
                break;
        }
    }

    return { sendOn, isTimeframeUsed };
};

interface FormState {
    voucherId: number | null;
    sendOnDate: Date;
    sendOnTime: string;
    isImmediate: boolean;
    timeFrame: PushNotificationTimeframes | null;
    timeFrameDays: number;
    timeFrameHours: number;
    timeFrameMinutes: number;
}

interface UseSendOnReturn {
    sendOn: Dayjs;
    isTimeframeUsed: boolean;
}

export default useSendOn;
