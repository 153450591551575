import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { AppDispatch } from 'src/redux/store';
import { Voucher, VoucherPatch } from 'src/types/shared/Voucher';

export const updateVoucherRequest = createAction('updateVoucherRequest');
export const updateVoucherSuccess = createAction<Voucher>('updateVoucherSuccess');
export const updateVoucherFailure = createAction('updateVoucherFailure');

export const updateVoucher = (id: number, postbody: VoucherPatch) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateVoucherRequest());

    try {
        const { data } = await api.patch<VoucherPatch, Voucher>(`vouchers/${id}`, postbody);

        dispatch(updateVoucherSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateVoucherFailure, e);
    }
};
