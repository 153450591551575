import { ReactNode } from 'react';

const FormField: React.FC<FormFieldProps> = ({
    children,
    name,
    label,
    required,
    error,
    warning,
    className = '',
}) => (
    <div className={`form-field ${className}`}>
        {!!label && (
            <label htmlFor={name} className="form-label">
                {label}
                {required && <span className="form-asterisk">*</span>}
            </label>
        )}

        {children}

        {!!error && <p className="form-error">{error}</p>}
        {!!warning && !error && <p className="form-warning">{warning}</p>}
    </div>
);

interface FormFieldProps {
    name: string;
    label?: ReactNode;
    required?: boolean;
    error?: string | null;
    warning?: ReactNode;
    className?: string;
}

export default FormField;
