import { combineReducers } from 'redux';

import adminUsersReducer from './adminUsers';
import authReducer from './auth';
import customersReducer from './customers';
import darkModeReducer from 'lib/src/redux/reducers/darkMode';
import fieldErrorsReducer from 'lib/src/redux/reducers/fieldErrors';
import filesReducer from 'lib/src/redux/reducers/files';
import postcodeFinderReducer from 'lib/src/redux/reducers/postcodeFinder';
import pushNotificationsReducer from './pushNotifications';
import redirectsReducer from 'lib/src/redux/reducers/redirects';
import storesReducer from './stores';
import vouchersReducer from './vouchers';
import importExportReducer from './importExport';

const rootReducer = combineReducers({
    adminUsersReducer,
    authReducer,
    customersReducer,
    darkModeReducer,
    fieldErrorsReducer,
    filesReducer,
    postcodeFinderReducer,
    pushNotificationsReducer,
    redirectsReducer,
    storesReducer,
    vouchersReducer,
    importExportReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
