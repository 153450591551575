import { Link } from 'react-router-dom';

const LinkButton: React.FC<LinkButtonProps> = ({
    color = 'blue',
    href = '',
    icon,
    className = '',
    children,
}) => (
    <Link to={href} className={`button ${color} ${className}`}>
        {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
        <span className="text">{children}</span>
    </Link>
);

interface LinkButtonProps {
    color?: string;
    href?: string;
    icon?: string;
    className?: string;
}

export default LinkButton;
