import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';

export const deleteStoreExceptionRequest = createAction('deleteStoreExceptionRequest');
export const deleteStoreExceptionSuccess = createAction<{ id: number; storeID: number }>(
    'deleteStoreExceptionSuccess',
);
export const deleteStoreExceptionFailure = createAction('deleteStoreExceptionFailure');

export const deleteStoreException = (id: number, storeID: number) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(deleteStoreExceptionRequest());
    try {
        await api.delete(`stores/${storeID}/exceptions/${id}`);

        dispatch(deleteStoreExceptionSuccess({ id, storeID }));
    } catch (e) {
        handleApiErrors(dispatch, deleteStoreExceptionFailure, e);
    }
};
