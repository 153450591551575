import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Vouchers from '@pages/vouchers/vouchers/Vouchers';
import VoucherContainer from '@pages/vouchers/voucher/VoucherContainer';

const VouchersRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Vouchers />
            </Route>
            <Route exact path={`${path}/:id`}>
                <VoucherContainer />
            </Route>
            <Route exact path={`${path}/:id/:action`}>
                <VoucherContainer />
            </Route>
        </Switch>
    );
};

export default VouchersRoutes;
