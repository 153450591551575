import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getVoucher } from '@selectors/vouchers';

import DeleteVoucherModal from './DeleteVoucherModal';
import UpdateVoucherModal from './UpdateVoucherModal';

import { RootState } from '@reducers/index';
import Voucher from './Voucher';
import useVouchersFetch from '../vouchers/hooks/useVouchersFetch';
import DataCheck from 'lib/src/components/layout/dataCheck/DataCheck';
import { isEmpty } from 'lib/src/utils/generic';

const VoucherContainer: React.FC = () => {
    const { id, action } = useParams<ParamTypes>();

    const { isFetching, fetchError } = useVouchersFetch(+id);
    const voucher = useSelector((state: RootState) => getVoucher(state, +id));

    return (
        <DataCheck isLoading={isFetching} error={fetchError} isEmpty={isEmpty(voucher)}>
            <Voucher voucher={voucher} />
            {action === 'delete' && <DeleteVoucherModal voucher={voucher} />}
            {!!voucher && action === 'edit' && <UpdateVoucherModal voucher={voucher} />}
        </DataCheck>
    );
};

interface ParamTypes {
    id: string;
    action: string;
}

export default VoucherContainer;
