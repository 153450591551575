import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { Customer } from 'src/types/shared/Customer';
import useDeleteCustomer from './hooks/useDeleteCustomer';

const DeleteCustomerModal: React.FC<DeleteCustomerProps> = ({ customer }) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeleteCustomer(customer.id);

    return (
        <ConfirmModal
            title="Delete Customer"
            description={`Are you sure you want to delete '${customer.firstName} ${customer.lastName}'?`}
            closeModal={() => history.replace(`/customers/${customer.id}`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
            isDelete
        />
    );
};

interface DeleteCustomerProps {
    customer: Customer;
}
export default DeleteCustomerModal;
