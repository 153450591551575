import Modal from 'lib/src/components/modal/Modal';
import Form from 'lib/src/components/form/Form';
import FormRow from 'lib/src/components/form/FormRow';
import useCreateStoreException from './hooks/useCreateStoreException';
import TextInput from 'lib/src/components/form/TextInput';
import Checkbox from 'lib/src/components/form/Checkbox';
import FormSubTitle from 'lib/src/components/form/FormSubTitle';
import DatePicker from 'lib/src/components/form/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getStores } from '@selectors/stores';
import FormField from 'lib/src/components/form/FormField';

const CreateStoreExceptionModal: React.FC<CreateCustomerProps> = ({ storeID, closeModal }) => {
    const {
        formState: {
            name,
            isDateRange,
            startOn,
            endOn,
            openTime,
            closeTime,
            isClosed,
            isRecurring,
        },
        handleChange,
        handleSubmit,
        isPosting,
    } = useCreateStoreException(storeID, closeModal);

    const stores = useSelector(getStores);
    const store = Object.values(stores).find(store => store.id === storeID);

    return (
        <Modal closeModal={closeModal} title="Create Business Hours Exception">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormSubTitle>Exception Details</FormSubTitle>
                <FormRow>
                    <TextInput
                        name="name"
                        value={name}
                        onChange={handleChange}
                        label="Name"
                        placeholder="Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <div className="flex-6">
                        <Checkbox
                            name="isDateRange"
                            label="Include date range?"
                            value={isDateRange}
                            onChange={handleChange}
                            disabled={isRecurring}
                        />
                    </div>

                    <div className="flex-6">
                        <Checkbox
                            name="isRecurring"
                            value={isRecurring}
                            onChange={handleChange}
                            label="Recurring"
                        />
                    </div>
                </FormRow>
                <FormRow>
                    <FormField name="" className="no-margin">
                        <DatePicker
                            name="startOn"
                            value={startOn}
                            onChange={handleChange}
                            label="Start On"
                            required
                            minDate={dayjs().toDate()}
                            customValidate={value =>
                                value &&
                                store?.businessHoursExceptions.find(exception =>
                                    dayjs(exception.startOn).isSame(value, 'day'),
                                )
                                    ? 'Exception already exists on that day'
                                    : undefined
                            }
                        />
                    </FormField>

                    <FormField name="" className="no-margin">
                        {isDateRange && !isRecurring && (
                            <DatePicker
                                name="endOn"
                                value={endOn}
                                onChange={handleChange}
                                label="End On"
                                customValidate={value =>
                                    value && !isRecurring && !dayjs(value).isAfter(startOn)
                                        ? 'End date must be after start date'
                                        : null
                                }
                                disabled={isRecurring}
                                required
                            />
                        )}
                    </FormField>
                </FormRow>
                <FormRow>
                    <Checkbox
                        name="isClosed"
                        value={isClosed}
                        onChange={handleChange}
                        label="Closed?"
                    />
                </FormRow>
                {!isClosed && (
                    <FormRow>
                        <TextInput
                            type="time"
                            name="openTime"
                            value={isClosed ? '' : openTime ?? ''}
                            onChange={handleChange}
                            label="Open Time"
                            placeholder="Open Time"
                            required={!isClosed}
                            disabled={isClosed}
                        />
                        <TextInput
                            type="time"
                            name="closeTime"
                            value={isClosed ? '' : closeTime ?? ''}
                            onChange={handleChange}
                            label="Close Time"
                            placeholder="Close Time"
                            required={!isClosed}
                            customValidate={value =>
                                !isClosed &&
                                !dayjs(`0000-00-00T${value}`).isAfter(`0000-00-00T${openTime}`)
                                    ? 'Close time must be after open time'
                                    : null
                            }
                            disabled={isClosed}
                        />
                    </FormRow>
                )}
            </Form>
        </Modal>
    );
};

interface CreateCustomerProps {
    storeID: number;
    closeModal: () => void;
}

export default CreateStoreExceptionModal;
