import { fetchAllPushNotifications, fetchSinglePushNotification } from '@actions/pushNotifications';
import {
    getPushNotifications,
    getPushNotificationsError,
    getPushNotificationsIsFetching,
} from '@selectors/pushNotifications';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PushNotification } from 'src/types/shared/PushNotification';

const usePushNotificationsFetch = (id?: number): PushNotificationsFetchReturn => {
    const dispatch = useDispatch();

    const [showHistorical, setShowHistorical] = useState(false);

    useEffect(() => {
        if (id != null) dispatch(fetchSinglePushNotification(id));
        else dispatch(fetchAllPushNotifications(showHistorical));
    }, [dispatch, id, showHistorical]);

    const pushNotifications = Object.values(useSelector(getPushNotifications));
    const isFetching = useSelector(getPushNotificationsIsFetching);
    const fetchError = useSelector(getPushNotificationsError);

    return {
        pushNotifications,
        isFetching,
        fetchError,
        showHistorical,
        setShowHistorical,
    };
};

interface PushNotificationsFetchReturn {
    pushNotifications: PushNotification[];
    isFetching: boolean;
    fetchError: string | null;
    showHistorical: boolean;
    setShowHistorical: (showHistorical: boolean) => void;
}

export default usePushNotificationsFetch;
