import { createAction, Dispatch } from '@reduxjs/toolkit';
import {
    PostcodeFindRequest,
    PostcodeFindResponse,
    PostcodeSuggestion,
} from '../../../types/shared/PostcodeFinder';
import { api, handleApiErrors } from '../../../utils/api';
import { APIError } from '../../../types/APIError';

// postcode find (step 1)
export const postcodeFindRequest = createAction('postcodeFindRequest');
export const postcodeFindSuccess = createAction<PostcodeSuggestion[]>('postcodeFindSuccess');
export const postcodeFindFailure = createAction('postcodeFindFailure');

export const postcodeFind = (isAdmin: boolean, postBody: PostcodeFindRequest) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(postcodeFindRequest());

    try {
        const { data } = await api.post<PostcodeFindRequest, PostcodeFindResponse>(
            `${isAdmin ? 'customers' : 'user'}/postcode/find`,
            postBody,
        );

        dispatch(postcodeFindSuccess(data.items));
    } catch (e) {
        handleApiErrors(dispatch, postcodeFindFailure, e as APIError);
    }
};
