import { RootState } from '@reducers/index';
import { PushNotification } from 'src/types/shared/PushNotification';

export const getPushNotifications = (state: RootState): Record<number, PushNotification> =>
    state.pushNotificationsReducer.pushNotifications;
export const getPushNotification = (state: RootState, id: number): PushNotification =>
    state.pushNotificationsReducer.pushNotifications[id];
export const getPushNotificationsIsFetching = (state: RootState): boolean =>
    state.pushNotificationsReducer.isFetching;
export const getPushNotificationsError = (state: RootState): string | null =>
    state.pushNotificationsReducer.error;

export const getPushNotificationIsPosting = (state: RootState): boolean =>
    state.pushNotificationsReducer.isPosting;
export const getPushNotificationPostSuccess = (state: RootState): boolean =>
    state.pushNotificationsReducer.postSuccess;
