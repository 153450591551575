import jwtDecode from 'jwt-decode';
import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';

interface JWT {
    nbf: number;
    exp: number;
    iat: number;
    // keys sent through JSON - strings double wrapped ("\"name\""), numbers single wrapped ("2")
    Email: string;
    FirstName: string;
    LastName: string;
    // number in string
    ID: string;
    // array in string ("[1, 2, 3]")
    Roles: string;
}

export interface JWTData {
    email: string;
    firstName: string;
    lastName: string;
    id: number;
    roles: AdminUserRoles[];
}

export function validateJWT(token: string): boolean {
    try {
        if (!token) return false;

        const now = new Date().valueOf() / 1000;
        const { nbf: validFrom, exp: expiry } = jwtDecode<JWT>(token);
        const isValid = validFrom < now + 5 && expiry > now;
        return isValid;
    } catch {
        return false;
    }
}

export const getJwtToken = (): string | null => {
    const token = localStorage.getItem('token');
    return token;
};

export const clearJwtToken = (): void => {
    localStorage.removeItem('token');
};
const formatJWT = (decoded: JWT): JWTData => {
    const { Email, FirstName, LastName, ID, Roles } = decoded;
    if (!Roles) return {} as JWTData;
    const roles = Roles.replace(/[[\]"]+/g, '')
        .split(',')
        .map(role => +role as AdminUserRoles);
    return {
        email: Email,
        firstName: FirstName,
        lastName: LastName,
        id: +ID,
        roles,
    };
};

export const getDecodedJwtToken = (): JWTData | null => {
    const token = getJwtToken();
    if (token === null) return null;
    const isValid = validateJWT(token);
    if (!isValid) {
        clearJwtToken();
        return null;
    }

    const decoded = jwtDecode<JWT>(token);
    const formatted = formatJWT(decoded);
    return formatted;
};
