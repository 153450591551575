import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from 'lib/src/utils/api';
import { Customer, CustomerPost } from 'src/types/shared/Customer';

export const createCustomerRequest = createAction('createCustomerRequest');
export const createCustomerSuccess = createAction<Customer>('createCustomerSuccess');
export const createCustomerFailure = createAction('createCustomerFailure');

export const createCustomer = (postbody: CustomerPost) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(createCustomerRequest());
    try {
        const { data }: { data: Customer } = await api.post(`customers`, postbody);

        dispatch(createCustomerSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createCustomerFailure, e);
    }
};
