import React, { useState } from 'react';

import Title from 'lib/src/components/typography/Title';

import { Store as StoreTypes } from '@customTypes/shared/Store';
import StoreExceptionsTable from './StoreExceptionsTable';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ActionButton from 'lib/src/components/button/ActionButton';
import CreateStoreExceptionModal from './CreateStoreExceptionModal';

const StoreExceptions: React.FC<StoreProps> = ({ store }) => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    if (!store) return null;

    const { id, name, businessHoursExceptions } = store;
    return (
        <>
            <CreateHeader>
                <Title backHref={`/stores/${store.id}`}>
                    Store Business Hour Exceptions - {name}
                </Title>
                <ActionButton color="green" icon="plus" onClick={() => setShowCreateModal(true)}>
                    Create
                </ActionButton>
            </CreateHeader>

            <StoreExceptionsTable storeID={id} exceptions={businessHoursExceptions} />
            {showCreateModal && (
                <CreateStoreExceptionModal
                    storeID={id}
                    closeModal={() => setShowCreateModal(false)}
                />
            )}
        </>
    );
};

interface StoreProps {
    store: StoreTypes | undefined;
}
export default StoreExceptions;
