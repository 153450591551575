import useForm from 'lib/src/hooks/useForm';
import usePrevious from 'lib/src/hooks/usePrevious';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getPushNotificationIsPosting,
    getPushNotificationPostSuccess,
    getPushNotificationsError,
} from '@selectors/pushNotifications';
import { CloseModal, HandleSubmit } from 'src/types/shared/Functions';
import dayjs from 'dayjs';
import { createPushNotification } from '@actions/pushNotifications';
import { PushNotificationTimeframes } from '@customTypes/shared/PushNotification';
import { fetchSingleVoucher } from '@actions/vouchers';
import { removeFieldError } from 'lib/src/redux/actions/fieldErrors';

const initialState: FormState = {
    voucherId: null,
    title: '',
    message: '',
    sendOnDate: new Date(),
    sendOnTime: dayjs().format('HH:mm'),
    isImmediate: false,
    timeFrame: null,
    timeFrameDays: 0,
    timeFrameHours: 0,
    timeFrameMinutes: 0,
    stores: [],
};

export default function useCreatePushNotification(
    closeModal: CloseModal,
    _voucherId?: number,
): UseCreatePushNotificationReturn {
    const dispatch = useDispatch();
    const history = useHistory();
    const [formState, handleChange] = useForm({
        ...initialState,
        voucherId: _voucherId === undefined ? null : _voucherId,
    });

    const handleSubmit = useCallback(() => {
        const {
            sendOnDate,
            sendOnTime,
            timeFrame,
            timeFrameDays,
            timeFrameHours,
            timeFrameMinutes,
        } = formState;

        const sendOnWithTime = dayjs(sendOnDate).utc().format(`YYYY-MM-DDT${sendOnTime}`);
        const sendOn = dayjs(sendOnWithTime).utc().format();

        dispatch(
            createPushNotification({
                ...formState,
                ...(timeFrame == null
                    ? {
                          sendOn,
                      }
                    : {
                          timeFrame,
                          timeFrameOffset:
                              timeFrameMinutes + timeFrameHours * 60 + timeFrameDays * 24 * 60,
                      }),
            }),
        );
    }, [dispatch, formState]);

    const isPosting = useSelector(getPushNotificationIsPosting);
    const error = useSelector(getPushNotificationsError);
    const postSuccess = useSelector(getPushNotificationPostSuccess);
    const prevPostSuccess = usePrevious(postSuccess);

    useEffect(() => {
        if (!prevPostSuccess && postSuccess) {
            closeModal();
            if (!_voucherId) history.push('/push-notifications');
            if (_voucherId) dispatch(fetchSingleVoucher(_voucherId));
        }
    }, [dispatch, postSuccess, prevPostSuccess, closeModal, _voucherId, history]);

    useEffect(() => {
        if (formState.voucherId == null) handleChange('timeFrame', null);
        // eslint-disable-next-line
    }, [formState.voucherId]);

    useEffect(() => {
        dispatch(removeFieldError('timeFrameOffset'));
        dispatch(removeFieldError('sendOn'));
    }, [dispatch, formState]);

    return {
        formState,
        handleChange,
        handleSubmit,
        isPosting,
        error,
    };
}

interface FormState {
    voucherId: number | null;
    title: string;
    message: string;
    sendOnDate: Date;
    sendOnTime: string;
    isImmediate: boolean;
    timeFrame: PushNotificationTimeframes | null;
    timeFrameDays: number;
    timeFrameHours: number;
    timeFrameMinutes: number;
    stores?: number[];
}

interface UseCreatePushNotificationReturn {
    formState: FormState;
    handleChange: <T>(name: keyof FormState, value: T) => void;
    handleSubmit: HandleSubmit;
    isPosting: boolean;
    error: string | null;
}
