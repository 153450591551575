import React from 'react';

import {
    PushNotificationStatuses,
    PushNotificationStatusNames,
} from '@customTypes/shared/PushNotification';
import InfoTooltip from '@components/tooltip/InfoTooltip';

const NotificationStatus: React.FC<NotificationStatusProps> = ({ status, errorMessage }) => {
    const pushStatus = PushNotificationStatusNames[status];

    return (
        <p className="notification-status">
            <span className={`status-text ${pushStatus.replace(' ', '-').toLowerCase()}`}>
                {pushStatus}
            </span>
            {errorMessage && <InfoTooltip description={errorMessage} />}
        </p>
    );
};

interface NotificationStatusProps {
    status: PushNotificationStatuses;
    errorMessage: string | null;
}

export default NotificationStatus;
