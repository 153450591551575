import ConfirmModal from 'lib/src/components/modal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { PushNotification } from 'src/types/shared/PushNotification';
import useDeletePushNotification from './hooks/useDeletePushNotification';

const DeletePushNotificationModal: React.FC<DeletePushNotificationProps> = ({
    pushNotification,
}) => {
    const history = useHistory();
    const { handleSubmit, isPosting, error } = useDeletePushNotification(pushNotification.id);

    return (
        <ConfirmModal
            title="Delete Push Notification"
            description={`Are you sure you want to delete '${pushNotification.title}'?`}
            closeModal={() => history.replace(`/push-notifications/${pushNotification.id}`)}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
            isDelete
        />
    );
};

interface DeletePushNotificationProps {
    pushNotification: PushNotification;
}
export default DeletePushNotificationModal;
