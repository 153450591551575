export enum SuppressedStatuses {
    Active = 1,
    SoftSuppressed = 2,
    HardSuppressed = 3,
}

export const SuppressedStatusNames = {
    [SuppressedStatuses.Active]: 'Active',
    [SuppressedStatuses.SoftSuppressed]: 'Soft Suppressed',
    [SuppressedStatuses.HardSuppressed]: 'Hard Suppressed',
};

export interface ContactPreferences {
    email: boolean;
    post: boolean;
    phone: boolean;
    sms: boolean;
}

export interface Customer {
    id: number;
    title: string | null;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
    contactEmail: string | null;
    phone: string | null;
    dateOfBirth: string | null;
    gender: string;
    storeID: number;
    isSuppressed: boolean;
    isRegistered: boolean;
    suppressedFlag: string | null;
    suppressedDate: string | null;
    suppressedStatus: SuppressedStatuses;
    emailSuppressedFlag: number;
    contactPreferences: ContactPreferences;
    isEligibleForMail: boolean;
    sort: number;
    createdOn: string;
    updatedOn: Date | null;
    lastSpendOn: Date | null;
}

export interface CustomerPost {
    title: string | null;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
    contactEmail: string | null;
    phone: string | null;
    dateOfBirth: string | null;
    gender: string | null;
    storeID: number;
    contactPreferences: ContactPreferences;
    password: string | null;
}

export interface CustomerPatch {
    title: string | null;
    firstName: string;
    lastName: string;
    addressLine1: string;
    addressLine2: string | null;
    addressLine3: string | null;
    town: string | null;
    county: string | null;
    postCode: string;
    contactEmail: string | null;
    phone: string | null;
    dateOfBirth: string | null;
    gender: string | null;
    storeID: number;
    contactPreferences: ContactPreferences;
    suppressedStatus: SuppressedStatuses;
    emailSuppressedFlag: number;
    password: string | null;
}

export interface CustomersImportPost {
    file: File;
}
