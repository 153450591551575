import { RootState } from '@reducers/index';
import { Voucher } from 'src/types/shared/Voucher';

export const getVouchers = (state: RootState): Record<number, Voucher> =>
    state.vouchersReducer.vouchers;
export const getVoucher = (state: RootState, id: number): Voucher =>
    state.vouchersReducer.vouchers[id];
export const getVouchersIsFetching = (state: RootState): boolean =>
    state.vouchersReducer.isFetching;
export const getVouchersError = (state: RootState): string | null => state.vouchersReducer.error;

export const getVoucherIsPosting = (state: RootState): boolean => state.vouchersReducer.isPosting;
export const getVoucherPostSuccess = (state: RootState): boolean =>
    state.vouchersReducer.postSuccess;
