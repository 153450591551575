import React from 'react';

import Title from 'lib/src/components/typography/Title';

import ButtonRow from 'lib/src/components/button/ButtonRow';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from 'lib/src/components/button/LinkButton';
import { Customer as CustomerTypes, SuppressedStatusNames } from '@customTypes/shared/Customer';
import { buildAddress, hasRole } from '@utils/generic';
import dayjs from 'dayjs';
import { toTitleCase } from 'lib/src/utils/generic';
import Checkbox from 'lib/src/components/form/Checkbox';

const Customer: React.FC<CustomerProps> = ({ customer }) => {
    if (!customer) return null;

    const {
        id,
        title,
        firstName,
        lastName,
        contactEmail,
        dateOfBirth,
        gender,
        phone,
        isRegistered,
        suppressedDate,
        createdOn,
        updatedOn,
        lastSpendOn,
    } = customer;

    return (
        <>
            <Title backHref="/customers">
                Customer - {title ?? ''} {firstName} {lastName}
            </Title>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="URN">
                        <p>{id}</p>
                    </ContentItem>
                    <ContentItem label="Name">
                        <p>{`${title ?? ''} ${firstName} ${lastName}`}</p>
                    </ContentItem>
                    <ContentItem label="Date of Birth">
                        <p>{dateOfBirth ? dayjs(dateOfBirth).format('DD/MM/YYYY') : 'N/A'}</p>
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Gender">
                        <p>
                            {
                                {
                                    M: 'Male',
                                    F: 'Female',
                                    X: 'Prefer not to say',
                                }[gender]
                            }
                        </p>
                    </ContentItem>
                    <ContentItem label="Full Address">
                        <p>{buildAddress(customer)}</p>
                    </ContentItem>
                    <ContentItem label="Email">
                        <p>
                            {contactEmail ? (
                                <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
                            ) : (
                                'N/A'
                            )}
                        </p>
                    </ContentItem>
                </ContentRow>

                <ContentRow>
                    <ContentItem label="Phone">
                        <p>{phone ? phone : 'N/A'}</p>{' '}
                    </ContentItem>
                    <ContentItem label="Suppressed Status">
                        <p>{SuppressedStatusNames[customer.suppressedStatus]}</p>
                    </ContentItem>
                    <ContentItem label="Suppressed Date">
                        <p>{suppressedDate ? dayjs(suppressedDate).format('DD/MM/YYYY') : 'N/A'}</p>
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Created Date">
                        <p>{dayjs(createdOn).format('DD/MM/YYYY')}</p>
                    </ContentItem>
                    <ContentItem label="Last Amended Date">
                        <p>{updatedOn ? dayjs(updatedOn).format('DD/MM/YYYY HH:mm') : 'N/A'}</p>
                    </ContentItem>
                    <ContentItem label="App User">
                        <Checkbox
                            name="isRegistered"
                            value={isRegistered}
                            disabled
                            onChange={() => null}
                        />
                    </ContentItem>
                </ContentRow>
                <ContentRow>
                    <ContentItem label="Last Spend On">
                        <p>{lastSpendOn ? dayjs(lastSpendOn).format('DD/MM/YYYY HH:mm') : 'N/A'}</p>
                    </ContentItem>
                    <ContentItem label="">
                        <p></p>
                    </ContentItem>
                    <ContentItem label="">
                        <p></p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ContentBlock>
                <ContentItem label="Contact Preferences" />
                <ContentRow>
                    {Object.entries(customer.contactPreferences).map(([key, value]) => (
                        <ContentItem label={toTitleCase(key)} key={key}>
                            <Checkbox name={key} value={value} disabled onChange={() => null} />
                        </ContentItem>
                    ))}
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <LinkButton color="grey" href={`/customers/${id}/edit`}>
                    Edit
                </LinkButton>
                {hasRole() && (
                    <LinkButton color="red" href={`/customers/${id}/delete`}>
                        Delete
                    </LinkButton>
                )}
            </ButtonRow>
        </>
    );
};

interface CustomerProps {
    customer: CustomerTypes | undefined;
}
export default Customer;
