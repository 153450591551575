import { getImportExportIsPosting, getImportExportError } from '@selectors/importExport';
import { useDispatch, useSelector } from 'react-redux';
import { importCustomerCSV } from '@actions/importExport';
import useForm from 'lib/src/hooks/useForm';

const useUploadFile = (isMatching: boolean): Hook => {
    const [formData, onChange] = useForm<FormData>({
        fileS3Key: null,
    });
    const dispatch = useDispatch();

    const isPosting = useSelector(getImportExportIsPosting);
    const error = useSelector(getImportExportError);

    const handleSubmit = () => {
        if (
            Array.isArray(formData.fileS3Key) &&
            formData.fileS3Key.length &&
            typeof formData.fileS3Key[0] === 'string'
        ) {
            // const file = formData.fileS3Key[0].split('/').pop();
            dispatch(
                importCustomerCSV({
                    s3Key: `${formData.fileS3Key[0]}`,
                    processMatchRef: isMatching,
                }),
            );
        }
    };

    return { formData, onChange, handleSubmit, isPosting, error };
};

interface FormData {
    fileS3Key: string[] | null;
}

interface Hook {
    formData: FormData;
    onChange: (name: keyof FormData, value: string[] | boolean | null) => void;
    handleSubmit: () => void;
    isPosting: boolean;
    error: string | null;
}

export default useUploadFile;
