import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from '@actions/auth';
import { clearJtwtToken } from 'lib/src/utils/jwt';

import NavGroup from './NavGroup';
import NavItem from './NavItem';
import { AdminUserRoles } from 'lib/src/shared/enums/roleEnums';
import { hasRole } from '@utils/generic';

const Nav: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const path = location.pathname.toLowerCase();

    if (path.startsWith('/auth')) return null;

    const isAdmin = hasRole(AdminUserRoles.Admins) || hasRole(AdminUserRoles.SuperAdmin);

    return (
        <nav className="navigation custom-scroll">
            <ul className="nav-list">
                <NavGroup text="User management">
                    {hasRole(AdminUserRoles.Admins) && (
                        <NavItem to="/admins" text="Admins" icon="users-cog" />
                    )}
                    {hasRole(AdminUserRoles.Customers) && (
                        <NavItem to="/customers" text="Customers" icon="users" />
                    )}
                </NavGroup>

                <NavGroup text="Management">
                    {hasRole(AdminUserRoles.Stores) && (
                        <NavItem to="/stores" text="Stores" icon="store" />
                    )}
                    {hasRole(AdminUserRoles.Vouchers) && (
                        <NavItem to="/vouchers" text="Vouchers" icon="tags" />
                    )}
                    {hasRole(AdminUserRoles.PushNotifications) && (
                        <NavItem to="/push-notifications" text="Push Notifications" icon="bell" />
                    )}
                    {isAdmin && (
                        <NavItem to="/import-export" text="Import/Export" icon="download" />
                    )}
                </NavGroup>

                <NavGroup text="Settings">
                    <NavItem
                        to="/auth/login"
                        text="Logout"
                        icon="sign-out"
                        onClick={() => {
                            clearJtwtToken();
                            dispatch(logout());
                        }}
                    />
                </NavGroup>
            </ul>
        </nav>
    );
};

export default Nav;
