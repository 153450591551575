import { useState } from 'react';

import VouchersTable from './VouchersTable';
import CreateVoucherModal from './CreateVoucherModal';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Title from 'lib/src/components/typography/Title';
import ActionButton from 'lib/src/components/button/ActionButton';

const Vouchers: React.FC = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    return (
        <>
            <CreateHeader>
                <Title>Vouchers</Title>
                <ActionButton color="green" icon="plus" onClick={() => setShowCreateModal(true)}>
                    Create
                </ActionButton>
            </CreateHeader>

            <VouchersTable />
            {showCreateModal && <CreateVoucherModal closeModal={() => setShowCreateModal(false)} />}
        </>
    );
};

export default Vouchers;
