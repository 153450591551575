import { Route, Switch, useRouteMatch } from 'react-router-dom';
import PushNotifications from '@pages/pushNotifications/pushNotifications/PushNotifications';
import PushNotificationContainer from '@pages/pushNotifications/pushNotification/PushNotificationContainer';

const PushNotificationsRoutes: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={`${path}`}>
                <PushNotifications />
            </Route>
            <Route exact path={`${path}/:id`}>
                <PushNotificationContainer />
            </Route>
            <Route exact path={`${path}/:id/:action`}>
                <PushNotificationContainer />
            </Route>
        </Switch>
    );
};

export default PushNotificationsRoutes;
